import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle  from '@mui/material/DialogTitle';
import CountdownTimer from "../Dashboard/CountDown";

const ExamIntroPopup = (props) => {
    const { isOpen, onClose, examData } = props;
    const [_isOpen, setOpen] = useState(isOpen);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleLogout = () => {
    navigate("/");
    localStorage.clear();
    setOpen(false);
  };

  const startExam = () => {
    navigate('/mcq-exam', {state: examData})
    localStorage.setItem('yourAppState', JSON.stringify(examData));
  };

  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={'xl'}
    >
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center" style={{ color:"#001b66" }}>
        Instructions
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "3%", bottom: "0%", color: "rgb(116, 103, 239)", cursor: "pointer"}}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <DialogContent className="" style={{padding:"0px 24px 24px"}}>
      <p className="current-text-heading">
        Welcome to the {examData && examData.exam_name} {examData && examData.exam_name.split(' ')[examData.exam_name.split(' ').length - 1].toLowerCase() !== "exam" && "exam"}.
        Please read the following instructions carefully before proceeding:
      </p>

                    <ol className="current-text-subheading py-2" type="1">
                        <li className="my-2"><span className="fw-bold">Question Reading:</span>  <br /> Ensure that you read each question thoroughly before making your selection.</li>
                        <li className="my-2"><span className="fw-bold">Skipping Questions:</span> <br /> You have the option to skip a question if needed.</li>
                        <li className="my-2" style={{display: examData && examData.enable_negative_mark ? '' : 'none'}}><span className="fw-bold">Scoring:</span> <br /> Each question carries one mark. Be aware that for every incorrect answer, 0.25 marks will be deducted from your total score.</li>
                        <li className="my-2"><span className="fw-bold">Exam Completion:</span> <br /> After answering all questions, click on the "Submit" button. If you do not submit manually, the exam will be automatically submitted after the end time.</li>
                        <li className="my-2"><span className="fw-bold">Tab/Window Usage:</span> <br /> Do not attempt to switch tabs or windows during the exam. If you switch tabs or windows three times, the exam will be automatically submitted.</li>
                    </ol>
        <p className="current-text-subheading">Please adhere to these guidelines to ensure a smooth and fair examination experience. <span className='fw-bold text-success'>Good luck!</span> </p>
        <div className='text-center'>
          {/* <button className="start-exam-button btn btn-custom btn-lg" onClick={startExam}>
            Start Exam
          </button> */}
          <div className="start-exam-button">
            <CountdownTimer targetDate={new Date(`${examData && examData.start_date}T${ examData && examData.start_time}`)} examData={examData}/>
          </div>
        </div>
      </DialogContent>
      {/* <DialogActions sx={{ justifyContent: "center", marginBottom: "18px" }} >
        <button onClick={handleLogout} className="btn btn-danger border rounded-5 border-danger mx-2 px-4 text-capitalize fw-bold">
          Yes
        </button>
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 px-4 text-capitalize fw-bold`}>
          No
        </button>
      </DialogActions> */}
    </Dialog>
  );
};

export default ExamIntroPopup;
