import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import React from "react";
import './Exam.css'
import LeftSideBar from "../../Sidebar/Left_SideBar";
import SelectQuestions from "./Select_Questions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import dayjs from "dayjs";
import ViewSelectedQuestionsPage from "./ViewSelectedQuestionPage";
import { TimePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import LoadingSpinner from "../../../Utils/LoadingSpinner";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import DataTable from 'react-data-table-component';
import Conformation from "./Conformation";
import { useParams } from 'react-router-dom';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import _isEqual from 'lodash/isEqual';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import UploadExcelStudent from "./Upload_Excel_Student";
import ResendEmail from "./ResendEmail";
import apiName from "../../../Utils/BackendApi";


import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

import 'dayjs/locale/en';
// const customParseFormat = require('dayjs/plugin/customParseFormat');
// dayjs.extend(customParseFormat);
dayjs.locale('en'); // Set the locale
dayjs.extend(require('dayjs/plugin/weekday'));

export default function ViewExam() {

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const { examId } = useParams();
    const navigate = useNavigate();
    const [questionReview, setQuestionReview] = useState();
    const [subjectName, setSubjectName] = useState('');
    const [SelectedData, setSelectedData] = useState([]); // Initialize with initially selected rows
    const [errorsMessage, setErrorsMessage] = useState({});
    const [validater, setValidater] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [loader, setLoader] = useState(false);
    const [conformation, setConformation] = useState(false);
    const [resendEmail, setResendEmail] = useState(false);
    const [qbIndex, setQbIndex] = useState('');
    const [uploadExcel, setUploadExcel] = useState(false);


    const [inputValues, setInputValues] = useState({
        examName: "",
        date: "",
        startTime: "",
        endTime: "",
        duration: 0,
        negativeMark: false,
        notes: "",
        status: ""
    });

    const [oldSelectedData, setOldSelectedData] = useState([]);
    const [oldinputValues, setOldInputValues] = useState({
        examName: "",
        date: "",
        startTime: "",
        endTime: "",
        duration: 0,
        negativeMark: false,
        notes: "",
        status: ""
    });

    const isDisabled = String(inputValues.status) === "2" || String(inputValues.status) === "4";
    const isDisabledEmail = String(inputValues.status) === "1" || String(inputValues.status) === "2";

    const duration = () => {
        const format = 'HH:mm';
        const startDateTime = moment(inputValues.startTime, format);
        const endDateTime = moment(inputValues.endTime, format);
        const minutesDifference = endDateTime.diff(startDateTime, 'minute');

        return (minutesDifference)
    }

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])

    useEffect(() => {
        axios.get(`${apiName}api/exam/get-exam/${userId}/${examId}`).then((res) => {
            if (res.data !== 'No data found') {
                setLoader(true);
                console.log("time: ", dayjs(`2000-01-01 ${res.data.start_time}`).format('HH:mm'))
                setInputValues({
                    examName: res.data.exam_name,
                    date: res.data.start_date,
                    startTime: dayjs(`2000-01-01 ${res.data.start_time}`).format('HH:mm'),
                    endTime: dayjs(`2000-01-01 ${res.data.end_time}`).format('HH:mm'),
                    notes: res.data.description,
                    negativeMark: res.data.enable_negative_mark,
                    duration: duration(res.data.start_time, res.data.end_time),
                    status: res.data.status
                })
                setOldInputValues({
                    examName: res.data.exam_name,
                    date: res.data.start_date,
                    startTime: dayjs(`2000-01-01 ${res.data.start_time}`).format('HH:mm'),
                    endTime: dayjs(`2000-01-01 ${res.data.end_time}`).format('HH:mm'),
                    notes: res.data.description,
                    negativeMark: res.data.enable_negative_mark,
                    duration: duration(res.data.start_time, res.data.end_time),
                    status: res.data.status
                })
                const allQuestions = [];

                res.data.question_banks.length > 0 && res.data.question_banks.forEach((questionBank) => {
                    questionBank.questions.forEach((question) => {
                        const questionData = {
                            id: question.id,
                            questionbank: question.questionbank,
                            question: question.question,
                            option_a: question.option_a,
                            option_b: question.option_b,
                            option_c: question.option_c,
                            option_d: question.option_d,
                            correct_option: question.correct_option,
                            subject: questionBank.name
                        };

                        allQuestions.push(questionData);
                    });
                });

                setSelectedData(allQuestions);
                setOldSelectedData(allQuestions);
                // console.log('allQuestions: ==================== ', allQuestions)
            }
            else {
                setLoader(true);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });
    }, [examId, userId])


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === "checkbox") {
            setInputValues((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        }
        else {
            setInputValues((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }
    };

    const validateTimeRange = (start, end) => {
        if (start && end) {
            if (start > end) {
                toast.warning('Start time cannot be greater than end time', { toastId: "timevalidation" });
            }
        }
    };

    const antdhandleChangestart = (value, dateString) => {
        const newStartTime = value ? dayjs(`2000-01-01 ${dateString}`).format('HH:mm') : null;
        setInputValues((prevState) => {
            validateTimeRange(newStartTime, prevState.endTime);

            return {
                ...prevState,
                startTime: newStartTime,
            };
        });
    };

    const antdhandleChangeend = (value, dateString) => {
        const newEndTime = value ? dayjs(`2000-01-01 ${dateString}`).format('HH:mm') : null;
        setInputValues((prevState) => {
            validateTimeRange(prevState.startTime, newEndTime);

            return {
                ...prevState,
                endTime: newEndTime,
            };
        });
    };


    useEffect(() => {
        if (inputValues.startTime && inputValues.endTime) {
            const format = 'HH:mm';
            const startDateTime = moment(inputValues.startTime, format);
            const endDateTime = moment(inputValues.endTime, format);
            const minutesDifference = endDateTime.diff(startDateTime, 'minute');
            console.log("timeDifference: ", minutesDifference);

            if (minutesDifference === 0) {
                setErrorsMessage((prevState) => ({
                    ...prevState,
                    endTime: "Invalid time",
                }));
                setValidater(true);
            }
            else {
                setErrorsMessage((prevState) => ({
                    ...prevState,
                    endTime: "",
                }));
                setValidater(false);
            }

            setInputValues((prevState) => ({
                ...prevState,
                duration: minutesDifference,
            }));
        }
    }, [inputValues.startTime, inputValues.endTime])


    // console.log(inputValues)

    // ============================================== select question ========================================================
    const handleSelectRows = (selectedRows) => {
        setSelectedData(selectedRows);
    };

    const handlexpanding = (expandable) => {
        setExpanded(expandable);
        setQbIndex('');
    };

    const handleRemove = (item) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.filter((row) => Number(row.id) !== Number(item.id))
        );
    }

    const rows = SelectedData
        ? SelectedData.map((item, index) => ({
            id: item.id,
            no: index + 1,
            subject: item.subject,
            question: item.question,
            options: [item.option_a, item.option_b, item.option_c, item.option_d],
            answer: item.correct_option,
            select: <button className="btn btn-none" style={{ border: "none" }} onClick={() => handleRemove(item)}><i className="bi bi-trash2-fill"></i></button>,
        }))
        : '';


    const [studentDataArray, setStudentDataArray] = useState([]);
    const [studentOldDataArray, setStudentOldDataArray] = useState([]);
    const [openStudent, setOpenStudent] = useState(false);
    const [studentInput, setStudentInput] = useState({
        id: "",
        indexId: "",
        studentName: "",
        studentEmail: "",
        studentPhone: ""
    })
    const [studentErrorsMessage, setStudentErrorsMessage] = useState({});
    const [studentIsSubmitting, setStudentIsSubmitting] = useState(false);

    useEffect(() => {
        axios.get(`${apiName}api/user/get-students-in-exam/${userId}/${examId}`)
            .then((res) => {
                if (res.data !== 'No data found') {
                    const newData = res.data.map((item) => ({
                        id: item.id,
                        name: item.username,
                        email: item.email,
                        phone: String(item.phone),
                        user_password: item.user_password,
                        admission_number: item.admission_number,
                    }));

                    setStudentDataArray((prevStudentDataArray) => [
                        ...prevStudentDataArray,
                        ...newData
                    ]);
                    setStudentOldDataArray(newData);
                }
            })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-student-api" });
            });
    }, [!openStudent]);


    const handleRowClick = (item, index) => {
        setOpenStudent(true);
        setStudentInput((prevState) => ({
            ...prevState,
            id: item.id,
            indexId: index,
            studentName: item.name,
            studentEmail: item.email,
            studentPhone: item.phone
        }));
    }

    const handleStudentChange = (event) => {
        const { name, value } = event.target;
        setStudentInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setStudentErrorsMessage((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const handleStudentSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(studentInput)) {
            var name;
            if (key === "studentName") { name = "Name" };
            if (key === "studentEmail") { name = "Email" };
            if (key === "studentPhone") { name = "Phone" };

            if (key !== 'id' && key !== 'indexId') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else if (key === "studentName" && !/^[a-zA-Z\s]{3,25}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "name must be 3-25 letters";
                }
                else if (key === "studentEmail" && !/\S+@\S+\.\S+/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is invalid`
                }
                else if (key === "studentPhone" && !/^\d{10}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is invalid`
                }
            }

        }

        setStudentErrorsMessage(errorMessage);

        if (Object.keys(errors).length === 0) {
            console.log("studentInput: ",studentInput)
            if (studentInput.id !== "" && studentInput.id !== undefined ) {
                axios.put(`${apiName}api/user/update-student/${studentInput.id}`, {
                    name: studentInput.studentName,
                    email: studentInput.studentEmail,
                    phone: String(studentInput.studentPhone),
                })
                    .then((res) => {
                        if (res.data) {
                            toast.success(res.data, { toastId: "addTax-1" });
                            setOpenStudent(false);
                            setStudentInput({ id: "", indexId:"" ,studentName: "", studentEmail: "", studentPhone: "" });
                            setStudentIsSubmitting(false);
                            // Update the studentDataArray with the new values
                            setStudentDataArray((prevData) =>
                                prevData.map((item) =>
                                    item.id === studentInput.id ? { ...item, name: studentInput.studentName, email: studentInput.studentEmail, phone: String(studentInput.studentPhone) } : item
                                )
                            );
                        } else {
                            toast.error("Failed, please try again", { toastId: "addTax-1" });
                            setStudentIsSubmitting(false);
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                        setStudentIsSubmitting(false);
                    });
            } 
            // else if (studentInput.indexId !== "" && studentInput.id === undefined ) {
            //     const updatedArray = studentDataArray.map((item, index) => {
            //       if (studentInput.indexId === index) {
            //         return {
            //           ...item,
            //           name: studentInput.studentName,
            //           email: studentInput.studentEmail,
            //           phone: String(studentInput.studentPhone),
            //         };
            //       }
            //       return item;
            //     });
              
            //     setStudentDataArray(updatedArray);
            //     setOpenStudent(false);
            //     setStudentInput({id:"", indexId:"", studentName: "", studentEmail: "", studentPhone: "" });
            //     setStudentIsSubmitting(false);
            //   }
            else {
                const isDuplicate = studentDataArray.some((item) =>
                    item.name === studentInput.studentName &&
                    item.email === studentInput.studentEmail &&
                    String(item.phone) === String(studentInput.studentPhone)
                );

                if (isDuplicate) {
                    toast.warn("Duplicate data found!", {
                        position: toast.POSITION.TOP_CENTER,
                    }, { toastId: "duplicate-1" });
                } else {
                    const newStudent = {
                        name: studentInput.studentName,
                        email: studentInput.studentEmail,
                        phone: String(studentInput.studentPhone),
                    };

                    setStudentDataArray([...studentDataArray, newStudent]);
                    // toast.success("Student added successfully!", { toastId: "addQues-1" });
                    setOpenStudent(false);
                    setStudentInput({id:"", indexId:"", studentName: "", studentEmail: "", studentPhone: "" });
                    setStudentIsSubmitting(false);
                }
            }
        } else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]}. Please make sure to include it.`, { toastId: "error" });
        }
    };

    const handleCloseStudent = () => {
        setOpenStudent(false);
        setStudentInput({ id: "", indexId:"", studentName: "", studentEmail: "", studentPhone: "", });
    };


    // Function to handle file input change from excelsheetpopup
    const handleFileChange = (newDataArray) => {
        setStudentDataArray([...studentDataArray, ...newDataArray]);
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "14px",
                minHeight: '40px'
            }
        },

    }

    const studentcolumns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            // center: 'true',
            width: '100px'
        },
        {
            name: 'Admission',
            selector: row => row.admission,
        },
        {
            name: 'Name',
            selector: row => row.name,
            grow: 2,
            // center: 'true',
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2,
            // center: 'true',
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            grow: 1,
            // center: 'true',
        },
        {
            name: 'Edit',
            selector: row => row.Edit,
            // grow: 1,
            width: "100px",
            center: 'true',
        },
    ];

    const filteredData = studentDataArray.filter((value, index, self) => {
        return (
            index === self.findIndex((item) =>
                item.name === value.name &&
                item.email === value.email &&
                String(item.phone) === String(value.phone)
            )
        );
    });

    const studentrows = filteredData
        ? filteredData.map((item, index) => ({
            id: item.id,
            no: index + 1,
            admission: item.admission_number ? item.admission_number : 'New Student',
            name: item.name,
            email: item.email,
            phone: String(item.phone),
            Edit: (<div>
                <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => handleRowClick(item, index)}
                    disabled={!item.id || isDisabled}
                    sx={{ color: 'rgb(116, 103, 239)' }}
                >
                    <Tooltip title="Edit" arrow>
                        <ModeEditIcon />
                    </Tooltip>
                </IconButton>
            </div>)
        }))
        : '';

    //=================================== add student end code ==============================================


    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(inputValues)) {
            var name;
            if (key === "examName") { name = "Exam Name" };
            if (key === "date") { name = "Date" };
            if (key === "startTime") { name = "Start Time" };
            if (key === "endTime") { name = "End Time" };


            if (key !== 'negativeMark' && key !== 'notes') {
                if (key === 'date' && value === 'Invalid Date') {
                    errors[key] = true;
                    errorMessage[key] = `${name} is Invalid Date`;
                }else {
                    errors[key] = false;
                    errorMessage[key] = ``;
                }

                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else {
                    errors[key] = false;
                    errorMessage[key] = ``;
                }

                if (key === 'startTime' || key === 'endTime') {
                    if(value === null){
                        errors[key] = true;
                        errorMessage[key] = `${name} is required`;
                    } else {
                        errors[key] = false;
                        errorMessage[key] = ``;
                    }
                }
            }
        };

        // setErrorsMessage(errorMessage);
        setErrorsMessage((prevState) => ({
            ...prevState,
            ...errorMessage,
        }));

        const uniqueQuestionBankIds = [...new Set(SelectedData.map(item => item.questionbank))];
        const uniqueQuestionIds = [...new Set(SelectedData.map(item => item.id))];
        const filtervalidation = Object.values(errors).filter(item => item !== false);
        const filterErrorMsg = Object.values(errorMessage).filter(item => item !== "");

        if (validater) {
            toast.warning(`Invalid time, please make sure to correct it.`, { toastId: "error-time" });
        } 
        else if (filtervalidation.length !== 0) {
            toast.warning(`${filterErrorMsg[0]} please make sure to include it.`, { toastId: "error-validation" });
        }  
        else if (uniqueQuestionIds.length === 0) {
            toast.warning(`Please provide questions for an exam.`, { toastId: "error1-sq" });
        } 
        if(!validater && filtervalidation.length === 0 && uniqueQuestionIds.length > 0) {
            handleSubmitReview(uniqueQuestionBankIds, uniqueQuestionIds);
            console.log("inputValues: ", inputValues, "qbids: ", uniqueQuestionBankIds, "qids: ", uniqueQuestionIds);
        }
    
    }

    const handleSubmitReview = (uniqueQuestionBankIds, uniqueQuestionIds) => {
        console.log("notes: ", oldinputValues, "====", inputValues, "====", oldSelectedData, "===", SelectedData)
        if (_isEqual(oldinputValues, inputValues) && _isEqual(oldSelectedData, SelectedData)) {
            handleSubmitCandidate();
        }
        else {
            setIsSubmitting(true);
            axios.put(`${apiName}api/exam/update-exam/${userId}/${examId}`, {
                exam_name: inputValues.examName,
                start_date: inputValues.date,
                start_time: inputValues.startTime,
                end_time: inputValues.endTime,
                description: inputValues.notes,
                enable_negative_mark: inputValues.negativeMark,
                question_bank_ids: uniqueQuestionBankIds,
                question_ids: uniqueQuestionIds
            })
                .then((res) => {
                    if (res.data) {
                        // toast.success("Exam has been updated", { toastId: "addQues-1" });
                        setIsSubmitting(false);
                        handleSubmitCandidate();
                    } else {
                        toast.error("Failed, please try again", { toastId: "addQues-2" });
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    toast.error(e.message, { toastId: "err-api" });
                    setIsSubmitting(false);
                });
        }
    }

    const uniqueSubjects = Array.from(new Set(rows.map(item => item.subject))); // Get unique subjects
    const subjectCounts = {};

    // Calculate the subject counts
    rows.forEach(item => {
        subjectCounts[item.subject] = (subjectCounts[item.subject] || 0) + 1;
    });

    const handleSubmitCandidate = () => {
        if (filteredData.length > 0) {
            setIsSubmitting(true);
            const newData = filteredData.map((item1) => {
                if (
                    !studentOldDataArray.some(
                        (item2) =>
                            item1.name === item2.name &&
                            item1.email === item2.email &&
                            String(item1.phone) === String(item2.phone)
                    )
                ) {
                    return item1;
                } else {
                    return null;
                }
            }).filter(item => item !== null);
            console.log("combinedArray: ================= ", newData)
            axios.post(`${apiName}api/user/create-user/${userId}/${examId}`, newData)
                .then((res) => {
                    if (res.data) {
                        res.data !== "User already has this Exam" && toast.success("Exam has been updated", { toastId: 'erruploadexcel1' });
                        setIsSubmitting(false);
                        navigate("/exam-list");
                    }
                })
                .catch((e) => {
                    toast.error(e.message, { toastId: "erruploadexcel2" });
                    setIsSubmitting(false);
                });
        }
        else {
            toast.warning("Please upload students data!", { toastId: "student-error" });
            setIsSubmitting(false);
        }

    }

    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);

    // Callback to update selectedRows state when rows are selected
    const handleRowSelected = useCallback((state) => {
        setSelectedRows(state.selectedRows);
    }, []);

    const clearSelecredRows = () => {
        setToggleCleared(!toggleCleared);
    }

    // Memoized context action for handling delete button click
    const contextActions = React.useMemo(() => {
        const handleDelete = () => {
            setResendEmail(true);
        };

        return (
        <button className="btn btn-custom" onClick={handleDelete}>
            E-mail
        </button>
        );
    }, [selectedRows, toggleCleared]);


    // console.log("inputs: ", inputValues)

    const exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Student list');

        // Function to add bold formatting to a cell
        const addBoldFormatting = (cell) => {
            cell.font = { bold: true };
        };

        const centerText = (cell) => {
            cell.alignment = { horizontal: 'middle', vertical: 'middle'  };
        }


        const studentDetailsRow = worksheet.addRow(['Student Details']);
        studentDetailsRow.eachCell({ includeEmpty: true }, addBoldFormatting);

        const headerRow = worksheet.addRow([
            'No',
            'Admission_number',
            'Name',
            'Email',
            'Phone',
            'Password'
        ]);

        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            addBoldFormatting(cell);
            centerText(cell);
        });

        studentOldDataArray.forEach((item, index) => {
            const row = worksheet.addRow([
                index + 1,
                item.admission_number,
                item.name,
                item.email,
                Number(item.phone),
                item.user_password
            ]);
        });


        // Create a buffer and save it as a file
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer]), `Student list.xlsx`);
        });
    };


    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ? 
            <div className="main-content">
                <div className="mx-sm-5">
                    {/* <div className="text-start fw-bold fs-3 text-capitalize">
                        View Exam
                    </div> */}
                    <div className="row">
                        <div className="col-sm-9 text-start fw-bold fs-3 text-capitalize">
                            View Exam
                        </div>
                        <div className="col-sm-3 text-end">
                                <button onClick={exportToExcel} className="my-2 btn btn-success" style={{ cursor: "pointer" }}>Student list</button>
                        </div>
                    </div>
                    {/* Basic info */}
                    <div>
                        <div style={{ margin: "35px 0px 0px" }}>
                            <div className="formCard">
                                <div className="py-1">
                                    <div className="my-2">
                                        <div className="questions-label" style={{ margin: "-35px 0px 10px" }}>
                                            <span className="questions-header">Basic info</span>
                                        </div>
                                        <div className="form-group row my-2 text-center">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <label htmlFor="exam-name" className="col-md-2 col-form-label question-label">Name:</label>
                                                    <div className="col-md-10">
                                                        <input
                                                            id="exam-name"
                                                            name="examName"
                                                            className="form-control exam-input"
                                                            type="text"
                                                            value={inputValues.examName}
                                                            onChange={handleChange}
                                                        />
                                                        {errorsMessage.examName && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.examName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 input-exam-box">
                                                <div className="row">
                                                    <label className="col-md-2 col-form-label question-label">Date:</label>
                                                    <div className="col-md-10">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <MobileDatePicker
                                                                className="form-control exam-input"
                                                                value={inputValues.date ? dayjs(inputValues.date) : null}
                                                                onChange={(newValue) =>
                                                                    setInputValues((prevState) => ({
                                                                        ...prevState,
                                                                        date: dayjs(newValue).format('YYYY-MM-DD')
                                                                    }))
                                                                }
                                                                minDate={dayjs()}
                                                                format="DD/MM/YYYY"
                                                                sx={{
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    borderRadius: '8px',
                                                                    fontSize: "14px",
                                                                    '& input': {
                                                                        border: '2px solid rgb(116, 103, 239)',
                                                                        borderRadius: '8px',
                                                                        outline: 'none',
                                                                        fontSize: "14px",
                                                                        '&:focus': {
                                                                            borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        },
                                                                        '&:active': {
                                                                            borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        },
                                                                        '&::placeholder': {
                                                                            verticalAlign: 'bottom',
                                                                            fontSize: "14px",
                                                                        },
                                                                    },
                                                                    '& fieldset': {
                                                                        border: 'none',
                                                                        // borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        borderRadius: '8px',
                                                                        fontSize: "14px",
                                                                    }
                                                                }}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                            />
                                                        </LocalizationProvider>

                                                        {errorsMessage.date && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.date}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row my-2 text-center">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="form-group my-2 d-flex align-items-center justify-content-center">
                                                        <label htmlFor="exam-marks" className="col-form-label question-label me-4">Negative Marks:</label>
                                                        <div className="">
                                                            <label className="switch">
                                                                <input
                                                                    id="exam-marks"
                                                                    name="negativeMark"
                                                                    type="checkbox"
                                                                    checked={inputValues.negativeMark}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <label className="col-md-2 col-form-label question-label">Time:</label>
                                                    <div className="col-md-5">
                                                        <ConfigProvider
                                                            theme={{
                                                                token: {
                                                                    activeBorderColor: 'red',
                                                                    cellActiveWithRangeBg: '#f6ffed',
                                                                },
                                                                components: {
                                                                    TimePicker: {
                                                                        backgroundColor: 'rgb(116, 103, 239)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <TimePicker
                                                                className=" exam-input w-100 py-2"
                                                                format={'hh:mm A'}
                                                                value={(inputValues.startTime && inputValues.startTime !== 'Invalid date') ? dayjs(`2000-01-01 ${inputValues.startTime}`) : null}
                                                                onChange={antdhandleChangestart}
                                                                placeholder="Start time"
                                                            />
                                                        </ConfigProvider>

                                                        {errorsMessage.startTime && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.startTime}</span>}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ConfigProvider
                                                            theme={{
                                                                token: {
                                                                    activeBorderColor: 'red',
                                                                    cellActiveWithRangeBg: '#f6ffed',
                                                                },
                                                                components: {
                                                                    TimePicker: {
                                                                        backgroundColor: 'rgb(116, 103, 239)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <TimePicker
                                                                className=" exam-input w-100 py-2"
                                                                format={'hh:mm A'}
                                                                value={(inputValues.endTime && inputValues.endTime !== 'Invalid date') ? dayjs(`2000-01-01 ${inputValues.endTime}`) : null}
                                                                onChange={antdhandleChangeend}
                                                                placeholder="End time"
                                                            />
                                                        </ConfigProvider>

                                                        {errorsMessage.endTime && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.endTime}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard mt-2 py-1">
                            <div className="questions-label" style={{ margin: "-20px 0px 10px" }}>
                                <span className="questions-header">{isDisabled ? "Select Question Banks" : "Select Questions"}</span>
                            </div>
                            <SelectQuestions
                                selectedData={SelectedData}
                                isDisabled={isDisabled}
                                onSelectRows={handleSelectRows}
                            />
                            {!isDisabled && rows.length > 0 &&  <hr />}
                            {rows.length > 0 && <>
                                
                                <div className="">
                                    <div className="mt-2" style={{display: isDisabled ? "none" : ""}}>
                                        <span className="questions-header" style={{ background: 'linear-gradient(90deg, rgb(207, 103, 239) -19.83%, rgb(239, 103, 155) 189.85%)', fontWeight: "bold" }}>Select Question Banks</span>
                                    </div>

                                    <div className="my-2 row">
                                        {uniqueSubjects.map((subject, index) => (
                                            <div className={`col-2 m-3 pb-4 subject-list-card text-center w-auto`} style={{ display: qbIndex === index ? "none" : "block" }} key={index}>
                                                {/* {!expanded && ( */}
                                                <>
                                                    <div className="mt-2 fs-5 fw-bold text-capitalize">{subject}</div>
                                                    <span style={{ fontSize: "12px" }} onClick={() => {
                                                        setExpanded(index === qbIndex ? !expanded : true );
                                                        setQbIndex(index);
                                                        setSubjectName(subject);
                                                        setQuestionReview(false);
                                                    }}>View Questions</span>
                                                    <span style={{
                                                        position: "absolute",
                                                        top: "8%",
                                                        left: "80%",
                                                        backgroundColor: "skyblue",
                                                        borderRadius: "50%",
                                                        padding: "2px 8px",
                                                        fontSize: "10px",
                                                        fontWeight: "bold",
                                                        display: subjectCounts[subject] > 0 ? "block" : "none"
                                                    }}>{subjectCounts[subject]}</span>
                                                </>
                                                {/* )} */}
                                            </div>
                                        ))}
                                    </div>
                                    {expanded && (
                                        <div className={`col-12 pb-4 subject-list-card text-center`} >
                                            <ViewSelectedQuestionsPage
                                                selectedData={SelectedData}
                                                subjectName={subjectName}
                                                isDisabled={isDisabled}
                                                review={questionReview}
                                                onSelectRows={handleSelectRows}
                                                onExpandable={handlexpanding}
                                            />
                                        </div>
                                    )}
                                    <div className="my-2" style={{ display: rows.length > 0 ? "block" : "none" }}>
                                        <span>Total selected question bank for this <span className="fw-bold fs-6 text-capitalize">{inputValues.examName}</span> exam is <span className="fw-bold fs-6 text-capitalize">{uniqueSubjects.length} Question Bank</span>.</span><br />
                                        <span>Total no of questions for this <span className="fw-bold fs-6 text-capitalize">{inputValues.examName}</span> exam is <span className="fw-bold fs-6 text-capitalize">{rows.length} Questions</span>.</span>
                                    </div>

                                </div>
                            </>
                            }
                        </div>
                        <div className="question-link">
                        </div>
                        <div>
                            <div className="formCard my-2">
                                 <div className="questions-label" style={{ margin: "-25px 0px 10px" }}>
                                    <span className="questions-header">Upload Students</span>
                                </div>

                                {!isDisabled &&<div className="row mb-2">
                                    <div className="">
                                        <div style={{display:"flex", justifyContent:"flex-end"}}>
                                            <button className="btn btn-custom me-2" onClick={() => setOpenStudent(true)}>Add Student</button>
                                            <button className="btn btn-success" onClick={() => setUploadExcel(true)}>Upload Excel</button>
                                        </div>
                                    </div>
                                </div>
                                }
                                {!isDisabled && <hr />}
                                {studentDataArray.length > 0 ?
                                    <>
                                        <div className="my-2">
                                            <DataTable
                                                title={isDisabledEmail && <span style={{ fontSize: '16px' }}>Select students to re-send email</span>}
                                                columns={studentcolumns}
                                                data={studentrows}
                                                fixedHeaderScrollHeight="600px"
                                                customStyles={customStyles}
                                                persistTableHead
                                                pagination
                                                paginationPerPage={10}
                                                paginationRowsPerPageOptions={[10, 25, 50]}
                                                highlightOnHover
                                                selectableRows={isDisabledEmail}
                                                contextActions={contextActions}
                                                onSelectedRowsChange={handleRowSelected}
                                                clearSelectedRows={toggleCleared}
                                            />
                                        </div>
                                        {/* <div className="text-end fw-bold fs-3 text-capitalize my-3">
                                            <button className="btn btn-white" style={{ display: _isEqual(studentOldDataArray, studentDataArray) ? "none" : "block" }} disabled={_isEqual(studentOldDataArray, studentDataArray)}>Undo</button>
                                        </div> */}
                                    </>
                                    :
                                    <>
                                        <div className="">
                                            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <div className="text-center">
                                                <p>Welcome to <span className="fw-bold fs-6 text-capitalize">{inputValues.examName}</span> exam please add students for your exam</p>
                                            </div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>


                        </div>
                    </div>

                    {/* QUESTION PAGE */}

                    <div>
                        <div className="text-end fw-bold fs-3 text-capitalize my-3">
                            <button className="btn btn-white me-2" onClick={() => navigate(-1)}>Back</button>
                            <button 
                                className="btn btn-custom" 
                                onClick={isDisabled ? null : handleSubmit}
                                disabled={isSubmitting} 
                                style={{
                                    opacity: isDisabled ? '0.5' : '',
                                    cursor: isDisabled ? 'not-allowed' : ''
                                  }}
                            >{isSubmitting ? 'Uploading...' : 'Save'}</button>
                        </div>
                    </div>
                </div>
            </div>
            :
            <LoadingSpinner />
            }


            <Conformation
                selectExamId={examId}
                isOpen={conformation}
                onClose={() => {
                    setConformation(false);
                }}
            />
            <ResendEmail
                selectExamId={examId}
                studentData={selectedRows}
                clearData={clearSelecredRows}
                isOpen={resendEmail}
                onClose={() => {
                    setResendEmail(false);
                }}
            />
            <Dialog
                open={openStudent}
                onClose={handleCloseStudent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                scroll={'body'}
                style={{ height: "auto", maxHeight: "900px" }}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center " style={{ marginBottom: '-20px' }}>
                    {studentInput.id ? "Edit Student" : "Add Student"}
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleCloseStudent}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 mt-2 fs-3">
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-name" style={{width:"80px"}}>Name: </label>
                        <input id="student-name" type="text" name='studentName' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentName || ""} onChange={handleStudentChange} />
                    </div>    
                        {studentErrorsMessage.studentName && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentName}</span>}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-email" style={{width:"80px"}}>Email: </label>
                        <input id="student-email" type="text" name='studentEmail' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentEmail || ""} onChange={handleStudentChange} />
                    </div>    
                        {studentErrorsMessage.studentEmail && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentEmail}</span>}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-phone" style={{width:"80px"}}>Phone: </label>
                        <input id="student-phone" type="text" name='studentPhone' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentPhone || ""} onChange={handleStudentChange} />
                    </div>
                        {studentErrorsMessage.studentPhone && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentPhone}</span>}
                    </DialogContentText>
                </DialogContent>
                <hr />
                <DialogActions style={{ marginTop: '-15px' }}>
                    <Button onClick={handleCloseStudent} className={`btn fw-bold text-dark`}>
                        Cancel
                    </Button>
                    <Button onClick={handleStudentSubmit} disabled={studentIsSubmitting} color="primary" className={`btn fw-bold`}>
                        {studentIsSubmitting ? 'Uploading...' : 'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>

            <UploadExcelStudent
                studentDataArray={studentDataArray} 
                CallbackOnChange={handleFileChange}
                isOpen={uploadExcel}
                onClose={() => {
                    setUploadExcel(false);
                }}
            />

        </div>
    )
}