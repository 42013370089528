import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import { toast } from 'react-toastify';
import apiName from "../../../../Utils/BackendApi";

const btnOK = {
  backgroundColor: "#464646",
  textTransform: "none",
  cursor: "pointer",
  color: "white",
  fontSize: "12px",
}

export default function DeleteQuestion(props) {
  const { questionBankId, selectQuestion, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const user = localStorage.getItem("mcq-user");
  const userId = user && JSON.parse(user).id;

  const [isSubmitting, setIsSubmitting] = useState(false);


  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen,questionBankId, selectQuestion]);

  const handleSubmit = () => {
    axios.delete(`${apiName}api/question/delete-question/${userId}/${selectQuestion.id}`)
    .then((res) => {
        if (res.data) {
            setIsSubmitting(true);
          setTimeout(() => {
            const str = String(res.data);
            const str2 = str.length > 2 ? str.charAt(0).toUpperCase() + str.slice(1) : str;

            toast.success(str2, { toastId: "listdel" });
            setOpen(false);
            if (onClose) onClose();
            setIsSubmitting(false);
          }, 1000);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listdel" });
      });
  };


  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px"}}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogContent className="px-4 my-2 text-center">
        <i className="bi bi-x-circle text-danger" style={{ fontSize: '75px' }}> </i>
        <div className='fw-bold mt-3'>
        <h5 style={{color: "#685e5e" }}>Are you sure</h5>
        <h5>Do you really want to delete these record?</h5>
        </div>
      </DialogContent>
      <DialogActions style={{marginTop:'-15px'}}>
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`${btnOK} btn fw-bold`}>
            {isSubmitting ? 'Uploading...' : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
