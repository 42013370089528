import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import './Candidate_Login.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './Change_password.css'
import { toast } from 'react-toastify';
import apiName from "../Utils/BackendApi";

export default function Changepassword() {

    const location = useLocation();
    const navigate = useNavigate();
    let email = location.state.email;
    const role = location.state.role;
    const [input, setInput] = useState({
        otp: "",
        password: "",
        confirmPassword: "",
    })
    const [type1 ,setType1] = useState('password');
    const [type2 ,setType2] = useState('password');
    const [errors, setErrors] = useState({});


const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrors( { ...errors, [e.target.name]: "" });
    };



    //prevent form submitting
function handleSubmit(e) {
    e.preventDefault();
}

    //API call for new password
    const resetPassword = (() => {
        const validationstatus = validation(input);
        if (!validationstatus.status) {
             setErrors(validationstatus.errors);
        } else{

        axios.put(`${apiName}api/forgot-password-reset`, {
            otp: input.otp,
            password: input.confirmPassword,
            email: email
        }).then(res => {
            if(res.data === "Password Updated... "){
            toast.success("Password  reset successfully")
            role ==='candidate'? navigate('/Candidate_Login'): navigate('/admin-login')
           
        }
            else{
                toast.error("Invalid OTP")
            }
        })
    }  
    });
   

// validation function 

function validation(data){
    let errors = {}
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    var status = true;
    if (input.otp === "") {
        status = false;
        errors.otp = "Please enter OTP."
    }
    if (input.password === "") {
        status = false;
        errors.password = "Please enter password."
    }

    if (input.confirmPassword === "") {
        status = false;
        errors.confirmPassword = "Please enter confirm password."
    }

    if (input.password && !password_pattern.test(input.password)) {
        status = false;
        errors.password = "Password must be eight characters including one uppercase letter, one special character and alphanumeric characters"
    }

    if (input.confirmPassword && String(input.confirmPassword) !== String(input.password)) {
        status = false;
        errors.confirmPassword = "Please make sure your passwords match"
    }

    return {'errors':errors,'status':status};
}




    const handleToggle1 =()=>{
        type1==='password'? setType1('text'): setType1('password')
    }

    
    const handleToggle2 =()=>{
        type2==='password'? setType2('text'): setType2('password')
    }

    return (
    <div className=" p-5 change-pswd">
        <div className="chngpswd-card" >
            <div className="card-body">
                <h4 className="card-title text-center ">Reset Password</h4>
                <form className='form flex' onSubmit={handleSubmit}>
                <div className=" m-3">
                    <h6 className="card-text text-center" style={{color:"brown"}}>
                        Please enter the OTP sent to <br /> {email}</h6>
                            <div className="form-group row">
                                <label htmlFor="otp" className="col col-form-label " style={{display: "flex",color: "#685e5e"}}>OTP</label>
                                <div className="input-group1" >
                                    <input name="otp" type="number" 
                                    onChange={(e)=>{handleInput(e)}}
                                    className="form-control"  />
                                </div>
                                 <span  className="chng-error-msg">
                                     {errors.otp ? errors.otp : ""}
                                </span>
                            </div>    
                </div>
                <div className=" m-3">
                            <h6 className="card-text text-center p-2" style={{color:"brown"}}>Please Change your Password!</h6>
                
                <div className="form-group chngpswd-form-group">
                <label htmlFor="newpassword" style={{color: "#685e5e"}} className="col col-form-label  text-start">New Password</label>
                        
                    <input type={type1}
                        name="password"
                        className="form-control  psd-input" 
                        required="required"
                        placeholder="Enter New Password" 
                        onChange={(e)=>{handleInput(e)}}
                        
                        />
                    <i className={type1 === 'password' ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                         id="chg-pass-tog"
                         style={{top:"50%"}}
                        onClick={handleToggle1}></i>
                 <span  className="chng-error-msg">
                 {errors.password ? errors.password : ""}
                    </span>
                </div>

                <div className="form-group chngpswd-form-group">
                <label htmlFor="newpassword" style={{color: "#685e5e"}} className="col col-form-label text-start">Confirm Password</label>
                        
                    <input type={type2}
                        name="confirmPassword"
                        className="form-control  psd-input" 
                        required="required"
                        placeholder="Enter Confirm Password" 
                        onChange={(e)=>{handleInput(e)}}
                        
                        />
                    <i className={type2 === 'password' ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                         id="chg-pass-tog"
                         style={{top:"50%"}}
                        onClick={handleToggle2}></i>

                    <span  className="chng-error-msg" >
                    {errors.confirmPassword ? errors.confirmPassword : ""}
                    </span>
                </div>
            </div>
                        <div className="text-center mb-4">
                        <button type="button" onClick={resetPassword} 
                            className="btn btn-primary w-50 " id="chngpswd-btn">Submit</button>
                        </div>
                        <div className="text-center ">
                                 <p style={{fontWeight: "500"}} >
                                    Back to  
                                   <span className="login-link"
                                   onClick={
                                    ()=>{
                                        role === 'candidate'? navigate('/Candidate_Login'): navigate('/admin-login')
                                    }

                                   }> Login</span></p>     
                        </div>
                       
                </form>
                </div>
            </div>
    </div>
    );
};