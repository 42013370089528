import { useNavigate, useLocation } from "react-router-dom"; 

export default function ExamIntro() {

    const navigate = useNavigate();
    const location = useLocation();

    const examData = location.state;

    const startExam = () => {
        navigate('/mcq-exam', {state: examData})
        localStorage.setItem('yourAppState', JSON.stringify(examData));
      };

    return(
        <div className="exam-intro-div">
            <div className="intro-main-div">
                    <p className="current-text-heading">Welcome to the [online exam]. Please read the following instructions carefully before proceeding:</p>
                    <ol className="current-text-subheading py-2" type="1">
                        <li className="my-2"><span className="fw-bold">Question Reading:</span>  <br /> Ensure that you read each question thoroughly before making your selection.</li>
                        <li className="my-2"><span className="fw-bold">Skipping Questions:</span> <br /> You have the option to skip a question if needed.</li>
                        <li className="my-2"><span className="fw-bold">Scoring:</span> <br /> Each question carries one mark. Be aware that for every incorrect answer, 0.25 marks will be deducted from your total score.</li>
                        <li className="my-2"><span className="fw-bold">Exam Completion:</span> <br /> After answering all questions, click on the "Submit" button. If you do not submit manually, the exam will be automatically submitted after the end time.</li>
                        <li className="my-2"><span className="fw-bold">Tab/Window Usage:</span> <br /> Do not attempt to switch tabs or windows during the exam. If you switch tabs or windows three times, the exam will be automatically submitted.</li>
                    </ol>
                    <p className="current-text-subheading">Please adhere to these guidelines to ensure a smooth and fair examination experience. Good luck!</p>
                    <div className='text-center'>
          <button className="start-exam-button btn btn-primary btn-lg" onClick={startExam}>
            Start Exam
          </button>
        </div>
                </div>
        </div>
    )
}