import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';


function NoDataModal() {
    let navigate = useNavigate();
  return (
    
  
    <Modal show={true} className="d-flex justify-content-center align-items-center">
                <Modal.Header>
                    <div className='container'>
                        <h3 className='text-center'>Sorry for this!</h3>
                    </div>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <div className='container'>
                        <h4 className='text-center'>
                            Currently all servers are down
                            <br />
                            Please try again after some times!!!
                        </h4>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <div className="text-end">
                        <button className="btn btn-info exam-btn" onClick={() => { navigate('/') }}>Go Back</button>
                    </div>
                </Modal.Footer>
    </Modal>  
  )
}

export default NoDataModal