import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLogin from './Components/Login/Admin/Admin';
import AdminRegistration from './Components/Register/Registration'
import CandidateLogin from './Components/Login/Candidate/Candidate_Login'
import AdminDashboard from './Components/Admin/Dashboard/Admin_Dashboard';
import ExamList from './Components/Admin/Exam/Exam Pages/Exam_List';
import CreateExam from './Components/Admin/Exam/Exam Pages/Create_Exam_Page';
import ViewExam from './Components/Admin/Exam/Exam Pages/View_Exam_Page';
import QuestionBank from './Components/Admin/Exam/Question Bank/Course list/Question_bank';
import AddQuestion from './Components/Admin/Exam/Question Bank/Questions/Add_Question_Page';
import EditQuestion from './Components/Admin/Exam/Question Bank/Questions/Edit_Question_Page';
import QuestionTable from './Components/Admin/Exam/Question Bank/Questions/Question_Table';
import ExamIntro from './Components/Candidate/Exam Intro/ExamIntro';
import ExamPage from './Components/Candidate/Exam page/ExamPage';
import ViewRecord from './Components/Admin/Exam/View Records/View_Records';
import StudentInExam from './Components/Admin/Exam/View Records/Exam_Student_Records';
import StudentList from './Components/Admin/Student_Data/Student_List';
import Error404Page from './Components/Utils/404_Page';

import Changepassword from './Components/Login/Change_password';
import CandidateDashboard from './Components/Candidate/Dashboard/CandidateDashboard';

import 'dayjs/locale/en-in'; // Load the Indian English locale
import dayjs from 'dayjs'; // Import dayjs

// Set the Indian English locale globally
// dayjs.locale('en-in');

function App() {
  return (
    <div>
      <Router>
          <Routes  >
          <Route path='/' element={< CandidateLogin />}></Route>
          <Route path='/admin-login' element={< AdminLogin />}></Route>
          <Route path='/Change_password' element={< Changepassword />}></Route>
          <Route path='/admin-registration' element={< AdminRegistration />}></Route>

          <Route path='/admin-dashboard' element={< AdminDashboard />}></Route>
          <Route path='/exam-list'>
            <Route index element={< ExamList />}></Route>
            <Route path='create-exam' element={< CreateExam />}></Route>
            <Route path='view-exam/:examId' element={< ViewExam />}></Route>
          </Route>
          <Route path='/question-bank'>
            <Route index element={< QuestionBank />}></Route>
            <Route path='question-list/:id/:name' element={< QuestionTable />}></Route>
            <Route path='add-question/:id/:name' element={< AddQuestion />}></Route>
            <Route path='edit-question/:id/:name/:qid' element={< EditQuestion />}></Route>
          </Route>
          <Route path='/exam-records'>
            <Route index element={< ViewRecord />}></Route>
            <Route path='exam-student-records/:id/:name' element={< StudentInExam />}></Route>
          </Route>
          <Route path='/list-of-all-students' element={< StudentList />}></Route>

          <Route path='/candidate-dashboard' element={< CandidateDashboard />}></Route>

          <Route path='/exam-intro' element={< ExamIntro />}></Route>
          <Route path='/mcq-exam' element={< ExamPage />}></Route>
          <Route path='/error-page' element={<Error404Page />}></Route>
            <Route path='*' element={<Error404Page />}></Route>
          </Routes>
    </Router>
    <ToastContainer position="bottom-right"
         limit={1}
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
    </div>
  );
}

export default App;
