import React, { useState, useEffect } from "react";
import Sidebar from '../../../Sidebar/Sidebar';
import LeftSideBar from "../../../Sidebar/Left_SideBar";
import './Question.css'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../../Utils/LoadingSpinner";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import apiName from "../../../../Utils/BackendApi";

export default function EditQuestion() {
    const { id } = useParams();
    const { qid } = useParams();
    const { name } = useParams();
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const [inputValues, setInputValues] = useState({
        questionbank:"",
        question:"",
        option_a:"",
        option_b:"",
        option_c:"",
        option_d:"",
        correct_option:""
      })
    
      const [errorsMessage, setErrorsMessage] = useState({});
      const [isSubmitting, setIsSubmitting] = useState(false);
    
      const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrorsMessage((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const handleClose = () => {
        setErrorsMessage({});
        navigate(-1)
    };

    useEffect(() => {

        axios.get(`${apiName}api/question/get-questions/${userId}/${qid}/${id}`).then((res) => {
            if (res.data !== 'No data found') {
                setLoader(true);
                setInputValues((prevState) => ({
                    ...prevState,
                        questionbank: id,
                        question: res.data[0].question,
                        option_a: res.data[0].option_a,
                        option_b: res.data[0].option_b,
                        option_c: res.data[0].option_c,
                        option_d: res.data[0].option_d,
                        correct_option: res.data[0].correct_option
                    }));
            }
            else{
                setLoader(true);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api" });
          });

        
      }, []);
      
      const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(inputValues)) {
          var name;
          if(key === "question"){name = "Question"};
          if(key === "option_a"){name = "Option A"};
          if(key === "option_b"){name = "Option B"};
          if(key === "option_c"){name = "Option C"};
          if(key === "option_d"){name = "Option D"};
          if(key === "correct_option"){name = "Answer"};
    
            if (!String(value).trim()) {
              errors[key] = true;
              errorMessage[key] = `${name} is required`;
            }
        }
    
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
        
            axios.put(`${apiName}api/question/update-questions/${userId}/${qid}`, {
                  questionbank: inputValues.questionbank,
                  question: inputValues.question,
                  option_a: inputValues.option_a,
                  option_b: inputValues.option_b,
                  option_c: inputValues.option_c,
                  option_d: inputValues.option_d,
                  correct_option: inputValues.correct_option
              })
              .then((res) => {
                if (res.data) {
                  setTimeout(() => {
                    toast.success("Question has been Updated", { toastId: "addQues-1" });
                    setErrorsMessage({});
                    navigate(-1)
                    setIsSubmitting(false);
                  }, 1000);              
                } else {
                  toast.error("Failed, please try again", { toastId: "addQues-1" });
                  setIsSubmitting(false);
                }
              })
              .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
                setIsSubmitting(false);
              });
        } else {
          toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }    
      };


    
    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
            <div className="main-content" style={{ paddingTop: '2em', paddingBottom:"2em", display: "block" }}>
                <div className="mx-sm-5">
                <div style={{display:"flex", justifyContent:"space-between"}}>
                    <h4>View Questions</h4>
                    <button onClick={handleClose} className={`btn btn-light fw-bold me-2`}>
                            Go Back
                    </button>
                </div>
                
                <div className="my-3">
                            <h6 className="fw-bold text-capitalize" style={{ fontSize: "18px" }}>Question Bank Name: {name}</h6>
                        </div>
                        <div style={{margin:"35px 0px 20px"}}>
                        <div className="formCard">
                            <div className="py-1">
                                <div className="my-2">
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Question</span>
                                    </div>
                                    <label htmlFor="questions" className="questions-label me-sm-2">Question Name: </label>
                                    <textarea id="questions" name="question" type="text" value={inputValues.question} className="question-textarea" onChange={handleChange} placeholder="Enter Question" />
                                    {errorsMessage.question && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.question}</span>}
                                </div>
                            </div> 
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard">
                            <div className="py-1">
                                <div className="my-2">
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Options</span>
                                    </div>
                                    {/* <h6 className="questions-label" style={{textDecoration:"underline", margin:"0px 0px 10px"}}>Options</h6> */}
                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-a" className="questions-label me-sm-2">Option A: </label>
                                            <input id="option-a" name="option_a" type="text" value={inputValues.option_a} className="question-input" onChange={handleChange} placeholder="Enter Option A" />
                                            {errorsMessage.option_a && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.option_a}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-b" className="questions-label me-sm-2">Option B: </label>
                                            <input id="option-b" name="option_b" type="text" value={inputValues.option_b} className="question-input" onChange={handleChange} placeholder="Enter Option B" />
                                            {errorsMessage.option_b && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.option_b}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-c" className="questions-label me-sm-2">Option C: </label>
                                            <input id="option-c" name="option_c" type="text" value={inputValues.option_c} className="question-input" onChange={handleChange} placeholder="Enter Option C" />
                                            {errorsMessage.option_c && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.option_c}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-d" className="questions-label me-sm-2">Option D: </label>
                                            <input id="option-d" name="option_d" type="text" value={inputValues.option_d} className="question-input" onChange={handleChange} placeholder="Enter Option D" />
                                            {errorsMessage.option_d && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.option_d}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard">
                            <div className="pt-1">
                                <div className="mt-2" >
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Answer</span>
                                    </div>
                                    {/* <h6 className="questions-label" style={{textDecoration:"underline", margin:"0px 0px 10px"}}>Answer</h6> */}
                                    <div className="row">
                                        <div className="col-md-3 text-md-center">
                                            <p className="questions-label py-md-2">Select Answer: </p>    
                                        </div>
                                        <div className="col-md-9">
                                            <select id="answer-option" name="correct_option" value={inputValues.correct_option} onChange={handleChange} className="question-textarea py-2">
                                                <option value="">Select Answer</option>
                                                <option value="option_a">Option A</option>
                                                <option value="option_b">Option B</option>
                                                <option value="option_c">Option C</option>
                                                <option value="option_d">Option D</option>
                                            </select>
                                            {errorsMessage.correct_option && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.correct_option}</span>}
                                            
                                        </div>
                                    </div>

                                </div>
                            </div> 
                        </div>
                        </div>
                        <div className="" style={{display:"flex", justifyContent:"end"}}>
                            <button className="btn btn-secondary me-2" onClick={handleClose}>Cancel</button>
                            <button className="btn btn-custom" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Uploading...' : 'Submit'}</button>
                        </div>
                </div>
                
                
            </div>
            : 
            <LoadingSpinner/>}
        </div>
    );
}
