import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';
import axios from "axios";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

const btnOK = {
  backgroundColor: "#464646",
  textTransform: "none",
  cursor: "pointer",
  color: "white",
  fontSize: "12px",
}

export default function UploadExcelStudent(props) {
  const { studentDataArray, CallbackOnChange, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  // console.log("inputValues: ", inputValues)
  const [excelName, setExcelName] = useState('');
  const [studentArray, setStudentArray] = useState([])
  const [isValid, setIsValid] = useState(false);

  const handleRemoveFile = () => {
    setExcelName('');
    setStudentArray([]);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, _isOpen]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
        try {
            setExcelName(file.name);
            // Read the Excel file using XLSX library
            const workbook = XLSX.read(await file.arrayBuffer(), { type: 'array' });

            // Assuming the first sheet is the one you want to convert
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Convert the sheet data to JSON
            const jsonData = XLSX.utils.sheet_to_json(sheet);

            const keyName = ["Name", "Email", "Phone"];
            const validation = jsonData.map((item) => {
                return keyName.every(key => Object.keys(item).includes(key) && item[key] !== undefined);
            });
            const isValid = validation.every(value => value === true);
            setIsValid(isValid);
            
            if(isValid){
                // Check for duplicates
                const duplicateData = jsonData.filter((newData) => {
                    return studentDataArray.some((item) =>
                        item.name === newData.Name &&
                        item.email === newData.Email &&
                        String(item.phone) === String(newData.Phone)
                    );
                });

                if (duplicateData.length > 0) {
                    toast.warn("Duplicate data found!", {
                        position: toast.POSITION.TOP_CENTER,
                    }, { toastId: "duplicate-1" });
                }

                // Merge the new data with the existing data
                const newDataArray = jsonData.map((item) => ({
                    name: item.Name,
                    email: item.Email,
                    phone: String(item.Phone),
                }));
                setStudentArray(newDataArray);
            } else{
                toast.error('Excel sheet is invalid format!', { toastId: "err-excelupload" });
            }

            
        } catch (error) {
            console.error('Error converting Excel to JSON:', error);
        }
    }
};



  const exportToExcelSample = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Candidate data');

    worksheet.addRow(['Name', 'Email', 'Phone']);
    // worksheet.addRow(['Dhinesh', 'dhinesh@gmail.com', '9500240401']);
    // worksheet.addRow(['Ajal', 'ajal@gmail.com', '9999900000']);

    // Create a buffer and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), `Candidate data.xlsx`);
    });
};

const handleSubmit = () => {
    if(isValid){
        if(studentArray.length > 0){
            CallbackOnChange(studentArray)
            setExcelName('');
            setOpen(false);
            if (onClose) onClose();
        } else {
            toast.warning(`Excel sheet is not added Please make sure to include it.`, { toastId: "error" });
        }
    }
    else {
        toast.warning('Excel sheet is invalid format!', { toastId: "err-excelupload-submit" });
    }
    
}

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      maxWidth={'md'}
      fullWidth={true}
    >
      {/* <DialogTitle id="alert-dialog-title" className="text-center fw-bold">Edit Exam Courses</DialogTitle> */}
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center " style={{ marginBottom: '-20px' }}>
        Upload Bulk Students
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
      <DialogContent className="px-5">
        <div className="">
          <div className="">
            {/* <span className="my-2 fw-bold">Upload Excel</span> */}
            <div className="excel-upload-notes-div row">
            <div className="excel-upload-left-content col-sm-4">
              {excelName ?
                <div className="excel-file-div">
                  <span className="my-2" style={{fontSize:"14px"}}>{excelName}</span>
                  <button className="btn btn-none" style={{ border: "none" }} onClick={handleRemoveFile} ><i className="bi bi-trash2-fill text-white"></i></button>
                </div>
                :
                <div className="file-input-container">
                  <label htmlFor="fileInput" className="custom-file-input">
                    <span className="file-icon">&#128190;</span>
                    Choose File
                  </label>
                  <input style={{ display: "none" }} id="fileInput" type="file" accept=".xlsx" onChange={handleFileChange}/>
                </div>
              }
            </div>
            <div className="excel-upload-right-content col-sm-8">
                <div>
                  <span className="fw-bold">Notes:</span>
                  <ul>
                    <li><span className="fw-bold">Format:</span> The column headers in Excel must be named 'Name', 'Email', and 'Phone'. </li>
                    <li><span className="fw-bold">Warning:</span> Do not alter the heading names; they must be in uppercase and maintain the same format for uploading Excel data. Ensure that all data is filled; otherwise, an error will occur during the upload.</li>
                    <li className="mt-1"><span style={{padding:'2px 4px', borderRadius:"4px" , cursor: "pointer", color: 'white', backgroundColor: 'rgba(116, 103, 239, 0.8)'}} onClick={exportToExcelSample}>Download</span> excel sheet for your references!</li>
                  </ul>
                </div>
            </div>
            </div>
          </div>
        </div>

      </DialogContent>
      <hr />
      <DialogActions style={{ marginTop: '-15px' }}>
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" className={`${btnOK} btn fw-bold`}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
