import React from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from "react-router-dom"; 
    

const CountdownTimer = ({ targetDate, onClickIntro, examData }) => {
    const navigate = useNavigate();
  const calculateTimeRemaining = () => {
    const now = new Date();
    const difference = new Date(targetDate) - now;

    // Calculate remaining time in seconds
    const remainingTime = Math.max(Math.floor(difference / 1000), 0);

    return remainingTime;
  };

  const startExam = () => {
    navigate('/mcq-exam', {state: examData})
    localStorage.setItem('yourAppState', JSON.stringify(examData));
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <div className='text-center'>
        <button className='btn btn-custom' onClick={() => (examData ? startExam() : onClickIntro())}>
            Start Exam
        </button>
      </div>
      )
    }

    return (
        <div>
        <p style={{backgroundColor:"white", borderRadius:"18px", padding:"8px 10px", fontSize:"14px", color:"black"}}>
        <span>Exam Start at </span>
        {days > 0 && `${days.toString().padStart(2, '0')}d `}
        {hours > 0 && `${hours.toString().padStart(2, '0')}h `}
        {minutes > 0 ? `${minutes.toString().padStart(2, '0')}m ` : '00m '}
        {seconds > 0 ? `${seconds.toString().padStart(2, '0')}s` : '00s'}
      </p>
      </div>
    );
  };

  return (
    <div className='text-white'>
      <Countdown date={Date.now() + calculateTimeRemaining() * 1000} renderer={renderer}/>
    </div>
  );
};

export default CountdownTimer;
