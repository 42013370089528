import React, { useState, useEffect } from 'react';
import logout from "../../../assets/Logout.png"
import Paper from '@mui/material/Paper';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from 'axios';
import { toast } from 'react-toastify';
import apiName from '../../Utils/BackendApi';


const AdminProfile = (props) => {
    const { isOpen, onClose, closeMenu } = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;

    const [inputData, setInputData] = useState({
        id: "",
        Name: "",
        Email: "",
    });
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        axios.get(`${apiName}api/admin/get-admin/${userId}`)
            .then((res) => {
                if (res.data !== 'No data found') {
                    setInputData((prevState) => ({
                        ...prevState,
                        id: res.data.id,
                        Name: res.data.username,
                        Email: res.data.email,
                    }));
                }
            })
            .catch((e) => {
                toast.error(e.message, { toastId: "admget-err-api" });
            });
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        setOpen(isOpen);
        closeMenu();
    }, [isOpen]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(inputData)) {
            var name;
            if (key === "Name") { name = "Name" };
            if (key === "Email") { name = "Email" };

            if (key !== 'id') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }

                if (key === 'Name' && !/^[a-zA-Z][a-zA-Z\s]{3,25}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Name should contain only letters and no numbers or symbols`;
                }

                if (key === 'Email' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Please enter a valid ${name}`;
                }
            }
        };

        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);

            axios.put(`${apiName}api/admin/update-admin/${userId}`, {
                name: inputData.Name,
                email: inputData.Email,
                role: '1',
            })
                .then((res) => {
                    if (res.data) {
                        setTimeout(() => {
                            toast.success("Profile is updated!", { toastId: "pro-1" });
                            setErrorsMessage({});
                            if (onClose) onClose();
                            setIsSubmitting(false);
                        }, 1000);
                    } else {
                        toast.error("Failed, please try again", { toastId: "pro-2" });
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    toast.error(e.message, { toastId: "pro-err-api" });
                    setIsSubmitting(false);
                });

        } else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, { toastId: "error" });
        }
    };

    return (
        <Dialog
            open={_isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            scroll={'body'}
            style={{ height: "auto", maxHeight: "900px" }}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center " style={{ marginBottom: '-20px' }}>
                Admin Profile
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-sm-5 mt-2 fs-3">
                <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="Name" className='me-2'>Name: </label>
                        <input id="Name" type="text" name='Name' className="question-textarea mx-sm-5" style={{width:"none"}} value={inputData.Name || ""} onChange={handleChange} />
                    </div>
                    {errorsMessage.Name && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.Name}</span>}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="Email" className='me-2'>Email: </label>
                        <input id="Email" type="text" name='Email' className="question-textarea mx-sm-5" style={{width:"none"}} value={inputData.Email || ""} onChange={handleChange} />
                    </div>
                    {errorsMessage.Email && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.Email}</span>}
                </DialogContentText>
            </DialogContent>
            <hr />
            <DialogActions style={{ marginTop: '-15px' }}>
                <Button onClick={handleClose} className={`btn fw-bold text-dark`}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`btn fw-bold`}>
                    {isSubmitting ? 'Updating...' : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdminProfile;
