import React, { useState, useEffect, useRef } from "react";
import '../Login/login.css'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import bg from '../../assets/admin/register.svg'
import LoadingSpinner from '../Utils/LoadingSpinner'
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import apiName from "../Utils/BackendApi";


function AdminRegistration() {
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    const [formData, setFormData] = useState({
        first_name: '',
        email: '',
        password: '',
        Confirm_password: ''
    });

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const errorMsg = useRef({
        nameError:"",
        emailError:"",
        passwordError:"",
        confirmPasswordError:""
    });

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    
        // Validate input when changed
        if (name === 'first_name') {
          validateName(value);
        } else if (name === 'email') {
          validateEmail(value);
        } else if (name === 'password') {
          validatePassword(value);
        } else if (name === 'Confirm_password') {
          validateConfirmPassword(value);
        }
      };
  
      const validateName = (name) => {
        const namePattern = /^[a-zA-Z][a-zA-Z\s]{3,25}$/;
        setNameError(!name || !namePattern.test(name));
        errorMsg.current.nameError = !name
          ? "This field is required"
          : !namePattern.test(name)
          ? "Name should contain only letters and no numbers or symbols"
          : "";
      };

      const validateEmail = (email) => {
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmailError(!email || !emailPattern.test(email));
        errorMsg.current.emailError = !email
          ? "This field is required"
          : !emailPattern.test(email)
          ? "Invalid email address"
          : "";
      };
      
      const validatePassword = (password) => {
        const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w\d!@#$%^&*()-_=+\\|[\]{};:'",<.>/?`~]{8,}$/; // Minimum 6 characters
        setPasswordError(!password || !passwordPattern.test(password));
        errorMsg.current.passwordError = !password
          ? "This field is required"
          : !passwordPattern.test(password)
          ? "password must be eight characters including one uppercase letter, lowercase letter and alphanumeric characters"
          : "";
      };
      
  
    const validateConfirmPassword = (confirmPassword) => {
        setConfirmPasswordError(!confirmPassword || (formData.password && confirmPassword !== formData.password));
        errorMsg.current.confirmPasswordError = !confirmPassword
          ? "This field is required"
          : (formData.password && confirmPassword !== formData.password)
          ? "Password is not match"
          : "";
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate all input fields
        validateName(formData.first_name);
        validateEmail(formData.email);
        validatePassword(formData.password);
        validateConfirmPassword(formData.Confirm_password);


        // If both email and password are valid, you can perform other actions here
        if (!errorMsg.current.nameError && !errorMsg.current.emailError && !errorMsg.current.passwordError && !errorMsg.current.confirmPasswordError) {
            axios.post(`${apiName}api/admin/create-admin`, {
                name: formData.first_name,
                email: formData.email,
                role: '1',
                password: formData.password

            })
                .then(res => {

                    if (res.data !== "Email already exists" && res.data !== "Select a valid role!") {
                        localStorage.setItem("mcq-user", JSON.stringify(res.data));
                        navigate('/admin-dashboard');
                        toast.success('Registration Successful', { toastId: 'errvolreg1' });
                    }
                    else {
                        toast.error(res.data.trim(), { toastId: 'errvolreg2' });
                    }
                })
        }
    };

    const [passtype, setPassType] = useState('password');
    const [passtype2, setPassType2] = useState('password');

    useEffect(() => {
        setLoader(false);
    }, [])


    //password hide & seek toggle
    const handleToggle = () => {
        passtype === 'password' ? setPassType('text') : setPassType('password')
    }
    const handleToggle2 = () => {
        passtype2 === 'password' ? setPassType2('text') : setPassType2('password')
    }


    return (
        <section>
            {loader ? <LoadingSpinner /> :
                <div className="login-page">
                    <div className="login-card">
                        <div className="login-box row">
                            <div className="left-side mobile-view-photo">
                                <div className="left-contant">
                                    <img width={"200px"} height={"250px"} src={bg} alt="login image" />
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="right-contant" >
                                    <div className="py-3" style={{boxSizing: 'unset'}}>
                                        <h5 className="mb-3 text-center">Admin Registration</h5>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group my-3">
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="outlined-name"
                                                    label={formData.email && "Name"}
                                                    placeholder="Name"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInput}
                                                    error={nameError}
                                                    helperText={nameError && errorMsg.current.nameError}
                                                    size="small"
                                                />
                                            </div>
                                            <div className="form-group my-3">
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="outlined-email"
                                                    label={formData.email && "E-mail"}
                                                    placeholder="E-mail"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInput}
                                                    error={emailError}
                                                    helperText={emailError && errorMsg.current.emailError}
                                                    size="small"
                                                />
                                            </div>
                                            <div className="form-group my-3">
                                                <TextField
                                                    sx={{ width: '100%',  position: "relative" }}
                                                    id="outlined-password"
                                                    label={formData.password && "Password"}
                                                    placeholder="Password"
                                                    name="password"
                                                    type={passtype}
                                                    value={formData.password}
                                                    onChange={handleInput}
                                                    error={passwordError}
                                                    helperText={passwordError && errorMsg.current.passwordError}
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <i className={passtype === 'password' ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                                                                id="input-password-eye"
                                                                onClick={handleToggle}
                                                            ></i>
                                                        ),
                                                      }}
                                                >
                                                </TextField>
                                            </div>

                                            <div className="form-group my-3" style={{ position: "relative" }}>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="outlined-confirm-password"
                                                    label={formData.Confirm_password && "Password"}
                                                    placeholder="Confirm Password"
                                                    name="Confirm_password"
                                                    type={passtype2}
                                                    value={formData.Confirm_password}
                                                    onChange={handleInput}
                                                    error={confirmPasswordError}
                                                    helperText={confirmPasswordError && errorMsg.current.confirmPasswordError}
                                                    size="small"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <i className={passtype2 === 'password' ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                                                                id="input-password-eye"
                                                                onClick={handleToggle2}
                                                            ></i>
                                                        ),
                                                      }}
                                                />
                                            </div>

                                            <div className="form-group sgn-from-group">
                                                <div className="sgn-content-between" style={{justifyContent: "center"}}>
                                                    <button type="submit"
                                                        className="btn sign-btn"
                                                    >Register</button>
                                                </div>

                                            </div>
                                            <div className="text-center">
                                                <Link className="text-decoration-none" to="/admin-login">
                                                    <span className="text-black me-2 text-muted" >Already have an account?
                                                    </span> <b style={{color:"rgb(116, 103, 239)"}}>Sign In</b>
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );

} export default AdminRegistration;