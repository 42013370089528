import Sidebar from '../Sidebar/Sidebar';
import LeftSideBar from '../Sidebar/Left_SideBar';
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import LoadingSpinner from '../../Utils/LoadingSpinner';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Switch from '@mui/material/Switch';
import { useRef } from 'react';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import apiName from '../../Utils/BackendApi';

export default function StudentList () {
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [data, setData] = useState([]);
    const [openStudent, setOpenStudent] = useState(false);
        const [studentInput, setStudentInput] = useState({
            id: "",
            studentName: "",
            studentEmail: "",
            studentPhone: ""
        })
        const [studentErrorsMessage, setStudentErrorsMessage] = useState({});
        const [studentIsSubmitting, setStudentIsSubmitting] = useState(false);
    

        const [searchInput, setSearchInput] = useState({
            name:"",
            email:"",
            phone:""
        })
        const [filter, setFilter] = useState(false);

    useEffect(()=>{
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
          }
    },[])
    
    useEffect(()=>{
        axios(`${apiName}api/user/get-all-student/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setLoader(true);
            }
            else{
                setLoader(true);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api" });
          });
    },[userId, !openStudent])

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearchInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const filteredData = data && data.filter(item => {
        return(String(item.username).toLowerCase().includes(searchInput.name.toLowerCase()) && 
        String(item.email).toLowerCase().includes(searchInput.email.toLowerCase()) && 
        String(item.phone).toLowerCase().includes(searchInput.phone.toLowerCase()));
    });

    const handleRowClick = (item) => {
        setOpenStudent(true);
        setStudentInput((prevState) => ({
            ...prevState,
            id: item.id,
            studentName: item.username,
            studentEmail: item.email,
            studentPhone: item.phone
        }));
    }

    const handleStudentChange = (event) => {
        const { name, value } = event.target;
        setStudentInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setStudentErrorsMessage((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const handleStudentSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(studentInput)) {
            var name;
            if (key === "studentName") { name = "Name" };
            if (key === "studentEmail") { name = "Email" };
            if (key === "studentPhone") { name = "Phone" };

            if(key !== 'id'){
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
            }
            
        }

        setStudentErrorsMessage(errorMessage);

        if (Object.keys(errors).length === 0) {
                    axios.put(`${apiName}api/user/update-student/${studentInput.id}`, {
                        name: studentInput.studentName,
                        email: studentInput.studentEmail,
                        phone: String(studentInput.studentPhone),
                    })
                    .then((res) => {
                        if (res.data) {
                            toast.success(res.data, { toastId: "addTax-1" });
                            setOpenStudent(false);
                            setStudentInput({id: "", studentName: "", studentEmail: "", studentPhone: "" });
                            setStudentIsSubmitting(false);
                        } else {
                        toast.error("Failed, please try again", { toastId: "addTax-1" });
                        setStudentIsSubmitting(false);
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                        setStudentIsSubmitting(false);
                    });
        } else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]}. Please make sure to include it.`, { toastId: "error" });
        }
    };

    const handleCloseStudent = () => {
        setOpenStudent(false);
        setStudentInput({id:"", studentName: "", studentEmail: "", studentPhone: "", });
    };

    const customStyles = {
        headRow: {
            style: {
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            },
        },
        rows: {
            style: {
                backgroundColor: "#fff",
                minHeight: '40px',
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Admission No',
            selector: row => row.admission,
            // center: 'true',
        },
        {
            name: 'Name',
            selector: row => row.username,
            grow: 2,
            // center: 'true',
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2,
            // center: 'true',
        },
        {
            name: 'Phone',
            selector: row => row.phone,
            grow: 1,
            // center: 'true',
        },
        {
            name: 'Edit',
            selector: row => row.Edit,
            width:"100px",
            center: 'true',
        },
    ];

    const rows = filteredData ? filteredData.map((item, index) => {
        return {
            no: index + 1,
            id: item.id,
            admission: item.admission_number,
            username: item.username,
            email: item.email,
            phone: item.phone,
            userpassword: item.user_password,
            Edit:( <div>
            <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleRowClick(item)} sx={{ color: 'rgb(116, 103, 239)' }}> <Tooltip title="Edit" arrow><ModeEditIcon /></Tooltip></IconButton>
            </div>)
        }
    }) : '';

    const exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Student list');

        // Function to add bold formatting to a cell
        const addBoldFormatting = (cell) => {
            cell.font = { bold: true };
        };

        const centerText = (cell) => {
            cell.alignment = { horizontal: 'middle', vertical: 'middle'  };
        }


        const studentDetailsRow = worksheet.addRow(['Student Details']);
        studentDetailsRow.eachCell({ includeEmpty: true }, addBoldFormatting);

        const headerRow = worksheet.addRow([
            'No',
            'Admission_number',
            'Name',
            'Email',
            'Phone',
            'Password'
        ]);

        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            addBoldFormatting(cell);
            centerText(cell);
        });

        data.forEach((item, index) => {
            const row = worksheet.addRow([
                index + 1,
                item.admission_number,
                item.username,
                item.email,
                Number(item.phone),
                item.user_password
            ]);
        });


        // Create a buffer and save it as a file
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer]), `Student list.xlsx`);
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

    return(
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
            <div className="main-content">
            <div className="mx-sm-5 my-2">
                    <div className="row">
                        <div className="col-sm-9 text-start fw-bold fs-3 text-capitalize">
                            Students List
                        </div>
                        <div className="col-sm-3 text-end">
                                <button onClick={exportToExcel} className="my-2 btn btn-success" style={{ cursor: "pointer" }}>Download list</button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <>
                        <span className='search-label'>Filter 
                        <Switch 
                                onClick={() => {
                                    setFilter((preOpen) => !preOpen)
                                    if(filter === true){
                                        setSearchInput({name:"", email:"",phone:""});
                                    }
                                }} 
                                checked={filter} 
                                size="small" 
                                color="secondary" 
                            />
                        </span>
                        <form className='my-2' style={{display: filter ? "block":"none"}}>
                        <div className="form-row row search-box">
                            <div className="form-group col-md-4">
                            <label htmlFor="name" className='search-label'>Name:</label>
                            <input type="text" name='name' className="form-control search-input" id="name" onKeyDown={handleKeyDown} value={searchInput.name} onChange={handleChange} placeholder="Name"/>
                            </div>
                            <div className="form-group col-md-4">
                            <label htmlFor="email" className='search-label'>Email:</label>
                            <input type="email" name='email' className="form-control search-input" id="email" onKeyDown={handleKeyDown} value={searchInput.email} onChange={handleChange} placeholder="Email"/>
                            </div>
                            <div className="form-group col-md-4">
                            <label htmlFor="phone" className='search-label'>Phone:</label>
                            <input type="number" name='phone' className="form-control search-input" id="phone" onKeyDown={handleKeyDown} value={searchInput.phone} onChange={handleChange} placeholder="Phone"/>
                            </div>
                            <div className='text-end my-2'>
                                <span className='search-label' style={{cursor:"pointer"}} onClick={()=>{setSearchInput({name:"", email:"",phone:""})}}>Clear filter</span>
                            </div>
                        </div>
                        </form>
                        </>
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                        />
                    </div>
                </div>
                </div>
            :
            <LoadingSpinner/>}
             <Dialog
                open={openStudent}
                onClose={handleCloseStudent}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                scroll={'body'}
                style={{ height: "auto", maxHeight: "900px" }}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center " style={{ marginBottom: '-20px' }}>
                    Add Student
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleCloseStudent}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 mt-2 fs-3">
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-name" style={{width:"80px"}}>Name: </label>
                        <input id="student-name" type="text" name='studentName' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentName || ""} onChange={handleStudentChange} />
                    </div>    
                        {studentErrorsMessage.studentName && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentName}</span>}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-email" style={{width:"80px"}}>Email: </label>
                        <input id="student-email" type="text" name='studentEmail' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentEmail || ""} onChange={handleStudentChange} />
                    </div>    
                        {studentErrorsMessage.studentEmail && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentEmail}</span>}
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" className="fs-6 fw-bold my-2">
                    <div className='d-flex'>
                        <label htmlFor="student-phone" style={{width:"80px"}}>Phone: </label>
                        <input id="student-phone" type="text" name='studentPhone' className="question-textarea mx-sm-5" style={{width:"none"}}  value={studentInput.studentPhone || ""} onChange={handleStudentChange} />
                    </div>
                        {studentErrorsMessage.studentPhone && <span style={{ color: "red", fontSize: "12px" }}>{studentErrorsMessage.studentPhone}</span>}
                    </DialogContentText>
                </DialogContent>
                <hr />
                <DialogActions style={{ marginTop: '-15px' }}>
                    <Button onClick={handleCloseStudent} className={`btn fw-bold text-dark`}>
                        Cancel
                    </Button>
                    <Button onClick={handleStudentSubmit} disabled={studentIsSubmitting} color="primary" className={`btn fw-bold`}>
                        {studentIsSubmitting ? 'Uploading...' : 'Ok'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}