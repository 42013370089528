import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const btnOK = {
    backgroundColor: "#464646",
    textTransform: "none",
    cursor: "pointer",
    color: "white",
    fontSize: "12px",
}

function TableLoadingSpinner() {
    return (
      <div className="custom-loader">
      <Box sx={{ 
      background: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAgCAMAAAAynjhNAAAAq1BMVEVHcExVQPtHYvxGXvtIW/tDZfstjvxAc/tAaftFYftPUfsvhvs4d/tAbvtVRftSSvs4ePs6dvtHYfw/avtTSPtWQ/svhvsjnfwolPtWQ/tURfxVRfsPv/whn/sgovsgoPtNV/shoftWQvtWQvsXs/tVRfsbq/sWs/scqPsVtvwRvfsKyfsMxftTSPsIy/sJy/sGzfsJy/sKyfsJy/sJy/sJx/sUuPsLx/wSuvsxdtzNAAAAOXRSTlMAUSLJ/1sInP/1L17/hmag2rgNPuf//nn/0EK5PvG2mHXd9PvMjsP//w//yv+B/9190byi72HuLN0MjjHTAAABh0lEQVR4AZXQhZKDMBSF4RMaUjRsKRevu3u77/9kG+o+s/8MYx+xi3/FvqFW4lz/hKJsmHqJl6y3ajuOCymYyb1XtH6cil8NiARczuUjikoYVkXFUAtlAFHiwf0JURxHiELnR9OCxE8zVE2eXTCvxXUfjXrDRrWSgmUpgCbnzRO3ajmAoN3uUOf8aT5TJ/go6tahatNdTepBv3AfQqjVlzpE0k97unHmwVA1wiVJlCaBduPB4Il7Esy+8Fi54jS1ACu1ekSaJGk7J56MCx+BKLvc0EuEf+XpeDy+cEBFlki8Gxc+QpbNAC/LEqIZSdjhmedz9cPD1XRI746Vi3tmavRXXixVvWuZ2jz1pR2feLV+Gao1I+/KK6g69+zxxI/i03U2820OMCbLLmuWm6zZ88oM9WELp/LtfD3Cbr7Ecr5DIw5dRMNhhGvLuTKRzzfYDYZ1ke8HdYG7RHGCdjgspvtcrMe/Bzx1WKgT8m1xwHSHN+2KE9Q9NuJZrifM56sDPnaY7PCf/gBYaTKpROM7TwAAAABJRU5ErkJggg==')`,
        backgroundSize: '30px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
        width: '100%'
     }}>
      <CircularProgress sx={{ width: '50px !important', height: '50px !important' }}/>
    </Box>
    </div>
    );
  }

export default function ViewSelectedQuestionsPage(props) {
    const { selectedData, subjectName, isDisabled, review } = props;
    const [SelectedData, setSelectedData] = useState([]); // Initialize with initially selected rows
    const [filteredData, setFilteredData] = useState([]);
    const [pending, setPending] = useState(false);

    useEffect(() => {
        setPending(true);
        const timeoutId = setTimeout(() => {
            setSelectedData(selectedData);
            setFilteredData(selectedData && selectedData.filter(item => {
                return (item.subject === subjectName);
            }));
            setPending(false);
          }, 1000);
      
          // Cleanup the timeout to avoid memory leaks
          return () => clearTimeout(timeoutId);
    }, [selectedData, subjectName]);

    const handleRemove = (item) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.filter((row) => Number(row.id) !== Number(item.id))
        );
        const updatedFilteredData = filteredData.filter((row) => Number(row.id) !== Number(item.id));
        setFilteredData(updatedFilteredData);
    }

    const handleClose = () => {
        if (props.onExpandable) {
            props.onExpandable(false);
        }
    }

    const handleOkClick = () => {
        if (props.onSelectRows) {
            props.onSelectRows(SelectedData);
        }
        handleClose();
    };

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "14px",
                minHeight: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            center: 'true',
            width: '100px'
        },
        {
            name: 'Questions',
            selector: row => row.question,
            grow: 2,
            // center: true,
        },
        {
            name: 'Subject',
            selector: row => row.subject,
            grow: 1,
            center: 'true',
        },
        {
            name: 'Answer',
            selector: row => row.answer,
            grow: 1,
            center: 'true',
        },
        review === false && {
            name: '',
            selector: row => row.select,
            center: 'true',
        },
    ];

    const rows = filteredData
        ? filteredData.map((item, index) => {
            return {
                id: item.id,
                no: index + 1,
                subject: item.subject,
                question: item.question,
                options: [item.option_a, item.option_b, item.option_c, item.option_d],
                answer: <span style={{ color: "green", fontWeight: "bold" }}>
                    {
                        item.correct_option === 'option_a' ? item.option_a :
                            item.correct_option === 'option_b' ? item.option_b :
                                item.correct_option === 'option_c' ? item.option_c :
                                    item.correct_option === 'option_d' ? item.option_d : ''
                    }</span>,
                dropanswer:
                    (
                        item.correct_option === 'option_a' ? 0 :
                            item.correct_option === 'option_b' ? 1 :
                                item.correct_option === 'option_c' ? 2 :
                                    item.correct_option === 'option_d' ? 3 : ''
                    ),
                select: <button className="btn btn-none" style={{ border: "none" }} onClick={() => handleRemove(item)} disabled={isDisabled}><i className="bi bi-trash2-fill"></i></button>,
            };
        })
        : '';



    const ExpandedComponent = ({ data }) => {
        return (
            <div style={{textAlign:"start" ,padding: "18px 0px 4px" }}>
                <p><span className="fw-bold">Question: </span>{data.question}</p>
                <p>
                    <span className="fw-bold">Options: </span>
                    {data.options.map((item, index) => (
                        <span key={index} style={{ color: index === data.dropanswer ? 'green' : 'black', fontWeight: index === data.dropanswer && "bold" }}>{`${String.fromCharCode(65 + index)}) ${item}`} {item.length > 25 && <br />}</span>
                    ))}
                </p>
            </div>
        );
    };

    const [expandedRows, setExpandedRows] = useState([]);
    return (
        <div>
            <div className="my-2">
                <div className="">
                    <div className="my-2 text-start">Select questions from <span className="text-capitalize fw-bold">{subjectName}</span> question bank</div>
                    <DataTable
                        columns={columns}
                        data={rows}
                        fixedHeaderScrollHeight="600px"
                        customStyles={customStyles}
                        persistTableHead
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 25, 50]}
                        highlightOnHover
                        expandableRows
                        expandableRowsComponent={ExpandedComponent}
                        onRowExpandToggled={(toggleState, row) => {
                            setExpandedRows(toggleState ? [row.id] : []);
                          }}
                        expandableRowExpanded={(row) => expandedRows.includes(row.id)}
                        progressPending={pending}
			            progressComponent={<TableLoadingSpinner/>}
                    />
                    <div className="text-end" >
                        <button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark me-2`}>{isDisabled ? "Close" : "Cancel"}</button>
                        <button onClick={handleOkClick} className={`btn btn-custom`} style={{display: isDisabled ? "none" : ""}}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
}