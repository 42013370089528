import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
// import logo from '../../assets/Exam logo.png';
import './Error_Page.css';

const LoadingSpinner = () => {
  return (
    <Box
      className="logo-loading-spinner" 
    >
      {/* Use img tag to verify the image */}
      <CircularProgress sx={{ width: '50px !important', height: '50px !important' }} />
    </Box>
  );
};

export default LoadingSpinner;
