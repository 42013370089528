import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';
import excelImg from '../../../../../assets/Excel bulk question format.png'
import axios from "axios";
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';
import apiName from "../../../../Utils/BackendApi";

const btnOK = {
  backgroundColor: "#464646",
  textTransform: "none",
  cursor: "pointer",
  color: "white",
  fontSize: "12px",
}

export default function UploadExcel(props) {
  const { questionBankId, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const user = localStorage.getItem("mcq-user");
  const userId = user && JSON.parse(user).id;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [excelName, setExamName] = useState('');
  const [excelDate, setExcelDate] = useState([]);
  const [excelFile, setExcelFile] = useState();

  // console.log("inputValues: ", inputValues)

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setExamName('');
    setExcelDate(null);
    setExcelFile();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen, _isOpen]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        setExcelFile(file);
        setExamName(file.name);
        // Read the Excel file using XLSX library
        const workbook = XLSX.read(await file.arrayBuffer(), { type: 'array' });

        // Assuming the first sheet is the one you want to convert
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet);

        // Check for duplicates
        const allValues = [];
        const duplicateData = [];
        jsonData.forEach((row) => {
          Object.values(row).forEach((value) => {
            if (allValues === value) {
              duplicateData.push(value);
            } else {
              allValues.push(value);
            }
          });
        });


        if (duplicateData.length > 0) {
          toast.warn("Duplicate data found!", {
            position: toast.POSITION.TOP_CENTER,
          }, { toastId: "duplicate-1" });
        }
        else {
          // Merge the new data with the existing data
          const newDataArray = jsonData.map((item) => {
            if(item['Questions'] !== undefined){
              return(
                {
                question: item['Questions'],
                option_a: item['Option A'],
                option_b: item['Option B'],
                option_c: item['Option C'],
                option_d: item['Option D'],
                correct_option:
                  item['Correct Option'] === 'Option A'
                    ? 'option_a'
                    : item['Correct Option'] === 'Option B'
                    ? 'option_b'
                    : item['Correct Option'] === 'Option C'
                    ? 'option_c'
                    : item['Correct Option'] === 'Option D'
                    ? 'option_d'
                    : ''
                }
              )
            }
          });

          const newDataArrayValidation = newDataArray.filter((item) => item !== undefined)

          if(newDataArrayValidation !== undefined){
            setExcelDate(newDataArrayValidation);
          }
          else{
            toast.error('Excel sheet is invalid format!', { toastId: "err-excelupload" });
          }
          
          // console.log("newDataArray: ", newDataArrayValidation)
          
        }
      } catch (error) {
        console.error('Error converting Excel to JSON:', error);
        toast.error(error, { toastId: "err-excelupload" });
      }
    }
  };

  const handleRemoveFile = () => {
    setExamName('');
    setExcelDate(null);
    setExcelFile();
  };

  //${apiName}api/question/create-bulk-questions/37(admin id) /27 (question bank id)

  const handleSubmit = () => {
    if (excelDate && excelDate.length > 0) {
      setIsSubmitting(true);
      axios.post(`${apiName}api/question/create-bulk-questions/${userId}/${questionBankId}`, excelDate)
        .then((res) => {
          if (res.data) {
            setTimeout(() => {
              toast.success("Question has been uploaded", { toastId: "addQues-1" });
              setOpen(false);
              if (onClose) onClose();
              setExamName('');
              setExcelDate(null);
              setExcelFile();
              setIsSubmitting(false);
            }, 1000);
          } else {
            toast.error("Failed, please try again", { toastId: "addQues-1" });
            setIsSubmitting(false);
          }
        })
        .catch((e) => {
          toast.error(e.message, { toastId: "err-api" });
          setIsSubmitting(false);
        });
    }
    else {
      toast.error("Please upload proper excel sheet", { toastId: "err-excelmsg" });
    }
  }

  const exportToExcelSample = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Question bank data');

    worksheet.addRow(['No', 'Questions', 'Option A', 'Option B', 'Option C', 'Option D', 'Correct Option']);
    worksheet.addRow([1, 'Question Name', 'option-1', 'option-2', 'option-3', 'option-4', 'Option A']);

    // Add comment/note to the cell in the data row
    worksheet.getCell('K8').value = 'Notes:';
    worksheet.getCell('K9').value = 'Enter Questions:';
    worksheet.getCell('K10').value = 'Assign Options:';
    worksheet.getCell('K11').value = 'Provide Answers:';
    worksheet.getCell('K12').value = 'Warning:';

    worksheet.getCell('L8').value = '';
    worksheet.getCell('L9').value = 'In column B, list your questions. Each row should represent a different question.';
    worksheet.getCell('L10').value = 'In columns C to F, label them as "Option A," "Option B," "Option C," and "Option D" values respectively.';
    worksheet.getCell('L11').value = 'For each question, input the correct answer option in the corresponding column. For example, if the correct answer to question 1 is Option B, enter "Option B" in answer cell.';
    worksheet.getCell('L12').value = "Don't change the heading names; they need to be in the same format for uploading Excel data. Ensure that you follow the case-sensitive approach when entering answer options exactly as provided (e.g., Option A, Option B).";


    // Estimate and set column widths
    const calculateColumnWidth = (content) => {
      const conversionFactor = 1.2;
      return content.length * conversionFactor;
    };

    worksheet.getColumn('A').width = calculateColumnWidth('No ');
    worksheet.getColumn('B').width = calculateColumnWidth('Question name: ');
    worksheet.getColumn('C').width = calculateColumnWidth('Option A');
    worksheet.getColumn('D').width = calculateColumnWidth('Option B');
    worksheet.getColumn('E').width = calculateColumnWidth('Option C');
    worksheet.getColumn('F').width = calculateColumnWidth('Option D');
    worksheet.getColumn('G').width = calculateColumnWidth('Correct Option');

    worksheet.getColumn('K').width = calculateColumnWidth('Provide Answers:');
    worksheet.getColumn('L').width = calculateColumnWidth("Don't change the heading names; they need to be in the same format for uploading Excel data. Ensure that you follow the case-sensitive approach when entering answer options exactly as provided (e.g., Option A, Option B).");

    // Create a buffer and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), `Question bank data.xlsx`);
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      maxWidth={'md'}
      fullWidth={true}
    >
      {/* <DialogTitle id="alert-dialog-title" className="text-center fw-bold">Edit Exam Courses</DialogTitle> */}
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center " style={{ marginBottom: '-20px' }}>
        Upload Bulk Questions
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
      <DialogContent className="px-5">
        <div className="">
          <div className="">
            {/* <span className="my-2 fw-bold">Upload Excel</span> */}
            <div className="excel-upload-notes-div row">
            <div className="excel-upload-left-content col-sm-4">
              {excelName ?
                <div className="excel-file-div">
                  <span className="my-2" style={{fontSize:"14px"}}>{excelName}</span>
                  <button className="btn btn-none" style={{ border: "none" }} onClick={handleRemoveFile} ><i className="bi bi-trash2-fill text-white"></i></button>
                </div>
                :
                <div className="file-input-container">
                  <label htmlFor="fileInput" className="custom-file-input">
                    <span className="file-icon">&#128190;</span>
                    Choose File
                  </label>
                  <input style={{ display: "none" }} id="fileInput" type="file" accept=".xlsx" onChange={handleFileChange} value={excelName && null} />
                </div>
              }
            </div>
            <div className="excel-upload-right-content col-sm-8">
                <div>
                  <span className="fw-bold">Notes:</span>
                  <ul>
                    <li><span className="fw-bold">Enter Questions:</span> In column B, list your questions. Each row should represent a different question.</li>
                    <li><span className="fw-bold">Assign Options:</span> In columns C to F, label them as "Option A," "Option B," "Option C," and "Option D" values respectively.</li>
                    <li><span className="fw-bold">Provide Answers:</span> For each question, input the correct answer option in the corresponding column. For example, if the correct answer to question 1 is Option B, enter "Option B" in answer cell. </li>
                    <li><span className="fw-bold">Warning:</span> Don't change the heading names; they need to be in the same format for uploading Excel data. Ensure that you follow the case-sensitive approach when entering answer options exactly as provided (e.g., Option A, Option B).</li>
                    <li className="mt-1"><span style={{padding:'2px 4px', borderRadius:"4px" , cursor: "pointer", color: 'white', backgroundColor: 'rgba(116, 103, 239, 0.8)'}} onClick={exportToExcelSample}>Download</span> excel sheet for your references!</li>
                  </ul>
                </div>
            </div>
            </div>
          </div>
        </div>

      </DialogContent>
      <hr />
      <DialogActions style={{ marginTop: '-15px' }}>
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`${btnOK} btn fw-bold`}>
          {isSubmitting ? 'Uploading...' : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
