import { useState, useEffect, useRef, Component } from "react";
// import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import timeUp from '../../../assets/exam/timeUp.png'
import './ExamPage.css'
import NoDataModal from "../../Exam_Protal/NodataModal";
import trophy from "../../../assets/exam/trophy.png";
import Confirmation from "../../../assets/exam/Confirmation status.jpg"
import axios from "axios";
import { toast } from 'react-toastify';
import apiName from "../../Utils/BackendApi";
import LoadingSpinner from "../../Utils/LoadingSpinner";

export default function ExamPage() {
    // const numQues = 10;
    const user = localStorage.getItem("mcq-candidates");
    const userId = user && JSON.parse(user).id;

    const navigate = useNavigate();
    const location = useLocation();
    const stateData = JSON.parse(localStorage.getItem('yourAppState'));

    const attempted = JSON.parse(sessionStorage.getItem('attempts'));
    const attnum = attempted === 0 ? 0 : attempted ? attempted : 2;

    const [loader, setLoader] = useState(true);

    // =========== Index and questions ===========
    const exam = `${stateData.exam_name} ${stateData.exam_name.split(' ')[stateData.exam_name.split(' ').length - 1].toLowerCase() !== "exam" && "exam"}`;
    const [queslength, setQueslength] = useState(0);
    const [questions, getQuestions] = useState([
        {
            question: "",
            options: [""],
        }
    ]);
    const [questionIndex, setQuestionIndex] = useState(0);

    const [selectedOptions, setSelectedOptions] = useState([]);

    // =========== countdown ===========
    const dataFetchedRef = useRef(false);
    var time = useRef();
    var startingTotal = useRef();
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    // const [startingTotal, getStartingTotal] = useState(0);
    const [timelineWidth, setTimelineWidth] = useState(0);
    const [timesup, setTimesUp] = useState(false);
    const [endexam, setEndexam] = useState(false);
    const [confirmationExam, setConfirmationExam] = useState(false);

    const [remainingAttempts, setRemainingAttempts] = useState(attnum);
    const [showModal, setShowModal] = useState(false);

    const [isOnline, setIsOnline] = useState(!navigator.onLine);


    useEffect(() => {
        const handleOnline = () => {
        setIsOnline(false);
        };

        const handleOffline = () => {
        setIsOnline(true);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
        window.removeEventListener('online', handleOnline);
        window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const handleCloseNetwork = () => {
        navigate('/');
        localStorage.removeItem("mcq-candidates");
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
        if (document.hidden) {
            if (remainingAttempts >= 0) {
            setShowModal(true);
            sessionStorage.setItem('attempts', JSON.stringify(remainingAttempts));
            } else {
                handleSubmit();
                navigate('/');
            }
        }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [remainingAttempts, navigate]);

    useEffect(() => {
        const handleDisableNavigation = (event) => {
          event.preventDefault();
        };
    
        // Disable back and refresh
        window.history.pushState(null, null, window.location.pathname);
        // window.addEventListener('popstate', handleDisableNavigation);
        window.addEventListener('beforeunload', handleDisableNavigation);
    
        return () => {
          // Cleanup: remove event listeners
        //   window.removeEventListener('popstate', handleDisableNavigation);
          window.removeEventListener('beforeunload', handleDisableNavigation);
        };
      }, []);
        
    const handleCloseModal = () => {
        setShowModal(false);
        setRemainingAttempts((prevAttempts) => prevAttempts - 1);
    };

    useEffect(() => {
        window.history.pushState(null, '', window.location.href);

        window.addEventListener('popstate', () => {window.history.pushState(null, '', window.location.href)});
    
        return () => {
          window.removeEventListener('popstate', () => {window.history.pushState(null, '', window.location.href)});
        };
      }, []);

    useEffect(() => {
        const extractedQuestions = stateData.question_banks.flatMap(
            (questionBank) => questionBank.questions
          );
          //shuffle function
          const shuffleArray = (array) => {
            for (let i = array.length - 1; i > 0; i--) {
              const j = Math.floor(Math.random() * (i + 1));
              [array[i], array[j]] = [array[j], array[i]];
            }
            return array;
          };
          const shuffledQuestions = shuffleArray([...extractedQuestions]);
          getQuestions(shuffledQuestions);

        const startTimeArray = stateData.start_time.split(":");
        const endTimeArray = stateData.end_time.split(":");

        // const startHours = parseInt(startTimeArray[0]);
        // const startMinutes = parseInt(startTimeArray[1]);

        // start time
        const now = new Date();
        const startHours = now.getHours();
        const startMinutes = now.getMinutes();
        
        // end time
        const endHours = parseInt(endTimeArray[0]);
        const endMinutes = parseInt(endTimeArray[1]);

        const startTotalMinutes = startHours * 60 + startMinutes;
        const endTotalMinutes = endHours * 60 + endMinutes;

        const timeDifference = endTotalMinutes - startTotalMinutes;

        // console.log("timeDifference: ", timeDifference)

        startingTotal.current = (timeDifference * 60);
        setQueslength(extractedQuestions.length);
        time.current = (timeDifference * 60);

        

        // timer
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        
        setLoader(false);
    }, []);

    useEffect(() => {
        const particularPages = ['/mcq-exam'];
        const isOnParticularPage = particularPages.indexOf(location.pathname) !== -1;
        // console.log("isOnParticularPage: ", isOnParticularPage)
        if (isOnParticularPage) {
            const intervalId = setInterval(updateCountdown, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [location.pathname]);



    const handleOptionChange = (ev, index) => {
        const { value, name } = ev.target;
        // Clone the current selectedOptions array
        const newSelectedOptions = [...selectedOptions];
        if (value !== undefined) {
            newSelectedOptions[index] = {
                "question_id": name,
                "selected_option": value
            };
        } else {
            delete newSelectedOptions[index];
        }

        setSelectedOptions(newSelectedOptions.filter(Boolean));
    };

    const handleClear = (item, index) => {
        ['option_a', 'option_b', 'option_c', 'option_d'].forEach(option => {
            document.getElementById(`${item.id}-${index}-${option}`).checked = false;
        });
        const newData = selectedOptions.filter(data => data.question_id !== String(item.id));
        setSelectedOptions(newData);
    };
    

    function onClickSubmit() {
        navigate('/')
    };

    function handleSubmit() {
        // console.log("selectedOptions: ------------- ", selectedOptions);
        //${apiName}api/result/add-result/14/8
        axios.post(`${apiName}api/result/add-result/${userId}/${stateData.id}`, [{ result: selectedOptions }])
            .then((res) => {
                if (res.data !== "No data Found") {
                    toast.success('Your data Successful uploaded!', { toastId: 'exampage1' });
                    sessionStorage.removeItem("attempts");
                } else {
                    toast.error(res.data.trim(), { toastId: 'exampage2' });
                }
            });
        // setEndexam(true);
    }

    function SubmitFunc() {
        if (time.current > 0) {
            setConfirmationExam(true);
          }
    }


    function updateCountdown() {
        if (time.current >= 0) {
            const hours = Math.floor(time.current / 3600);
            const minutes = Math.floor((time.current % 3600) / 60);
            const seconds = Math.floor(time.current % 60);

            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);

            const percentageRemaining = (time.current / startingTotal.current) * 100;
            // console.log('percentageRemaining: ', time.current , '/', startingTotal.current, '=' , 100 - percentageRemaining)
            setTimelineWidth(100 - percentageRemaining);

            time.current = time.current - 1;
        }
    }

    useEffect(() => {
        if (timelineWidth === 100 && endexam === false) {
            handleSubmit();
            setTimesUp(true);
            setConfirmationExam(false);
        }
    }, [timelineWidth, endexam]);

    const handleCopy = (e) => {
        e.preventDefault();
        alert('Copying is not allowed on this page.');
      };
    
      const handlePaste = (e) => {
        e.preventDefault();
        alert('Pasting is not allowed on this page.');
      };

      const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <section>
            {loader ? <LoadingSpinner /> :
            <>
                {questions.length < 1 ? <NoDataModal /> :

                    <div>
                        {/* <WarningPage/> */}
                        <div className="exampage-main-div" onCopy={handleCopy} onPaste={handlePaste} onContextMenu={handleContextMenu}>
                        <div className="exam-start-title">
                            <h3 className="text-capitalize">{exam}</h3>
                            <div className="mt-4 timeline-div">
                                <div className="timeline ">
                                    <div
                                        className="timeline-bar"
                                        style={{ width: `${timelineWidth}%` }}
                                    ></div>
                                </div>
                                <p className={`time-display ${minutes < 5 && minutes >= 0 ? 'text-danger' : ''}`} style={{marginTop:"11.5px"}}>
                                    <span className="exam-timer">
                                        Time Left: {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                                    </span> 
                                </p>
                            </div>
                        </div>

                        <div className="exam-container">
                            <div className="question-box">
                                <div className="qstn-container p-sm-4 p-2">
                                    {questions.map((item, index) => {
                                        return (
                                            <div className={`${index === questionIndex ? 'displaydiv' : 'hiddendiv'}`} key={index}>
                                                <div className={windowWidth > 500 ? "question-number" : "d-none"}>
                                                    Q-{questionIndex + 1}
                                                </div>
                                                {windowWidth < 500 && 
                                                <div className="d-flex justify-content-between">
                                                <div className={"phone-question-number"}>
                                                    Q-{questionIndex + 1}
                                                </div>
                                                <div className={"phone-question-buttom-number"}>
                                                    {questionIndex + 1} to {questions.length} Q
                                                </div>
                                                </div>
                                                }
                                                <h6 className="qstn-text-heading my-2" style={{ fontSize: "18px" }}>{item.question}</h6>
                                                <div>
                                                    {['option_a', 'option_b', 'option_c', 'option_d'].map((option, optionIndex) => (
                                                        <label className="form-check-box" key={optionIndex}>
                                                            <span className="form-check-label" style={{ overflowWrap: "break-word", width:"100%" , marginRight:"8px", fontSize: "16px", color: "darkslategrey" }}>{item[option]}</span>
                                                            <input
                                                                id={`${item.id}-${index}-${option}`}
                                                                type="radio"
                                                                className="form-check-input"
                                                                value={option}
                                                                name={item.id}
                                                                onChange={(ev) => handleOptionChange(ev, index)}
                                                            />
                                                            <div className="outer-square">
                                                                <div className="inner-square"></div>
                                                            </div>
                                                        </label>
                                                    ))}
                                                </div>
                                                <div className={windowWidth > 500 ? "question-buttom-number" : "d-none"}>
                                                    {questionIndex + 1} to {questions.length} Q
                                                </div>
                                                {windowWidth < 500 ?
                                                <div className="">
                                                <button className="btn btn-custom" onClick={() => handleClear(item, index)}>
                                                    Clear
                                                </button>
                                                </div>
                                                : 
                                                <div className="clear-buttom-number">
                                                    <button className="btn btn-custom" onClick={() => handleClear(item, index)}>
                                                        Clear
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* Next and PRevious button */}
                                <div className="btn-box-question d-grid gap-2 d-flex justify-content-end">
                                    {questionIndex === 0 ? null :
                                        < button onClick={() => { setQuestionIndex(questionIndex - 1) }} className="btn btn-custom me-2 exam-btn"> Previous </button>
                                    }
                                    {questions.length === questionIndex + 1 ?
                                        <button type='button' onClick={SubmitFunc} className="btn btn-success exam-btn">Submit</button> :
                                        <button type='button' onClick={() => { setQuestionIndex(questionIndex + 1) }} className="btn btn-custom exam-btn">Next</button>
                                    }
                                </div>
                            </div>

                            
                        </div>
                    </div>
                    {/* Times up modal */}
                {
                                timesup && endexam === false ? <>
                                    <Modal show={true} className="d-flex justify-content-center align-items-center">
                                        <Modal.Body>
                                            <div className='container text-center'>
                                                <img src={timeUp} alt="time's up" width="150px" height="150px" />
                                                <h5>Time's up! Your exam has ended</h5>
                                                <button type='button' onClick={onClickSubmit} className="mt-2 mx-5 btn btn-success exam-btn">Submit</button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </> : null
                            }
                            {
                                endexam ? <>
                                    <Modal show={endexam} className="d-flex justify-content-center align-items-center">
                                        <Modal.Body>
                                            <div className='container text-center'>
                                                <img src={trophy} alt="time's up" width="150px" height="150px" />
                                                <h5>Congratulations <br /> you successfully finished the exam!</h5>
                                                <button type='button' onClick={onClickSubmit} className="mt-3 mx-5 btn btn-success exam-btn">Submit</button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </> : null
                            }
                            {
                                confirmationExam ? <>
                                    <Modal show={confirmationExam} className="d-flex justify-content-center align-items-center">
                                        <Modal.Body>
                                            <div className='container text-center'>
                                                <img src={Confirmation} alt="time's up" width="150px" height="150px" />
                                                <h5>Still, you have time. <br /> Do you want to finish the exam?</h5>
                                                <div style={{display:"flex"}}>
                                                    <button type='button' onClick={()=>{setConfirmationExam(false)}} className="mt-3 mx-5 btn btn-secondary exam-btn">Cancel</button>
                                                    <button type='button' 
                                                        onClick={() => {
                                                            handleSubmit();
                                                            setConfirmationExam(false);
                                                            setEndexam(true);
                                                        }} 
                                                        className="mt-3 mx-5 btn btn-success exam-btn">Submit</button>
                                                </div> 
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </> : null
                            }
                            
                            {
                                showModal ? <>
                                    <Modal show={showModal} className="d-flex justify-content-center align-items-center">
                                        <Modal.Body>
                                            <div className='container text-center'>
                                            <p>
                                                Warning: You switched tabs or minimized the browser. You have {remainingAttempts === 0 ? 'no more' : remainingAttempts} attempts left.
                                            </p>
                                                <button type='button' onClick={handleCloseModal} className="mt-3 mx-5 btn btn-success exam-btn">OK</button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </> : null
                            }

                            {
                                isOnline ? <>
                                <Modal show={isOnline} className="d-flex justify-content-center align-items-center">
                                    <Modal.Body>
                                        <div className='container text-center'>
                                        <p>
                                            Oops! Network Error...
                                        </p>
                                            <button type='button' onClick={handleCloseNetwork} className="mt-3 mx-5 btn btn-success exam-btn">OK</button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </> : null
                            }
                            
                    </div>
                    
                }  
             
            </>
            }    
        </section>
    )
}