import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import './Candidate_Login.css';
import './Change_password';
import axios from "axios";
import fgtImg from '../../assets/Candidate assets/fgtpsd.png'
import './Forgot_password.css'
import { toast } from 'react-toastify';
import { RotatingLines } from  'react-loader-spinner'
import apiName from '../Utils/BackendApi';

export default function Forgotpassword(props) {

    const [email, setEmail] = useState('');
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [btnDisable, setBnDisable] = useState(false);

    const handleClose = () => {
            setShow(false);
            setEmail('');
            setLoader(false);
            setBnDisable(false);
    }
    const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const handleSubmit = () => {
       console.log("working")
        if(email.trim().length > 0 ){
            setLoader(true);
             setBnDisable(true);
            axios.post(`${apiName}api/forgot-password-request`, {
                email: email
            }).then(res => {
                localStorage.setItem("msg", JSON.stringify(res.data));
                let msg = JSON.parse(localStorage.getItem("msg")).status;
                if (msg !== "Invalid Email! try again" && email !== "" && res.data !== "Please Enter valid email address") {
                    setLoader(false);
                    localStorage.setItem('userMail', JSON.stringify(email));
                    let userMail = JSON.parse(localStorage.getItem('userMail'));
                    navigate('/Change_password', {
                        state: {
                            email: userMail,
                            role:props.role
                        }
                    });
                    
                }
                else {
                    toast.error("Please Enter Registered Email ID")
                    setLoader(false);
                }
            }).catch(error => {
                //toast.warning("Something Went Wrong")  ;
                setLoader(false);
              });

        }
     
    }
      //prevent form submitting
      const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
      
      
        <> 
            <a onClick={handleShow} className='sgn-fgtpass' href='#Forgotpassword'>Forgot Password</a>

            <Modal 
                    show={show} 
                    onHide={handleClose} 
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="my-modal">
                <Modal.Header closeButton style={{borderBottom:'0px'}}>
                </Modal.Header>
                <Modal.Body className='mb-5' >
               
                    <div className='container  d-sm-flex ' >
                        <img src={fgtImg} alt="forgotPassword"  width="170px" height="170px" 
                        className="rounded-circle mb-2"/>
                        <div className='ms-3 container-fluid'>
                            <h6 className='mb-2 fgt-title'>Forgot Your Password ?</h6>
                            <p className="fgt-parag mb-4" >Enter the email address assoicated with your account</p>
                            <form className='form flex'  onSubmit={handleFormSubmit}>
                                <input name="email"  id='fgt-input'
                                    type="email" onChange={(e) => {setEmail(e.target.value)}} 
                                        placeholder="Enter Email"  required="required"
                                     className="form-control w-100  mb-3" autoFocus />

                                <button type="submit" onClick={handleSubmit} 
                                   disabled={btnDisable?"disabled":''} 
                                id="fgt-btn"
                                    className="btn btn-primary w-20 ">
                                      {loader ? <><RotatingLines
                                                        strokeColor="#fff"
                                                        strokeWidth="4"
                                                        animationDuration="1.5"
                                                        width="19"
                                                        visible={true}/>
                                                        <span className='ms-2' style={{fontSize:"13px"}}>
                                                                Sending....</span> </>
                                            :  "Send OTP" }  
                                 </button>
                            </form>
                        </div>
                    </div>
                   
                </Modal.Body>
            </Modal>
        </>
    );
}