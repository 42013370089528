import React, { useState, useEffect } from "react";
import Sidebar from '../../../Sidebar/Sidebar';
import LeftSideBar from "../../../Sidebar/Left_SideBar";
import './Question.css'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import apiName from "../../../../Utils/BackendApi";

export default function AddQuestion() {
    const { id } = useParams();
    const { name } = useParams();
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const navigate = useNavigate();

    const [inputValues, setInputValues] = useState({
        question: "",
        optionA: "",
        optionB: "",
        optionC: "",
        optionD: "",
        answer: ""
    })

    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInputValues((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrorsMessage((prevState) => ({
            ...prevState,
            [name]: ""
        }));
    };

    const handleClose = () => {
        setErrorsMessage({});
        navigate(-1)
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(inputValues)) {
            var name;
            if (key === "question") { name = "Question" };
            if (key === "optionA") { name = "Option A" };
            if (key === "optionB") { name = "Option B" };
            if (key === "optionC") { name = "Option C" };
            if (key === "optionD") { name = "Option D" };
            if (key === "answer") { name = "Answer" };

            if (!String(value).trim()) {
                errors[key] = true;
                errorMessage[key] = `${name} is required`;
            }
        }

        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);

            axios.post(`${apiName}api/question/create-questions/${userId}`, {
                questionbank: id,
                question: inputValues.question,
                option_a: inputValues.optionA,
                option_b: inputValues.optionB,
                option_c: inputValues.optionC,
                option_d: inputValues.optionD,
                correct_option: inputValues.answer
            })
                .then((res) => {
                    if (res.data) {
                        setTimeout(() => {
                            toast.success("Question has been created", { toastId: "addQues-1" });
                            setErrorsMessage({});
                            navigate(-1)
                            setIsSubmitting(false);
                        }, 1000);
                    } else {
                        toast.error("Failed, please try again", { toastId: "addQues-1" });
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    toast.error(e.message, { toastId: "err-api" });
                    setIsSubmitting(false);
                });
        } else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, { toastId: "error" });
        }
    };

   
    return (
                <div className={`d-flex`}>
                    <LeftSideBar />
                    <div className="main-content" style={{ paddingTop: '2em', paddingBottom: '2em', display: "block" }}>
                        <div className="mx-sm-5">
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                            <h4>Add New Questions</h4>
                            <button onClick={handleClose} className={`btn btn-light fw-bold me-2`}>
                                    Go Back
                            </button>
                        </div>
                        <div className="my-3">
                            <h6 className="fw-bold text-capitalize" style={{ fontSize: "18px" }}>Question Bank Name: {name}</h6>
                        </div>
                        <div style={{margin:"35px 0px 20px"}}>
                        <div className="formCard">
                            <div className="py-1">
                                <div className="my-2">
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Question</span>
                                    </div>
                                    <label htmlFor="questions" className="questions-label me-sm-2">Question Name: </label>
                                    <textarea id="questions" name="question" type="text" value={inputValues.question} className="question-textarea" onChange={handleChange} placeholder="Enter Question" />
                                    {errorsMessage.question && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.question}</span>}
                                </div>
                            </div> 
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard">
                            <div className="py-1">
                                <div className="my-2">
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Options</span>
                                    </div>
                                    {/* <h6 className="questions-label" style={{textDecoration:"underline", margin:"0px 0px 10px"}}>Options</h6> */}
                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-a" className="questions-label me-sm-2">Option A: </label>
                                            <input id="option-a" name="optionA" type="text" value={inputValues.optionA} className="question-input" onChange={handleChange} placeholder="Enter Option A" />
                                            {errorsMessage.optionA && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.optionA}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-b" className="questions-label me-sm-2">Option B: </label>
                                            <input id="option-b" name="optionB" type="text" value={inputValues.optionB} className="question-input" onChange={handleChange} placeholder="Enter Option B" />
                                            {errorsMessage.optionB && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.optionB}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-c" className="questions-label me-sm-2">Option C: </label>
                                            <input id="option-c" name="optionC" type="text" value={inputValues.optionC} className="question-input" onChange={handleChange} placeholder="Enter Option C" />
                                            {errorsMessage.optionC && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.optionC}</span>}
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <label htmlFor="option-d" className="questions-label me-sm-2">Option D: </label>
                                            <input id="option-d" name="optionD" type="text" value={inputValues.optionD} className="question-input" onChange={handleChange} placeholder="Enter Option D" />
                                            {errorsMessage.optionD && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.optionD}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard">
                            <div className="pt-1">
                                <div className="mt-2" >
                                    <div className="questions-label" style={{margin:"-35px 0px 10px"}}>
                                        <span className="questions-header">Answer</span>
                                    </div>
                                    {/* <h6 className="questions-label" style={{textDecoration:"underline", margin:"0px 0px 10px"}}>Answer</h6> */}
                                    <div className="row">
                                        <div className="col-md-3 text-md-center">
                                            <p className="questions-label py-md-2">Select Answer: </p>    
                                        </div>
                                        <div className="col-md-9">
                                            <select id="answer-option" name="answer" value={inputValues.answer} onChange={handleChange} className="question-textarea py-2">
                                                <option value="">Select Answer</option>
                                                <option value="option_a">Option A</option>
                                                <option value="option_b">Option B</option>
                                                <option value="option_c">Option C</option>
                                                <option value="option_d">Option D</option>
                                            </select>
                                            {errorsMessage.answer && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.answer}</span>}
                                            
                                        </div>
                                    </div>

                                </div>
                            </div> 
                        </div>
                        </div>
                        <div className="" style={{display:"flex", justifyContent:"end"}}>
                            <button className="btn btn-secondary me-2" onClick={handleClose}>Cancel</button>
                            <button className="btn btn-custom" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Uploading...' : 'Submit'}</button>
                        </div>
                        </div>
                    </div>
                </div>
            );

}