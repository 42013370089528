import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import './Admin_Dashboard.css';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import examCalender from "../../../assets/Questions/exam-calendar.gif";
import moment from 'moment';

function WeekdayNavbar({ contentData }) {
  const today = dayjs(); // Get today's date
  const [selectedDate, setSelectedDate] = useState(today.format('YYYY-MM-DD')); // Initialize with the date part only

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const upcomingDays = [];

  for (let i = 0; i < 5; i++) {
    const day = today.add(i, 'day');
    const dayName = daysOfWeek[day.day()]; // Get the day name
    upcomingDays.push(dayName);
  }

  const upcomingExam = contentData.filter(item => dayjs(item.start_date).format('YYYY-MM-DD') === selectedDate);

  // console.log("upcomingExam: ", upcomingExam)


  return (
    <>
    <div className='' style={{height:"auto", margin:"30px 20px"}}>
    <div className="navbar-weekday">
      {upcomingDays.map((day, index) => (
        <div
          key={index}
          className={`day-circle ${selectedDate === today.add(index, 'day').format('YYYY-MM-DD') ? 'today' : ''}`}
          onClick={() => handleDateClick(today.add(index, 'day').format('YYYY-MM-DD'))}
        >
          {day}
        </div>
      ))}
    </div>
    <div className='vertical-line w-100'></div>
    </div>
    <div className=''>
        {upcomingExam.length > 0 ? 
            <ExamSlider contentData={upcomingExam}/>
            :
            <div className='examCard mt-5 text-center'>
                <img src={examCalender} alt="exam-calender" width={100} height={100} />
                <h4 className='py-2' style={{fontSize:"14px", color: 'rgba(52, 49, 76, 0.54)'}}>Exam not scheduled for this date.</h4>
            </div>
        }
        
      </div>
    </>
  );
}

function ExamSlider({ contentData }) {
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: (dots) => (
            <div>
              <div className="mt-4">
                <button className="btn btn-none" style={{border:"none"}} onClick={() => sliderRef.slickPrev()}>
                <i className="bi bi-arrow-left-circle-fill" style={{ fontSize: '20px', color: 'rgb(116, 103, 239)' }}></i>
                </button>
                {/* {dots} */}
                <button className="btn btn-none" style={{border:"none"}} onClick={() => sliderRef.slickNext()}>
                <i className="bi bi-arrow-right-circle-fill" style={{ fontSize: '20px', color: 'rgb(116, 103, 239)' }}></i>
                </button>
              </div>
            </div>
          ),
    };

    const duration = (startTime, endTime) => {
        if (!startTime || !endTime) return;
    
        const format = 'HH:mm';
        const startDateTime = moment(startTime, format);
        const endDateTime = moment(endTime, format);
        const duration = moment.duration(endDateTime.diff(startDateTime));

        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        let formattedDifference = '';

        if (hours > 0) {
            formattedDifference += `${hours} hour${hours > 1 ? 's' : ''} `;
        }

        if (minutes > 0) {
            formattedDifference += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        return formattedDifference;
    }


    let sliderRef = null;

  const setSliderRef = (slider) => {
    sliderRef = slider;
  };
  
    return (
      <Slider {...settings}
        ref={setSliderRef}
      >
        {contentData.map((item, index) => (
          <div className='my-2' >
            <div className='examCard' style={{position:"relative"}} key={index}>
                <h4 className='examCard-heading'>Exam: <span className='examCard-value'>{item.exam_name}</span></h4>
                <p className='examCard-line'>Date: <span className='examCard-value'>{dayjs(item.start_date).format('DD/MM/YYYY')}</span></p>
                <p className='examCard-line'>Time: <span className='examCard-value'>{dayjs(item.start_time, "HH:mm").format('h:mm A')}</span> to <span className='examCard-value'>{dayjs(item.end_time, "HH:mm").format('h:mm A')}</span></p>
                <p className='examCard-line'>Duration: <span className='examCard-value'>{duration(item.start_time, item.end_time)}</span></p>
                <div style={{ position: "absolute", top: "7%", right: "2%" }}>
                    <p style={{backgroundColor:"white", borderRadius:"18px", padding:"8px 10px", fontSize:"13px"}} className={`${Number(item.status) === 3 ? 'text-danger fw-bold' : Number(item.status) === 2 ? 'text-success fw-bold' : 'text-secondary fw-bold'}`}>
                        {Number(item.status) === 3 ? 'Expired' : Number(item.status) === 2 ? 'Published' : Number(item.status) === 4 ? 'Completed' :'Draft'}
                    </p>
                </div>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

export default WeekdayNavbar;
