import Sidebar from "../Sidebar/Sidebar";
import LeftSideBar from "../Sidebar/Left_SideBar";
import './Admin_Dashboard.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import adminpic from '../../../assets/admin/Admin.svg'
import WeekdayNavbar from "./WeekdayNavbar";
import examlistSpy from "../../../assets/Questions/exam-list-spy.gif"
import studentCup from "../../../assets/Questions/student-cup.gif"
import questionBankList from "../../../assets/Questions/question-bank-list.gif"
import LoadingSpinner from "../../Utils/LoadingSpinner";
import moment from 'moment';
import { useRef } from "react";
import apiName from "../../Utils/BackendApi";


export default function AdminDashboard() {

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [data, setData] = useState([]);
    const [examList, setExamList] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [questionBank, setQuestionBank] = useState([]);
    const [flagToUpdate, setFlagToUpdated] = useState([]);

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }

        axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setExamList((res.data).reverse());
                setLoader(true);
                const today = dayjs();
                const updatedList = res.data.reduce((accumulator, item) => {
                    const parsedTargetDate = dayjs(item.start_date, { format: 'DD-MM-YYYY' });
                    const isSameDate = parsedTargetDate.isSame(today, 'day');

                    if (isSameDate && (Number(item.status) === 1 || Number(item.status) === 2)) {
                        accumulator.push(item);
                    }

                    return accumulator;
                }, []);
                setFlagToUpdated(updatedList);

                const examResult = (res.data.filter(item => Number(item.status) === 4));
                // Get the top five items
                const topFiveData = examResult.slice(0, 5);

                setStudentData(topFiveData);

                // if (examResult.length > 0 && examResult.length === 1) {
                //     axios.get(`https://exam.prod-app.in/api/result/get-all-results/${userId}/${examResult[0].id}`).then((res) => {
                //         if (res.data !== 'No data found') {
                //             const filterStudentData = res.data.filter(item => item.student_status !== "not attempted");
                //             const sortedStudents = filterStudentData.sort((a, b) => b.final_result.final_score - a.final_result.final_score);
                //             const top5Students = sortedStudents.slice(0, 5);
                //             // setStudentData(top5Students);
                //         }
                //     })
                //         .catch((e) => {
                //             toast.error(e.message, { toastId: "err-api" });
                //         });
                // }

                // else if (examResult.length > 1) {
                //     axios.get(`https://exam.prod-app.in/api/result/get-all-results/${userId}/${examResult[examResult.length - 1].id}`).then((res) => {
                //         if (res.data !== 'No data found') {
                //             const filterStudentData = res.data.filter(item => item.student_status !== "not attempted");
                //             const sortedStudents = filterStudentData.sort((a, b) => b.final_result.final_score - a.final_result.final_score);
                //             const top5Students = sortedStudents.slice(0, 5);
                //             // setStudentData(top5Students);
                //             console.log("data : ======= : ", res.data)
                //         }
                //     })
                //         .catch((e) => {
                //             toast.error(e.message, { toastId: "err-api" });
                //         });
                // }
            }
            else {
                setLoader(true);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });


        axios.get(`${apiName}api/question/get-all-questionbank/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                const sortedData = res.data.sort((a, b) => b.total_question_count - a.total_question_count);

                // Get the top five items
                const topFiveData = sortedData.slice(0, 4);

                setQuestionBank(topFiveData);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });

    }, [userId])

    useEffect(() => {
        const intervalIds = flagToUpdate.map((item) => {
            const endDateTime = new Date(`${item.start_date}T${item.end_time}`);

            const shouldCheck = () => {
                const currentDateTime = new Date();
                return currentDateTime >= endDateTime;
            };

            const autoupdate = () => {
                // console.log(`data update on ${item.start_date} at ${item.end_time}!`);
                axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
                    if (res.data !== 'No data found') {
                        setData(res.data);
                    }
                })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            };

            const intervalId = setInterval(() => {
                if (shouldCheck()) {
                    autoupdate();
                    clearInterval(intervalId);
                }
            }, 60000);

            return intervalId;
        });

        return () => {
            intervalIds.forEach((intervalId) => clearInterval(intervalId));
        };
    }, [flagToUpdate]);

    const duration = (startTime, endTime) => {
        if (!startTime || !endTime) return;

        const format = 'HH:mm';
        // const startDateTime = dayjs(startTime, format);
        // const endDateTime = dayjs(endTime, format);
        // const minutesDifference = endDateTime.diff(startDateTime, 'minute');

        const startDateTime = moment(startTime, format);
        const endDateTime = moment(endTime, format);
        const duration = moment.duration(endDateTime.diff(startDateTime));

        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();

        let formattedDifference = '';

        if (hours > 0) {
            formattedDifference += `${hours} hour${hours > 1 ? 's' : ''} `;
        }

        if (minutes > 0) {
            formattedDifference += `${minutes} minute${minutes > 1 ? 's' : ''}`;
        }

        // const hours = Math.floor(minutesDifference / 60);
        // const minutes = minutesDifference % 60;
        // const formattedTimeDifference = `${hours > 0 ? `${hours}:` : ''}${minutes > 0 ? `${minutes.toString().padStart(2, '0')}` : ''} ${hours > 0 ? hours > 1 ? 'hours' : 'hour' : minutes > 1 ? 'minutes' : 'minute'}`

        return formattedDifference;
    }

  
    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
                <div className="main-content" >
                    <div className="divider">
                        <div className="left-content" style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                            <div className="welcome-card">
                                <div className="text-white">
                                    <h4 className="heading-text">Hi {JSON.parse(user).username}</h4>
                                    <p className="subline-text">
                                        Welcome to your Exam Control Panel.
                                        <br />
                                        Now, take charge of a new exam and enhance your administrative performance.
                                    </p>
                                </div>
                                <img className="admin-image" alt="admin image" src={adminpic} height={'100px'}></img>
                            </div>
                            <div className="my-4">
                                <div className="display-progress">
                                    <div className="progress-left-div" style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                                        <div className="result-div">
                                            <h6 style={{ marginTop: "0px", marginLeft: "0px", fontSize: "16px", fontWeight: "500", lineHeight: "1.5", textTransform: "none" }}>Examination</h6>
                                            {data.length > 0 ? examList.slice(0, 4).map((item, index) => {
                                                return (
                                                    <div className="subject-div" key={index}>
                                                        <div className="subject-name">
                                                            <div>
                                                                <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: 'rgb(255, 158, 67)' }}></div>
                                                            </div>

                                                            <div style={{ boxSizing: 'border-box', marginLeft: "12px" }}>
                                                                <p style={{ fontSize: "14px", textTransform: "none", lineHeight: '1.2', whiteSpace: 'normal' }}>{item.exam_name}</p>
                                                                <p style={{ paddingTop: "-20px", fontSize: '12px', fontWeight: '500', lineHeight: '0.1', color: 'rgba(52, 49, 76, 0.54)', textTransform: 'none' }}>{dayjs(item.start_date).format('DD/MM/YYYY')}</p>
                                                            </div>
                                                        </div>
                                                        {/* <div className="subject-duration" >
                                                    <p style={{fontSize: '14px', fontWeight: '500', lineHeight: '1.5', color: 'rgba(52, 49, 76, 0.54)', textTransform: 'none'}}>Duration: </p>
                                                    <p style={{fontSize: '14px', fontWeight: '500', lineHeight: '1.5', color: 'rgba(52, 49, 76, 0.54)', textTransform: 'none'}}>{duration(item.start_time,item.end_time)}</p>
                                                </div> */}
                                                    </div>
                                                )
                                            })
                                                :
                                                <div className="text-center">
                                                    <div>
                                                        <img src={examlistSpy} alt="helloHi-gif" width={150} height={150} style={{ margin: "10px auto" }} />
                                                    </div>

                                                    <button className="btn btn-custom" onClick={() => navigate('/exam-list/create-exam')}>Create a Exam</button>
                                                    <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}> Please Create your Exam.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="progress-right-div " style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                                        <div className="time-line-div">
                                            <h6 style={{ marginTop: "0px", marginLeft: "0px", fontSize: "16px", fontWeight: "500", lineHeight: "1.5", textTransform: "none" }}>Upcoming Exam</h6>
                                            <WeekdayNavbar contentData={data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-4">
                                <div className="create-exam-div">
                                    <div className="create-left-exam">
                                        <div className="create-examination" onClick={() => { navigate(`/exam-list`) }}>
                                            <div className="create-template">
                                                <div className="create-icon">
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', fontWeight: '500', color: 'white', textTransform: 'none' }}>EX</p>
                                                </div>
                                                <div className="text-nowrap">
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', color: 'rgb(224, 224, 224)', textTransform: 'none' }}>Create Exam</p>
                                                    <p style={{ marginBottom: '0px', marginTop: '4px', fontSize: '15px', lineHeight: '1.5', fontWeight: '500', color: 'white', textTransform: 'none' }}>Add Examination</p>
                                                </div>
                                            </div>
                                            <button className="btn btn-none" style={{ border: "none" }} onClick={() => { navigate(`/exam-list`) }}>
                                                <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'white' }}></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="create-right-exam">
                                        <div className="create-questionBank" onClick={() => { navigate(`/question-bank`) }}>
                                            <div className="create-template">
                                                <div className="create-icon" style={{ backgroundColor: 'rgb(255, 99, 120)' }}>
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', fontWeight: '500', color: 'white', textTransform: 'none' }}>QB</p>
                                                </div>
                                                <div className="text-nowrap">
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', color: 'rgb(224, 224, 224)', textTransform: 'none' }}>Create Question Bank</p>
                                                    <p style={{ marginBottom: '0px', marginTop: '4px', fontSize: '15px', lineHeight: '1.5', fontWeight: '500', color: 'white', textTransform: 'none' }}>Add Question Bank</p>
                                                </div>
                                            </div>
                                            <button className="btn btn-none" style={{ border: "none" }} onClick={() => { navigate(`/question-bank`) }}>
                                                <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'white' }}></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-content" style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                            <div className="right-content-div">
                                <div style={{ height: "350px" }}>
                                    <div className="">
                                        <h6 style={{ marginBottom: '8px', marginTop: '0px', fontSize: '16px', fontWeight: '600', lineHeight: '1.5', textTransform: 'none' }}>Exam Results</h6>
                                    </div>
                                    {studentData.length > 0 ?
                                        <div>
                                            {studentData.map((item, index) => {
                                                return (
                                                    <div className="top-studentlist" key={index} onClick={() => { navigate(`/exam-records/exam-student-records/${item.id}/${item.exam_name}`) }}
                                                    // style={{
                                                    // background: index === 0 ? 
                                                    // 'linear-gradient(90deg, rgb(251, 243, 243) -19.83%, #FFD700 169.85%)' : 
                                                    // index === 1 ?
                                                    // 'linear-gradient(90deg, rgb(251, 243, 243) -19.83%, #C0C0C0 169.85%)' :
                                                    // index === 2 ?
                                                    // 'linear-gradient(90deg, rgb(251, 243, 243) -19.83%, #CD7F32 169.85%)' :
                                                    // 'linear-gradient(90deg, rgb(251, 243, 243) -19.83%, rgb(233, 237, 237) 169.85%)'}}
                                                    >
                                                        <div style={{ gap: '16px', display: 'flex' }}>
                                                            <div className="student-text-icon-div"
                                                               style={{ background: "linear-gradient(90deg, rgb(253, 240, 255) -19.83%, rgb(222, 255, 255)", color: "rgba(116, 103, 239, 0.8)" }}>
                                                                <span className="student-text-icon">
                                                                    {item.exam_name[0]}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{item.exam_name}</p>
                                                                <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none', color: "rgba(52, 49, 76, 0.54)" }}>{dayjs(item.start_date).format('DD/MM/YYYY')}</p>
                                                            </div>

                                                        </div>
                                                        <button className="btn btn-none" style={{ border: "none" }} onClick={() => { navigate(`/exam-records/exam-student-records/${item.id}/${item.exam_name}`) }}>
                                                            <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'rgba(116, 103, 239, 0.8)' }}></i>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="text-center student-result-empty-div" style={{ height: "300px" }}>
                                            <div>
                                                <img src={studentCup} alt="helloHi-gif" width={150} height={150} style={{ margin: "10px auto", marginTop: "-6px", marginRight: "80px" }} />
                                            </div>

                                            <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}>Examination are not completed yet!</p>
                                        </div>
                                    }
                                </div>
                                <div className="">
                                    <div className="">
                                        <h6 className="right-div-heading">Question Banks</h6>
                                    </div>

                                    {questionBank.length > 0 ?
                                        <div>
                                            {questionBank.map((item, index) => {
                                                return (
                                                    <div className="question-bank-div" key={index} onClick={() => { navigate(`/question-bank/question-list/${item.id}/${item.name}`) }}>
                                                        <div style={{ gap: '16px', display: 'flex' }}>
                                                            <div className="student-text-icon-div" style={{ backgroundColor: 'rgba(116, 103, 239, 0.1)', background: "rgba(116, 103, 239, 0.1)", color: "rgb(116, 103, 239)" }}>
                                                                <span className="student-text-icon">
                                                                    {item.name[0]}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{item.name}</p>
                                                                <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none', color: 'rgba(52, 49, 76, 0.54)' }}>no of questions: {item.total_question_count}</p>
                                                            </div>

                                                        </div>
                                                        <button className="btn btn-none" style={{ border: "none" }} onClick={() => { navigate(`/question-bank/question-list/${item.id}/${item.name}`) }}>
                                                            <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'rgba(116, 103, 239, 0.8)' }}></i>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        :
                                        <div className="text-center student-result-empty-div">
                                            <div>
                                                <img src={questionBankList} alt="helloHi-gif" width={100} height={100} style={{ margin: "30px auto" }} />
                                            </div>

                                            <button className="btn btn-custom" onClick={() => { navigate('/question-bank') }}>Go to Question Bank</button>
                                            <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold", paddingBottom: "25px" }}> Please Create your Question bank</p>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                :
                <LoadingSpinner />}
        </div>
    )
}