import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import './CandidateDashboard.css'
import { toast } from 'react-toastify';
import dayjs from "dayjs";
import LogoutPopup from "../../Admin/Sidebar/Logout";
import adminpic from "../../../assets/Candidate assets/Candidate.png"
import examtoday from "../../../assets/Candidate assets/exam_today.png"
import ExamIntroPopup from "../Exam Intro/ExamIntroPopup";

import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Tooltip from '@mui/material/Tooltip';


import { Pagination } from '@mui/material';
import { Box } from '@mui/system';
import CountdownTimer from "./CountDown";
import apiName from "../../Utils/BackendApi";
import LoadingSpinner from "../../Utils/LoadingSpinner";


function CandidateDashboard() {

    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const user = localStorage.getItem("mcq-candidates");
    const userId = user && JSON.parse(user).id;
    const [logoutIsOpen, setLogoutIsOpen] = useState(false);
    const [examIntroIsOpen, setExamIntroIsOpen] = useState(false);
    const [examIntroData, setExamIntroData] = useState();
    const [data, setData] = useState(
        [
            {
                id: "",
                exam_name: "",
                start_date: "",
                start_time: "",
                end_time: "",
                status: "",
                student_status: "",
                description: "",
                enable_negative_mark: "",
                question_banks: [
                    {
                        id: "",
                        name: "",
                        admin_id: "",
                        questions: [
                            {
                                id: "",
                                questionbank: "",
                                question: "",
                                option_a: "",
                                option_b: "",
                                option_c: "",
                                option_d: "",
                                correct_option: ""
                            }
                        ]
                    }
                ]
            }
        ]
    );
    const [upcomingExam,setUpcomingExam] = useState([]);
    const [historyList, setHistoryList] = useState([]);

    const itemsPerPage = 2; // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = upcomingExam.slice(indexOfFirstItem, indexOfLastItem); //add 2 for upcoming exam

    // history
    const historyitemsPerPage = 2; // Number of items to display per page
    const [currentPagehistory, setCurrentPagehistory] = useState(1);

    const indexOfLastItemhistory = currentPagehistory * historyitemsPerPage;
    const indexOfFirstItemhistory = indexOfLastItemhistory - historyitemsPerPage;
    const currentItemshistory = historyList.slice(indexOfFirstItemhistory, indexOfLastItemhistory); //add 2 for upcoming exam

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handlePageChangehistory = (event, value) => {
        setCurrentPagehistory(value);
    }

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("mcq-candidates");
            navigate('/');
        }
        else {

            axios.get(`${apiName}api/student-applied-exam/${userId}`).then((response) => {
                const allExams = response.data
                    .filter(item => String(item.status) === "2" && item.student_status === "not attempted" ) 
                    .sort((a, b) => {
                        const dateA = new Date(a.start_date);
                        const dateB = new Date(b.start_date);

                        // Compare dates
                        if (dateA.getTime() !== dateB.getTime()) {
                            return dateA - dateB;
                        } else {
                            // If dates are the same, compare times
                            const timeA = new Date(a.start_date).getTime();
                            const timeB = new Date(b.start_date).getTime();
                            return timeA - timeB;
                        }
                    });


                const firstTwoExams = allExams.slice(0, 1);
                const restOfTheExams = allExams.slice(1);

                const historyList = response.data
                    .filter(item => (String(item.status) === "2" || String(item.status) === "4") && item.student_status === "completed")
                    .sort((a, b) => {
                        const dateA = new Date(a.start_date);
                        const dateB = new Date(b.start_date);

                        // Compare dates
                        if (dateA.getTime() !== dateB.getTime()) {
                            return dateA - dateB;
                        } else {
                            // If dates are the same, compare times
                            const timeA = new Date(a.start_date).getTime();
                            const timeB = new Date(b.start_date).getTime();
                            return timeA - timeB;
                        }
                    });
                console.log("data: ", historyList);
                setData(firstTwoExams);
                setUpcomingExam(restOfTheExams);
                setHistoryList(historyList.reverse());
                setLoader(false);


            }).catch(error => {
                setLoader(false);
                navigate('/Error_Page');
            });
        }
    }, []);

    const handleClickOpen = (item) => {
        setExamIntroIsOpen(true);
        setExamIntroData(item);
        // navigate('/exam-intro', {state: item})
    }

    function getColorByNumber(number) {
        const colors = ['orange', 'red', 'blue'];
        const colorIndex = number % colors.length;
        return colors[colorIndex];
      }

    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu
    };


    return (
        <section onContextMenu={handleContextMenu}>
            {loader ? <LoadingSpinner /> :
            <>
            <AppBar position="static" sx={{ backgroundColor: "white", color: "black", boxShadow: "none" }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: "end", fontSize: "16px" }}>
                        Hi {JSON.parse(user).email}
                    </Typography>
                    <div>
                        <Tooltip title="Logout" arrow>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={() => setLogoutIsOpen(true)}
                            color="error"
                        >
                            <PowerSettingsNewIcon />
                        </IconButton>
                        </Tooltip>
                    </div>
                </Toolbar>
            </AppBar>

            <div className='mx-sm-5 px-3 my-2' >
                <div className="divider">
                    <div className="left-content" style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                        <div className="welcome-card-candidate">
                            <div className="text-white">
                                <h4 className="heading-text">Hi {JSON.parse(user).username}</h4>
                                <p className="subline-text">
                                    Welcome to Aspire IT Academy Examination.
                                    <br />
                                    We are excited to have you here and wish you the best of luck!
                                </p>
                            </div>
                            <img className="admin-image" alt="admin image" src={adminpic} height={'140px'} width={'200px'}></img>
                        </div>
                        <div className="box mt-4">
                            <div className="">
                                {data.length <= 0 || data === "No data found" ?
                                    <div className="current-exam-div">
                                        <div>
                                            <h6 style={{ marginBottom: '14px', marginTop: '0px', fontSize: '16px', fontWeight: '700', lineHeight: '1.5', textTransform: 'none' }}>Current Exam</h6>
                                        </div>
                                        <div className="current-exam-content" style={{height:"300px", maxWidth: '100%',paddingLeft: '0px', paddingTop: '0px', margin: '0px auto 0px'}}>
                                        <div className="exam-today-not-added">
                                            {/* <div className="current-exam-img ">
                                                <img alt="admin image" src={examtoday} height={'200px'} width={'100%'}></img>
                                            </div> */}
                                            <div>
                                            <h4 className="current-text-heading">Welcome {JSON.parse(user).username}!</h4>
                                            <h6 className="current-text-subheading" style={{ height: "50px" }}>Your examination is not scheduled for today.</h6>
                                            </div>
                                            
                    
                                        </div>
                                        </div>
                                    </div> :

                                    <div className="current-exam-div">
                                        <div>
                                            <h6 style={{ marginBottom: '14px', marginTop: '0px', fontSize: '16px', fontWeight: '700', lineHeight: '1.5', textTransform: 'none' }}>Current Exam</h6>
                                        </div>
                                        <div className="display-progress ">
                                        {data.map((item,index) => {
                                            return (
                                                <div className={data.length === 1 ? 'current-one-exam-content' : `current-exam-content`} style={{margin:"20px auto"}}>
                                                <div className={`current-exam-box-${getColorByNumber(index + 1)}`}  key={item.id}>
                                                    <div className="current-exam-header">
                                                        <div className="current-text-icon-div mx-4">
                                                            <span className="current-text-icon" style={{ fontSize: "16px", color: "white", textTransform:"capitalize" }}>
                                                                {String(item.exam_name)[0]}
                                                            </span>
                                                        </div>
                                                        <div style={{ padding: '8px', borderLeft: '1px solid white' }}>
                                                            <button className="btn-examlist" style={{ fontSize: "16px", color: "white" }} onClick={() => handleClickOpen(item)}>
                                                                View Details
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr style={{ margin: '0px', borderWidth: '0px 0px thin', borderStyle: 'solid', borderColor: 'white', opacity: "inherit" }} />
                                                    <div style={{position:"relative"}}>
                                                        <div style={{ padding: '40px 32px', color: 'white' }}>
                                                            <p className="current-text-heading" style={{ textTransform:"capitalize" }}> <span style={{color:"ivory"}}>Name:</span> {item.exam_name}</p>
                                                            <p className="current-text-subheading"> <span style={{color:"ivory"}}>Date:</span> {dayjs(item.start_date).format('DD MMMM YYYY')}</p>
                                                            <p className="current-text-subheading"> <span style={{color:"ivory"}}>Time:</span> {dayjs(item.start_time, "HH:mm").format('h:mm A')} <span style={{color:"ivory"}}>To</span> {dayjs(item.end_time, "HH:mm").format('h:mm A')}</p>
                                                        </div>
                                                        <div className="current-countDown-div">
                                                            <CountdownTimer targetDate={new Date(`${item.start_date}T${item.start_time}`)} onClickIntro={() => handleClickOpen(item)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="right-content" style={{ paddingTop: '24px', paddingLeft: '24px' }}>
                        <div className="rightside-div-candidate">
                            <div className="rightside-div-candidate-list-box mb-4">
                            <div className="">
                                <h6 style={{ marginBottom: '8px', marginTop: '0px', fontSize: '16px', fontWeight: '600', lineHeight: '1.5', textTransform: 'none' }}>Upcoming Exam</h6>
                            </div>
                            <div>
                                {currentItems.length > 0 ?
                                <>
                                {currentItems.map((item, index) => {
                                    return (
                                        <div className="upcoming-examlist" key={index}>
                                            <div style={{ gap: '16px', display: 'flex' }}>
                                                <div className="examlist-text-icon-div">
                                                    <span className="examlist-text-icon">
                                                        {String(item.exam_name)[0]}
                                                    </span>
                                                </div>
                                                <div>
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{item.exam_name}</p>
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{dayjs(item.start_date).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            <button className="btn-examlist" onClick={() => handleClickOpen(item)}>
                                                <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'rgb(116, 103, 239)' }}></i>
                                            </button>
                                        </div>
                                    )
                                })}
                                {upcomingExam.length > 2 && <Box className={`mt-2`} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Pagination
                                        count={Math.ceil(upcomingExam.length / itemsPerPage)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        variant="outlined"
                                        shape="rounded"
                                        size="small"
                                        sx={{
                                            fontSize: '16px',
                                            color: 'red',
                                            '& .MuiPaginationItem-root': {
                                                '&.Mui-selected': {
                                                    backgroundColor: "rgb(116, 103, 239)",
                                                    color: "white"
                                                },
                                                '&:hover': {
                                                    backgroundColor: "rgb(116, 133, 269)",
                                                    color: "white"
                                                },
                                            },
                                        }}
                                    />
                                </Box>}
                                </>
                                :
                                <div>
                                    <div className="exam-today-not-added">
                                        <h6 className="current-text-subheading" style={{padding:"24px" }}>Your examination is not scheduled for upcoming days.</h6>
                                    </div>
                                </div>
                                }
                            </div>
                            </div>
                            {/* histroy lisy */}
                            <div className="rightside-div-candidate-list-box">
                            <div className="">
                                <h6 style={{ marginBottom: '8px', marginTop: '0px', fontSize: '16px', fontWeight: '600', lineHeight: '1.5', textTransform: 'none' }}>Exam History</h6>
                            </div>
                            <div>
                                {currentItemshistory.length > 0 ?
                                <>
                                {currentItemshistory.map((item, index) => {
                                    return (
                                        <div className="upcoming-examlist" key={index}>
                                            <div style={{ gap: '16px', display: 'flex' }}>
                                                <div className="examlist-text-icon-div">
                                                    <span className="examlist-text-icon">
                                                        {String(item.exam_name)[0]}
                                                    </span>
                                                </div>
                                                <div>
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{item.exam_name}</p>
                                                    <p style={{ marginBottom: '0px', marginTop: '0px', fontSize: '14px', textTransform: 'none' }}>{dayjs(item.start_date).format('DD MMMM YYYY')}</p>
                                                </div>
                                            </div>
                                            {/* <button className="btn-examlist" onClick={() => handleClickOpen(item)}>
                                                <i className="bi bi-caret-right-square-fill" style={{ fontSize: '20px', color: 'rgb(116, 103, 239)' }}></i>
                                            </button> */}
                                        </div>
                                    )
                                })}
                                {historyList.length > 2 && <Box className={`mt-2`} sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Pagination
                                        count={Math.ceil(historyList.length / historyitemsPerPage)}
                                        page={currentPagehistory}
                                        onChange={handlePageChangehistory}
                                        variant="outlined"
                                        shape="rounded"
                                        size="small"
                                        sx={{
                                            fontSize: '16px',
                                            color: 'red',
                                            '& .MuiPaginationItem-root': {
                                                '&.Mui-selected': {
                                                    backgroundColor: "rgb(116, 103, 239)",
                                                    color: "white"
                                                },
                                                '&:hover': {
                                                    backgroundColor: "rgb(116, 133, 269)",
                                                    color: "white"
                                                },
                                            },
                                        }}
                                    />
                                </Box>}
                                </>
                                :
                                <div>
                                    <div className="exam-today-not-added">
                                        <h6 className="current-text-subheading" style={{padding:"24px" }}>You don't have complete any exams yet!</h6>
                                    </div>
                                </div>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <LogoutPopup isOpen={logoutIsOpen} onClose={() => { setLogoutIsOpen(false) }} />
            <ExamIntroPopup isOpen={examIntroIsOpen} onClose={() => { setExamIntroIsOpen(false) }} examData={examIntroData}/>
            </>
            }
        </section>

    )
}

export default CandidateDashboard;              