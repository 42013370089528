import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import DeleteExam from "./Delete_Exam";
import LeftSideBar from '../../Sidebar/Left_SideBar';
import Conformation from './Conformation';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Switch from '@mui/material/Switch';
import helloHi from '../../../../assets/Questions/hello-hi.gif'
import LoadingSpinner from '../../../Utils/LoadingSpinner';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useRef } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import apiName from '../../../Utils/BackendApi';


// Extend dayjs with the plugin
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function ExamList() {
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const userName = user && JSON.parse(user).username;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [searchInput, setSearchInput] = useState({
        exam: "",
        date: "",
        status: ""
    })
    const [filter, setFilter] = useState(false);
    const [QbEmpty, setQbEmpty] = useState(false);
    const [QuestionEmpty, setQuestionEmpty] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectExamId, setSelectExamId] = useState();
    const [conformation, setConformation] = useState(false);
    const [flagToUpdate, setFlagToUpdated] = useState([]);

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])

    useEffect(() => {
        axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setLoader(true);
                const today = dayjs();
                const updatedList = res.data.reduce((accumulator, item) => {
                    const parsedTargetDate = dayjs(item.start_date, { format: 'DD-MM-YYYY' });
                    const isSameDate = parsedTargetDate.isSame(today, 'day');

                    if (isSameDate && (Number(item.status) === 1 || Number(item.status) === 2)) {
                        accumulator.push(item);
                    }

                    return accumulator;
                }, []);
                setFlagToUpdated(updatedList);
            }
            else {
                setLoader(true);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });

        axios.get(`${apiName}api/question/get-all-questionbank/${userId}`).then((res) => {
            if (res.data === 'No data found') {
                setQbEmpty(true);
            }
            else {
                if (res.data.every(item => item.total_question_count === 0)) {
                    setQuestionEmpty(true);
                }
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api-qb" });
            });

    }, [userId, openDelete,conformation])

    useEffect(() => {
        const intervalIds = flagToUpdate.map((item) => {
            const endDateTime = new Date(`${item.start_date}T${item.end_time}`);

            const shouldCheck = () => {
                const currentDateTime = new Date();
                return currentDateTime >= endDateTime;
            };

            const autoupdate = () => {
                console.log(`data update on ${item.start_date} at ${item.end_time}!`);
                axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
                    if (res.data !== 'No data found') {
                        setData(res.data);
                    }
                })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            };

            const intervalId = setInterval(() => {
                if (shouldCheck()) {
                    autoupdate();
                    clearInterval(intervalId);
                }
            }, 60000);

            return intervalId;
        });

        return () => {
            intervalIds.forEach((intervalId) => clearInterval(intervalId));
        };
    }, [flagToUpdate]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearchInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const filteredData = data && data.filter(item => {
        return (String(item.exam_name).toLowerCase().includes(searchInput.exam.toLowerCase()) &&
            String(item.start_date).toLowerCase().includes(searchInput.date.toLowerCase()) &&
            String(item.status).toLowerCase().includes(searchInput.status.toLowerCase()));
    });

    const handleRowClick = (row) => {
        navigate(`/exam-list/view-exam/${row.id}`)
    };

    const handleDelete = (item) => {
        setSelectExamId(item)
        setOpenDelete(true);
    }

    const handleConformation = (item) => {
        setSelectExamId(item.id)
        setConformation(true);
    }

    const customStyles = {
        headRow: {
            style: {
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            },
        },
        rows: {
            style: {
                backgroundColor: "#fff",
                minHeight: '40px',
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            // center: true,
            width: '100px'
        },
        {
            name: 'Exam Title',
            selector: row => row.exam_name,
            grow: 3,
        },
        {
            name: 'Start Date',
            selector: row => row.start_date,
        },
        {
            name: 'Start Time',
            selector: row => row.start_time,
            center: "true"
        },
        {
            name: 'End Time',
            selector: row => row.end_time,
            center: "true"
        },
        {
            name: 'Status',
            selector: row => row.status,
            center: "true"
        },
        {
            name: 'Edit',
            selector: row => row.Edit,
            center: "true",
            width: '170px'
        },
        // {
        //     name: '',
        //     selector: row => row.delete,
        //     center: true,
        //     width: '75px'
        // },
    ];


    const rows = filteredData ? filteredData.map((item, index) => {
        return {
            no: index + 1,
            id: item.id,
            exam_name: item.exam_name,
            start_date: dayjs(item.start_date).format("DD/MM/YYYY"),
            start_time: dayjs(item.start_time, "HH:mm").format('h:mm A'),
            end_time: dayjs(item.end_time, "HH:mm").format('h:mm A'),
            status:
                Number(item.status) === 4 ?
                    <span className='status-green'>Completed</span> :
                    Number(item.status) === 3 ?
                        <span className='status-red'>Expired</span> :
                        Number(item.status) === 2 ?
                            <span className='status-green'>Published</span> :
                            <span className='status-orange'>Draft</span>,
            enable_negative_mark: item.enable_negative_mark,
            description: item.description,
            question_bank: item.question_bank,
            // conformation: <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleConformation(item)} color="inherit" > <PublishedWithChangesIcon /> </IconButton>,
            delete: <button className="btn btn-none" style={{ border: "none" }} onClick={() => handleDelete(item)} ><i className="bi bi-x-lg text-danger"></i></button>,
            Edit: (
                <div>
                  <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleRowClick(item)} sx={{ color: 'rgb(116, 103, 239)' }} disabled={Number(item.status) === 2 || Number(item.status) === 4}>
                    <Tooltip title="Edit" arrow>
                      <ModeEditIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleConformation(item)} sx={{ color: 'green', margin: "0px 5px" }} disabled={Number(item.status) === 2 || Number(item.status) === 3 || Number(item.status) === 4}>
                    <Tooltip title="Publish" arrow>
                      <PublishedWithChangesIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleDelete(item)} sx={{ color: 'red' }} disabled={Number(item.status) === 2 || Number(item.status) === 4}>
                    <Tooltip title="Delete" arrow>
                      <DeleteForeverIcon />
                    </Tooltip>
                  </IconButton>
                </div>
              )              
        }
    }) : '';

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };


    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
                <div className="main-content">
                    <div className="mx-sm-5 my-2">
                        <div className="row">
                            <div className="col-sm-9 text-start fw-bold fs-3 text-capitalize">
                                Examination
                            </div>
                            {!QbEmpty && !QuestionEmpty && <div className="col-sm-3 text-end">
                                <button className="btn" style={{ backgroundColor: "rgb(116, 103, 239)", color: "white" }} onClick={() => navigate('/exam-list/create-exam')}>Create a Exam</button>
                            </div>}
                        </div>
                        <div className="mt-4">
                            {data.length > 0 ?
                                <>
                                    <span className='search-label'>Filter 
                                    <Switch 
                                        onClick={() => {
                                            setFilter((preOpen) => !preOpen)
                                            if(filter === true){
                                                setSearchInput({ exam: "", date: "", status: "" })
                                            }
                                        }} 
                                        checked={filter} 
                                        size="small" 
                                        color="secondary" 
                                    />
                                    </span>
                                    <form className='my-2' style={{ display: filter ? "block" : "none" }}>
                                        <div className="form-row row search-box">
                                            <div className="form-group col-md-4">
                                                <label htmlFor="exam-name" className='search-label'>Name:</label>
                                                <input type="text" name='exam' className="form-control search-input" id="exam-name" onKeyDown={handleKeyDown} value={searchInput.exam} onChange={handleChange} placeholder="Name" />
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="exam-date" className='search-label'>Date:</label>
                                                {/* <input type="date" name='date' className="form-control search-input" id="exam-date" onKeyDown={handleKeyDown} value={searchInput.date} onChange={handleChange} placeholder="Date" /> */}
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        className="form-control search-input"
                                                        value={searchInput.date ? dayjs(searchInput.date) : null}
                                                        onAccept={(newValue) =>
                                                            setSearchInput((prevState) => ({
                                                                ...prevState,
                                                                date: dayjs(newValue).format('YYYY-MM-DD')
                                                            }))
                                                        }
                                                        format="DD/MM/YYYY"
                                                        sx={{
                                                            border: 'none',
                                                            outline: 'none',
                                                            borderRadius: '5px',
                                                            fontSize: "14px",
                                                            '& input': {
                                                                border: '2px solid rgb(116, 103, 239)',
                                                                borderRadius: '5px',
                                                                outline: 'none',
                                                                fontSize: "14px",
                                                                padding: '8px 14px',
                                                                '&:focus': {
                                                                    borderBottom: '2px solid rgb(116, 103, 239)',
                                                                },
                                                                '&:active': {
                                                                    borderBottom: '2px solid rgb(116, 103, 239)',
                                                                },
                                                                '&::placeholder': {
                                                                    verticalAlign: 'bottom',
                                                                    fontSize: "14px",
                                                                },
                                                            },
                                                            '& fieldset': {
                                                                border: 'none',
                                                                // borderBottom: '2px solid rgb(116, 103, 239)',
                                                                borderRadius: '8px',
                                                                fontSize: "14px",
                                                                padding: '8px 14px'
                                                            }
                                                        }}
                                                        slotProps={{ textField: { size: 'small' } }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            <div className="form-group col-md-4">
                                                <label htmlFor="exam-status" className='search-label'>Status:</label>
                                                {/* <input type="text" name='status' className="form-control search-input" id="exam-status" value={searchInput.status} onChange={handleChange} placeholder="Status"/> */}
                                                <select id="exam-status" name="status" onChange={handleChange} className="form-control search-input" onKeyDown={handleKeyDown} value={searchInput.status}>
                                                    <option value="">Status</option>
                                                    <option value="1">Draft</option>
                                                    <option value="2">Published</option>
                                                    <option value="3">Expired</option>
                                                    <option value="4">Completed</option>
                                                </select>
                                            </div>
                                            <div className='text-end my-2'>
                                                <span 
                                                    className='search-label' 
                                                    style={{cursor:"pointer"}} 
                                                    onClick={() => { 
                                                        setSearchInput({ exam: "", date: "", status: "" })
                                                    }}
                                                    >
                                                        Clear filter
                                                    </span>
                                            </div>
                                        </div>
                                    </form>
                                    <DataTable
                                        columns={columns}
                                        data={rows}
                                        fixedHeaderScrollHeight="600px"
                                        customStyles={customStyles}
                                        persistTableHead
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 25, 50]}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={handleRowClick}
                                    />
                                </>
                                :
                                <div style={{ textAlign: "center" }}>
                                    <div className='my-2' style={{ margin: "0px auto" }}>
                                        <div className='question-bank-box-card'>
                                            <div>
                                                <img src={helloHi} alt="helloHi-gif" width={250} height={200} style={{ marginTop: "20px" }} />
                                            </div>
                                            <div style={{ padding: '40px 10px 30px', color: 'indigo', textAlign: "center" }}>
                                                <p style={{ fontSize: "16px", fontWeight: "bold" }}>Hi {userName} welcome to Exam Page</p>
                                                {
                                                    QuestionEmpty ?
                                                        <>
                                                            <button className="btn btn-custom" onClick={() => navigate('/question-bank')}>Go to Question Bank</button>
                                                            <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}> Please Formulate exam queries first.</p>
                                                        </>
                                                        :
                                                        QbEmpty ?
                                                            <>
                                                                <button className="btn btn-custom" onClick={() => navigate('/question-bank')}>Go to Question Bank</button>
                                                                <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}> Please Create your Question bank first.</p>
                                                            </>
                                                            :
                                                            <>
                                                                <button className="btn btn-custom" onClick={() => navigate('/exam-list/create-exam')}>Create a Exam</button>
                                                                <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}> Please Create your Exam.</p>

                                                            </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                <LoadingSpinner />}
            <DeleteExam
                selectExamId={selectExamId}
                isOpen={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                }}
            />
            <Conformation
                selectExamId={selectExamId}
                isOpen={conformation}
                onClose={() => {
                    setConformation(false);
                }}
            />
        </div>
    )
}