import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import apiName from "../../../Utils/BackendApi";

const btnOK = {
    backgroundColor: "#464646",
    textTransform: "none",
    cursor: "pointer",
    color: "white",
    fontSize: "12px",
  }

export default function Conformation(props) {
    const { selectExamId, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const user = localStorage.getItem("mcq-user");
  const userId = user && JSON.parse(user).id;
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleSubmit = () => {
    axios.put(`${apiName}api/exam/update-status/${userId}/${selectExamId}`, {status: 2})
    .then((res) => {
        if (res.data) {
            setIsSubmitting(true);
          setTimeout(() => {
            toast.success(res.data, { toastId: "listdel" });
            setOpen(false);
            if (onClose) onClose();
            setIsSubmitting(false);
            navigate("/exam-list");
          }, 1000);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listdel" });
      });
  };

  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px"}}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogContent className="px-4 my-2 text-center">
        <i className="bi bi-check-circle text-success" style={{ fontSize: '75px' }}> </i>
        <div className='fw-bold mt-3'>
        <h5 style={{color: "#685e5e" }}>Are you sure</h5>
        <h5>Once an exam is published, you cannot edit it.</h5>
        </div>
      </DialogContent>
      <DialogActions style={{marginTop:'-15px'}} className="px-4">
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`${btnOK} btn fw-bold`}>
            {isSubmitting ? 'Uploading...' : 'Publish'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}