import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import apiName from "../../../Utils/BackendApi";

const btnOK = {
    backgroundColor: "#464646",
    textTransform: "none",
    cursor: "pointer",
    color: "white",
    fontSize: "12px",
  }

export default function ResendEmail(props) {
    const { selectExamId, studentData, clearData, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const user = localStorage.getItem("mcq-user");
  const userId = user && JSON.parse(user).id;
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };


  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const studentIds = [];

  studentData.map((item) => {
    studentIds.push(item.id)
  })

  const handleSubmit = () => {
    setIsSubmitting(true);
    axios.post(`${apiName}api/send-mail/${userId}/${selectExamId}`, studentIds)
    .then((res) => {
        if (res.data) {
            toast.success("Email send successfully!", { toastId: "resend-Email" });
            setOpen(false);
            if (onClose) onClose();
            if (clearData) clearData();
            setIsSubmitting(false);
        }
        else{
            setIsSubmitting(false);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-resend-Email" });
        setIsSubmitting(false);
      });
  };

  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px"}}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <DialogContent className="px-4 my-2 text-center">
        <i className="bi bi-envelope-at" style={{ fontSize: '75px', color:"rgb(116, 103, 239)" }}> </i>
        <div className='fw-bold mt-3'>
        <h5 style={{color: "#685e5e" }}>Are you sure</h5>
        <h5>you want to resend e-mail?</h5>
        </div>
      </DialogContent>
      <DialogActions style={{marginTop:'-15px'}} className="px-4">
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`${btnOK} btn fw-bold`}>
            {isSubmitting ? 'Sending...' : 'Send'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}