import Sidebar from '../../../Sidebar/Sidebar';
import LeftSideBar from '../../../Sidebar/Left_SideBar';
import DeleteQuestionPopup from "./Delete_Question_List_Popup";
import EditQuestionPopup from "./Edit_Question_List_Popup";
import AddQuestionPopup from "./Add_Question_List_Popup";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import welcomegif from '../../../../../assets/Questions/welcome-17.gif'
import LoadingSpinner from '../../../../Utils/LoadingSpinner';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ViewListIcon from '@mui/icons-material/ViewList';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useRef } from 'react';
import apiName from '../../../../Utils/BackendApi';


//rgb(218 220 230 / 30%)

export default function QuestionBank() {

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const userName = user && JSON.parse(user).username;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [open, setOpen] = useState(false);
    const [selectQuestionBankId, setSelectQuestionBankId] = useState();
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])

    useEffect(() => {
        axios.get(`${apiName}api/question/get-all-questionbank/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setLoader(true);
            }
            else{
                setLoader(true);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });
    }, [userId, open, openEdit, openDelete])

    const handleOpen = (item) => {
        navigate(`/question-bank/question-list/${item.id}/${item.name}`)
    }

    const handleEdit = (item) => {
        setSelectQuestionBankId(item)
        setOpenEdit(true);
    }

    const handleDelete = (item) => {
        setSelectQuestionBankId(item)
        setOpenDelete(true);
    }

    const filteredData = data && data !== "No data available" && data.filter(item => {
        return (String(item.name).toLowerCase().includes(searchQuery.toLowerCase()));
    });

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

    
    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
            <div className="main-content">
                <div className="mx-sm-5 my-2">
                    <div className="row">
                        <div className="col-sm-9 text-start fw-bold fs-3 text-capitalize">
                            Question Bank
                        </div>
                        {data.length > 0 && 
                        <div className="col-sm-3 text-end">
                            <button className="btn btn-custom" onClick={() => { setOpen(true) }}>Add Question Bank</button>
                        </div>
                        }
                    </div>
                    <div className="row mt-4">
                    { data.length > 0 ?
                        <>
                        <span className='search-label'>Filter 
                            <Switch 
                                onClick={() => {
                                    setFilter((preOpen) => !preOpen)
                                    if(filter === true){
                                        setSearchQuery('')
                                    }
                                }} 
                                checked={filter} 
                                size="small" 
                                color="secondary" 
                            /></span>
                        <form className='m-2' style={{ display: filter ? "block" : "none" }} >
                            <div className="form-row row search-box search-box-width">
                                <div className="form-group col-sm-12">
                                    <label htmlFor="exam-name" className='search-label'>Question Bank Name:</label>
                                    <input type="text" name='exam' className="form-control search-input" id="exam-name" onKeyDown={handleKeyDown} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Name" />
                                </div>
                                <div className='text-end my-2'>
                                    <span className='search-label' style={{cursor:"pointer"}} onClick={() => { setSearchQuery('') }}>Clear filter</span>
                                </div>
                            </div>
                        </form>
                        {
                            filteredData.length > 0 ?
                                filteredData.map((item, index) => {
                                    return (
                                        <div className='my-2 col-sm-4 question-bank-box' key={index}>
                                            <div className='question-bank-box-card'>
                                                <div className='question-bank-header'>
                                                    <div className="question-bank-text-icon-div mx-4">
                                                        <span className="question-bank-text-icon">
                                                            {item.name[0]}
                                                        </span>
                                                    </div>
                                                    <div style={{ padding: '8px', borderLeft: '1px solid lightsteelblue' }}>
                                                        <Tooltip title="View" arrow><IconButton size="small" aria-label="view of current question bank" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleOpen(item)} sx={{ color: 'rgb(116, 103, 239)', backgroundColor: 'rgba(0, 195, 255, 0.1)', borderRadius: "20px" }} > <ViewListIcon /> </IconButton></Tooltip>
                                                        <Tooltip title="Edit" arrow><IconButton size="small" aria-label="edit of current question bank" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleEdit(item)} sx={{ color: 'rgb(237, 108, 2)', margin: "0px 5px", backgroundColor: 'rgba(0, 195, 255, 0.1)', borderRadius: "20px" }} > <ModeEditIcon /> </IconButton></Tooltip>
                                                        <Tooltip title="Delete" arrow><IconButton size="small" aria-label="delete of current question bank" aria-controls="menu-appbar" aria-haspopup="true" onClick={() => handleDelete(item)} sx={{ color: 'red', backgroundColor: 'rgba(0, 195, 255, 0.1)', borderRadius: "20px" }} > <DeleteForeverIcon /> </IconButton></Tooltip>
                                                    </div>
                                                </div>
                                                <hr style={{ margin: '0px', borderWidth: '0px 0px thin', borderStyle: 'solid', borderColor: 'lightsteelblue', opacity: "inherit" }} />
                                                <div style={{ padding: '10px 32px', color: 'black' }}>
                                                    <p style={{ fontSize: "12px", color: "blueviolet", marginBottom: "3px" }}>Question bank name:</p>
                                                    <p style={{ fontSize: "18px", fontWeight: "bold" }}>{item.name}</p>
                                                    <p style={{ fontSize: "12px", color: "blueviolet", marginBottom: "3px" }}>No of Questions:</p>
                                                    <p style={{ fontSize: "16px", fontWeight: "bold" }}>{item.total_question_count} Questions</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div className='my-2 question-bank-box'>
                                        <div className='question-bank-box-card'>
                                            <div style={{ padding: '40px 10px 30px', color: 'indigo', textAlign: "center" }}>
                                                <p style={{ fontSize: "16px", fontWeight: "bold" }}>There are no records to display</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        </>
                            :
                            <div style={{textAlign: "center" }}>
                                <div className='my-2' style={{ margin: "0px auto" }}>
                                    <div className='question-bank-box-card'>
                                        <div>
                                            <img src={welcomegif} alt="welcome-gif" width={250} height={200} style={{ marginTop: "20px", borderRadius: "500px" }} />
                                        </div>
                                        <div style={{ padding: '40px 10px 30px', color: 'indigo', textAlign: "center" }}>
                                            <p style={{ fontSize: "16px", fontWeight: "bold" }}>Hi {userName} welcome to Question Bank Page</p>
                                            <button className="btn btn-custom" onClick={() => { setOpen(true) }}>Add new Question Bank</button>
                                            <p style={{marginTop:"20px" ,fontSize: "16px", fontWeight: "bold" }}> Please Create your Question bank</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            :
            <LoadingSpinner/>
            }

            <AddQuestionPopup
                selectQuestionBankId={selectQuestionBankId}
                isOpen={open}
                onClose={() => {
                    setOpen(false);
                }}
            />
            <EditQuestionPopup
                selectQuestionBankId={selectQuestionBankId}
                isOpen={openEdit}
                onClose={() => {
                    setOpenEdit(false);
                }}
            />
            <DeleteQuestionPopup
                selectQuestionBankId={selectQuestionBankId}
                isOpen={openDelete}
                onClose={() => {
                    setOpenDelete(false);
                }}
            />
        </div>
    )
}