import { useEffect, useRef, useState } from "react"
import './Exam.css'
import LeftSideBar from "../../Sidebar/Left_SideBar";
import SelectQuestions from "./Select_Questions";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import dayjs from "dayjs";
import ViewSelectedQuestionsPage from "./ViewSelectedQuestionPage";
import { TimePicker, ConfigProvider } from 'antd';
import moment from 'moment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import apiName from "../../../Utils/BackendApi";

import 'dayjs/locale/en';
// const customParseFormat = require('dayjs/plugin/customParseFormat');
// dayjs.extend(customParseFormat);
dayjs.locale('en'); // Set the locale
dayjs.extend(require('dayjs/plugin/weekday'));

export default function CreateExam() {

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const navigate = useNavigate();
    const [questionReview, setQuestionReview] = useState();
    const [subjectName, setSubjectName] = useState('');
    const [SelectedData, setSelectedData] = useState([]); // Initialize with initially selected rows
    const [errorsMessage, setErrorsMessage] = useState({});
    const [validater, setValidater] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [qbIndex, setQbIndex] = useState('');


    const [inputValues, setInputValues] = useState({
        examName: "",
        date: "",
        startTime: null,
        endTime: null,
        duration: 0,
        negativeMark: false,
        notes: ""
    });

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])


    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        if (type === "checkbox") {
            setInputValues((prevState) => ({
                ...prevState,
                [name]: checked,
            }));
        }
        else {
            setInputValues((prevState) => ({
                ...prevState,
                [name]: value,
            }))
        }
    };

    const validateTimeRange = (start, end) => {
        if (start && end) {
            if (start > end) {
                toast.warning('Start time cannot be greater than end time', { toastId: "timevalidation" });
            }
        }
    };

    const antdhandleChangestart = (value, dateString) => {
        const newStartTime = value ? dayjs(`2000-01-01 ${dateString}`).format('HH:mm') : null;
        setInputValues((prevState) => {
          validateTimeRange(newStartTime, prevState.endTime);
      
          return {
            ...prevState,
            startTime: newStartTime,
          };
        });
    };

    const antdhandleChangeend = (value, dateString) => {
        const newEndTime = value ? dayjs(`2000-01-01 ${dateString}`).format('HH:mm') : null;
        setInputValues((prevState) => {
          validateTimeRange(prevState.startTime, newEndTime);
      
          return {
            ...prevState,
            endTime: newEndTime,
          };
        });
    };


    useEffect(() => {
        if (inputValues.startTime && inputValues.endTime) {
            const format = 'HH:mm';
            const startDateTime = moment(inputValues.startTime, format);
            const endDateTime = moment(inputValues.endTime, format);
            const minutesDifference = endDateTime.diff(startDateTime, 'minute');
            console.log("timeDifference: ", minutesDifference);

            if (minutesDifference === 0) {
                setErrorsMessage((prevState) => ({
                    ...prevState,
                    endTime: "Invalid time",
                }));
                setValidater(true);
            }
            else {
                setErrorsMessage((prevState) => ({
                    ...prevState,
                    endTime: "",
                }));
                setValidater(false);
            }

            setInputValues((prevState) => ({
                ...prevState,
                duration: minutesDifference,
            }));
        }
    }, [inputValues.startTime, inputValues.endTime])


    // console.log(inputValues)

    // ============================================== select question ========================================================
    const handleSelectRows = (selectedRows) => {
        setSelectedData(selectedRows);
    };

    const handlexpanding = (expandable) => {
        setExpanded(expandable);
        setQbIndex('');
    };

    const handleRemove = (item) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.filter((row) => Number(row.id) !== Number(item.id))
        );
    }

    const rows = SelectedData
        ? SelectedData.map((item, index) => ({
            no: index + 1,
            subject: item.subject,
            question: item.question,
            options: [item.option_a, item.option_b, item.option_c, item.option_d],
            answer: item.correct_option,
            select: <button className="btn btn-none" style={{ border: "none" }} onClick={() => handleRemove(item)}><i className="bi bi-trash2-fill"></i></button>,
        }))
        : '';

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(inputValues)) {
            var name;
            if (key === "examName") { name = "Exam Name" };
            if (key === "date") { name = "Date" };
            if (key === "startTime") { name = "Start Time" };
            if (key === "endTime") { name = "End Time" };


            if (key !== 'negativeMark' && key !== 'notes') {
                if (key === 'date' && value === 'Invalid Date') {
                    errors[key] = true;
                    errorMessage[key] = `${name} is Invalid Date`;
                }else {
                    errors[key] = false;
                    errorMessage[key] = ``;
                }

                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else {
                    errors[key] = false;
                    errorMessage[key] = ``;
                }

                if (key === 'startTime' || key === 'endTime') {
                    if(value === null){
                        errors[key] = true;
                        errorMessage[key] = `${name} is required`;
                    } else {
                        errors[key] = false;
                        errorMessage[key] = ``;
                    }
                }
            }
        };
        // setErrorsMessage(errorMessage);
        setErrorsMessage((prevState) => ({
            ...prevState,
            ...errorMessage,
        }));

        const uniqueQuestionBankIds = [...new Set(SelectedData.map(item => item.questionbank))];
        const uniqueQuestionIds = [...new Set(SelectedData.map(item => item.id))];
        const filtervalidation = Object.values(errors).filter(item => item !== false);
        const filterErrorMsg = Object.values(errorMessage).filter(item => item !== "");

        if (validater) {
            toast.warning(`Invalid time, please make sure to correct it.`, { toastId: "error-time" });
        } 
        else if (filtervalidation.length !== 0) {
            toast.warning(`${filterErrorMsg[0]} please make sure to include it.`, { toastId: "error-validation" });
        }  
        else if (uniqueQuestionIds.length === 0) {
            toast.warning(`Please provide questions for an exam.`, { toastId: "error1-sq" });
        } 
        if(!validater && filtervalidation.length === 0 && uniqueQuestionIds.length > 0) {
            handleSubmitReview(uniqueQuestionBankIds, uniqueQuestionIds);
            console.log("inputValues: ", inputValues, "qbids: ", uniqueQuestionBankIds, "qids: ", uniqueQuestionIds);
        }
    
    }

    const handleSubmitReview = (uniqueQuestionBankIds, uniqueQuestionIds) => {
        setIsSubmitting(true);
        axios.post(`${apiName}api/exam/create-exam/${userId}`, {
            exam_name: inputValues.examName,
            start_date: inputValues.date,
            start_time: inputValues.startTime,
            end_time: inputValues.endTime,
            description: inputValues.notes,
            enable_negative_mark: inputValues.negativeMark,
            question_bank_ids: uniqueQuestionBankIds,
            question_ids: uniqueQuestionIds
        })
            .then((res) => {
                if (res.data) {
                    toast.success("Exam has been created", { toastId: "addQues-1" });
                    navigate('/exam-list')
                    setIsSubmitting(false);
                } else {
                    toast.error("Failed, please try again", { toastId: "addQues-1" });
                    setIsSubmitting(false);
                }
            })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
                setIsSubmitting(false);
            });
    }

    const uniqueSubjects = Array.from(new Set(rows.map(item => item.subject))); // Get unique subjects
    const subjectCounts = {};

    // Calculate the subject counts
    rows.forEach(item => {
        subjectCounts[item.subject] = (subjectCounts[item.subject] || 0) + 1;
    });



    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            <div className="main-content">
                <div className="m-sm-5">
                    <div className="text-start fw-bold fs-3 text-capitalize">
                        Create Exam
                    </div>

                    {/* Basic info */}
                    <div>
                        <div style={{ margin: "35px 0px 0px" }}>
                            <div className="formCard">
                                <div className="py-1">
                                    <div className="my-2">
                                        <div className="questions-label" style={{ margin: "-35px 0px 10px" }}>
                                            <span className="questions-header">Basic info</span>
                                        </div>
                                        <div className="form-group row my-2 text-center">
                                            <div className="col-lg-6">
                                                <div className="row">
                                                <label htmlFor="exam-name" className="col-md-2 col-form-label question-label">Name:</label>
                                                    <div className="col-md-10">
                                                        <input
                                                            id="exam-name"
                                                            name="examName"
                                                            className="form-control exam-input"
                                                            type="text"
                                                            value={inputValues.examName}
                                                            onChange={handleChange}
                                                        />
                                                        {errorsMessage.examName && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.examName}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 input-exam-box">
                                            <div className="row">
                                                    <label className="col-md-2 col-form-label question-label">Date:</label>
                                                    <div className="col-md-10">
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <MobileDatePicker
                                                                className="form-control exam-input"
                                                                value={inputValues.date ? dayjs(inputValues.date) : null}
                                                                onChange={(newValue) =>
                                                                    setInputValues((prevState) => ({
                                                                        ...prevState,
                                                                        date: dayjs(newValue).format('YYYY-MM-DD')
                                                                    }))
                                                                }
                                                                minDate={dayjs()}
                                                                format="DD/MM/YYYY"
                                                                sx={{
                                                                    border: 'none',
                                                                    outline: 'none',
                                                                    borderRadius: '8px',
                                                                    fontSize: "14px",
                                                                    '& input': {
                                                                        border: '2px solid rgb(116, 103, 239)',
                                                                        borderRadius: '8px',
                                                                        outline: 'none',
                                                                        fontSize: "14px",
                                                                        '&:focus': {
                                                                            borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        },
                                                                        '&:active': {
                                                                            borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        },
                                                                        '&::placeholder': {
                                                                            verticalAlign: 'bottom',
                                                                            fontSize: "14px",
                                                                        },
                                                                    },
                                                                    '& fieldset': {
                                                                        border: 'none',
                                                                        // borderBottom: '2px solid rgb(116, 103, 239)',
                                                                        borderRadius: '8px',
                                                                        fontSize: "14px",
                                                                    }
                                                                }}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                            />
                                                        </LocalizationProvider>

                                                        {errorsMessage.date && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.date}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row my-2 text-center">
                                        <div className="col-lg-6">
                                                <div className="row">
                                                    <div className="form-group my-2 d-flex align-items-center justify-content-center">
                                                        <label htmlFor="exam-marks" className="col-form-label question-label me-4">Negative Marks:</label>
                                                        <div className="">
                                                            <label className="switch">
                                                                <input
                                                                    id="exam-marks"
                                                                    name="negativeMark"
                                                                    type="checkbox"
                                                                    checked={inputValues.negativeMark}
                                                                    onChange={handleChange}
                                                                />
                                                                <span className="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <label className="col-md-2 col-form-label question-label">Time:</label>
                                                    <div className="col-md-5">
                                                        <ConfigProvider
                                                            theme={{
                                                                token: {
                                                                    activeBorderColor: 'red',
                                                                    cellActiveWithRangeBg: '#f6ffed',
                                                                },
                                                                components: {
                                                                    TimePicker: {
                                                                        backgroundColor: 'rgb(116, 103, 239)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <TimePicker
                                                                name="startTime"
                                                                key={inputValues.startTime}
                                                                className="exam-input w-100 py-2"
                                                                format={'hh:mm A'}
                                                                value={(inputValues.startTime && inputValues.startTime !== 'Invalid date') ? dayjs(`2001-01-01 ${inputValues.startTime}`) : null}
                                                                onChange={antdhandleChangestart}
                                                                placeholder="Start time"
                                                            />
                                                        </ConfigProvider>

                                                        {errorsMessage.startTime && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.startTime}</span>}
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ConfigProvider
                                                            theme={{
                                                                token: {
                                                                    activeBorderColor: 'red',
                                                                    cellActiveWithRangeBg: '#f6ffed',
                                                                },
                                                                components: {
                                                                    TimePicker: {
                                                                        backgroundColor: 'rgb(116, 103, 239)',
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            <TimePicker
                                                                name="endTime"
                                                                key={inputValues.endTime}
                                                                className=" exam-input w-100 py-2"
                                                                format={'hh:mm A'}
                                                                value={(inputValues.endTime && inputValues.endTime !== 'Invalid date') ? dayjs(`2001-01-01 ${inputValues.endTime}`) : null}
                                                                onChange={antdhandleChangeend}
                                                                placeholder="End time"
                                                            />
                                                        </ConfigProvider>

                                                        {errorsMessage.endTime && <span style={{ color: "red", fontSize: "12px" }}>{errorsMessage.endTime}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="question-link">
                        </div>
                        <div className="formCard mt-2 py-1">
                            <div className="questions-label" style={{ margin: "-20px 0px 10px" }}>
                                <span className="questions-header">Select Questions</span>
                            </div>
                            <SelectQuestions
                                selectedData={SelectedData}
                                onSelectRows={handleSelectRows}
                            />
                        
                        {rows.length > 0 && <>
                        <hr />
                            <div className="">
                                <div className="mt-2">
                                    <span className="questions-header" style={{background: 'linear-gradient(90deg, rgb(207, 103, 239) -19.83%, rgb(239, 103, 155) 189.85%)', fontWeight:"bold"}}>Select Question Banks</span>
                                </div>

                                <div className="my-2 row">
                                    {uniqueSubjects.map((subject, index) => (
                                        <div className={`col-2 m-3 pb-4 subject-list-card text-center w-auto`} style={{display: qbIndex === index ? "none" : "block"}} key={index}>
                                            {/* {expanded ||!expanded && ( */}
                                                <>
                                                    <div className="mt-2 fs-5 fw-bold text-capitalize">{subject}</div>
                                                    <span style={{ fontSize: "12px" }} onClick={() => {
                                                        setExpanded(!expanded);
                                                        setQbIndex(index);
                                                        setSubjectName(subject);
                                                        setQuestionReview(false);
                                                    }}>View Questions</span>
                                                    <span style={{
                                                        position: "absolute",
                                                        top: "8%",
                                                        left: "80%",
                                                        backgroundColor: "skyblue",
                                                        borderRadius: "50%",
                                                        padding: "2px 8px",
                                                        fontSize: "10px",
                                                        fontWeight: "bold",
                                                        display: subjectCounts[subject] > 0 ? "block" : "none"
                                                    }}>{subjectCounts[subject]}</span>
                                                </>
                                            {/* )} */}
                                        </div>
                                    ))}
                                </div>
                                {expanded && (
                                                <div className={`col-12 pb-4 mb-3 subject-list-card text-center`} >
                                                    <ViewSelectedQuestionsPage
                                                        selectedData={SelectedData}
                                                        subjectName={subjectName}
                                                        review={questionReview}
                                                        onSelectRows={handleSelectRows}
                                                        onExpandable={handlexpanding}
                                                    />
                                                </div>
                                            )}
                                <div className="my-2" style={{ display: rows.length > 0 ? "block" : "none" }}>
                                    <span>Total selected question bank for this <span className="fw-bold fs-6 text-capitalize">{inputValues.examName}</span> exam is <span className="fw-bold fs-6 text-capitalize">{uniqueSubjects.length} Question Bank</span>.</span><br />
                                    <span>Total no of questions for this <span className="fw-bold fs-6 text-capitalize">{inputValues.examName}</span> exam is <span className="fw-bold fs-6 text-capitalize">{rows.length} Questions</span>.</span>
                                </div>

                            </div>
                        </>
                        }
                        </div>
                    </div>

                    {/* QUESTION PAGE */}
                    
                    <div>
                        <div className="text-end fw-bold fs-3 text-capitalize my-3">
                            <button className="btn btn-white me-2" onClick={() => navigate(-1)}>Back</button>
                            <button className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? 'Uploading...' : 'Create'}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}