import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from "../../../Utils/LoadingSpinner";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import apiName from "../../../Utils/BackendApi";

const btnOK = {
    backgroundColor: "#464646",
    textTransform: "none",
    cursor: "pointer",
    color: "white",
    fontSize: "12px",
}

function TableLoadingSpinner() {
    return (
      <div className="custom-loader">
      <Box sx={{ 
      background: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAgCAMAAAAynjhNAAAAq1BMVEVHcExVQPtHYvxGXvtIW/tDZfstjvxAc/tAaftFYftPUfsvhvs4d/tAbvtVRftSSvs4ePs6dvtHYfw/avtTSPtWQ/svhvsjnfwolPtWQ/tURfxVRfsPv/whn/sgovsgoPtNV/shoftWQvtWQvsXs/tVRfsbq/sWs/scqPsVtvwRvfsKyfsMxftTSPsIy/sJy/sGzfsJy/sKyfsJy/sJy/sJx/sUuPsLx/wSuvsxdtzNAAAAOXRSTlMAUSLJ/1sInP/1L17/hmag2rgNPuf//nn/0EK5PvG2mHXd9PvMjsP//w//yv+B/9190byi72HuLN0MjjHTAAABh0lEQVR4AZXQhZKDMBSF4RMaUjRsKRevu3u77/9kG+o+s/8MYx+xi3/FvqFW4lz/hKJsmHqJl6y3ajuOCymYyb1XtH6cil8NiARczuUjikoYVkXFUAtlAFHiwf0JURxHiELnR9OCxE8zVE2eXTCvxXUfjXrDRrWSgmUpgCbnzRO3ajmAoN3uUOf8aT5TJ/go6tahatNdTepBv3AfQqjVlzpE0k97unHmwVA1wiVJlCaBduPB4Il7Esy+8Fi54jS1ACu1ekSaJGk7J56MCx+BKLvc0EuEf+XpeDy+cEBFlki8Gxc+QpbNAC/LEqIZSdjhmedz9cPD1XRI746Vi3tmavRXXixVvWuZ2jz1pR2feLV+Gao1I+/KK6g69+zxxI/i03U2820OMCbLLmuWm6zZ88oM9WELp/LtfD3Cbr7Ecr5DIw5dRMNhhGvLuTKRzzfYDYZ1ke8HdYG7RHGCdjgspvtcrMe/Bzx1WKgT8m1xwHSHN+2KE9Q9NuJZrifM56sDPnaY7PCf/gBYaTKpROM7TwAAAABJRU5ErkJggg==')`,
        backgroundSize: '30px',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
        width: '100%'
     }}>
      <CircularProgress sx={{ width: '50px !important', height: '50px !important' }}/>
    </Box>
    </div>
    );
  }

export default function SelectQuestions(props) {
    const { selectedData, isDisabled } = props;
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const navigate = useNavigate();
    const [selectSubject, SetSelectSubject] = useState();
    const [data,setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedQuestionsCount, setSelectedQuestionsCount] = useState(0);
    const [selectedCheckbox, setSelectedCheckbox] = useState([]);
    const [course, setCourse] = useState([]);
    const selectRef = useRef(null);
    const [pending, setPending] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [expandedRows, setExpandedRows] = useState([]);

    useEffect(()=>{
        axios(`${apiName}api/question/get-all-questionbank/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                setCourse(res.data)
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api1" });
          });
    },[userId])

    useEffect(() => {
        if(selectSubject){
            setPending(true);
        axios(`${apiName}api/question/get-all-questions/${userId}/${selectSubject}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setSelectedCheckbox([]);
                setSelectedRows([...selectedData]);
                setPending(false);
            }
            else {
                setPending(false);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api2" });
            setPending(false);
          });
        }
        else{
            setData([]);
        }
    },[userId,selectSubject,selectedData])

    useEffect(() => {
        const resultList = data.map((item) => {
          return selectedData.some((rowItem) => Number(rowItem.id) === Number(item.id));
        });
        setSelectedCheckbox(resultList);
        setSelectedQuestionsCount(resultList.filter((value) => value === true).length)
      }, [data,selectedData]);


    useEffect(() => {
        setSelectedRows(selectedData);
    },[selectedData])

        // State to track whether all checkboxes are selected or not
        const allCheckboxesChecked = selectedCheckbox.every((isChecked) => isChecked);

        // Function to handle "Select All" action
        const handleSelectAll = () => {
            const updatedData = data.map((item) => ({
                ...item,
                subject: (course.find((subjectItem) => Number(subjectItem.id) === Number(item.questionbank))).name
            }));
            // Filter out rows that are already in selectedRows based on the question property
            const filteredUpdatedData = updatedData.filter((item) =>
                !selectedRows.some((selectedItem) => Number(selectedItem.id) === Number(item.id))
            );
            setSelectedRows([...selectedRows, ...filteredUpdatedData]);
            // setSelectedRows(updatedData);
            setSelectedQuestionsCount(rows.length);
            setSelectedCheckbox(new Array(rows.length).fill(true));
        };
    
        // Function to handle "Deselect All" action
        const handleDeselectAll = () => {
            const filteredSelectedRows = selectedRows.filter((item) => !rows.some((row) => Number(row.id) === Number(item.id)));
            setSelectedRows(filteredSelectedRows);
            setSelectedQuestionsCount(0);
            setSelectedCheckbox(new Array(rows.length).fill(false));
        };
    
        // Function to toggle between "Select All" and "Deselect All" states
        const toggleSelectAll = () => {
            if (allCheckboxesChecked) {
                handleDeselectAll();
            } else {
                handleSelectAll();
            }
        };

    const handleRowSelection = (e, row) => {
        console.log("selectedRowNo: ", row)
        const selectedRowNo = row;
        const isChecked = e.target.checked;
        const selectedCourse = course.find((item) => Number(item.id) === Number(selectSubject));
      
        if (isChecked) {
          // Add the selected row to the array of selected rows if it's not already selected
          if (!selectedRows.includes(selectedRowNo)) {
            setSelectedRows([...selectedRows, {...selectedRowNo, subject: selectedCourse && selectedCourse.name}]);
            setSelectedQuestionsCount(selectedQuestionsCount + 1);
            const indexToUpdate = selectedRowNo.no;
            const updatedSelectedCheckbox = [...selectedCheckbox];
            updatedSelectedCheckbox[indexToUpdate] = true;
            setSelectedCheckbox(updatedSelectedCheckbox);
          }
        } else {
          // Remove the deselected row from the array of selected rows
          setSelectedRows(selectedRows.filter((rowNo) => Number(rowNo.id) !== Number(selectedRowNo.id)));
          setSelectedQuestionsCount(selectedQuestionsCount - 1);
          const indexToUpdate = selectedRowNo.no;
          const updatedSelectedCheckbox = [...selectedCheckbox];
          updatedSelectedCheckbox[indexToUpdate] = false;
          setSelectedCheckbox(updatedSelectedCheckbox);
          // Call the parent component's deselection callback
          if (props.onDeselect) {
            props.onDeselect(row);
          }
        }
      };

      const handleOkClick = () => {
        // Pass selectedRows data to the parent component
        // console.log("-=============================- : ",selectedRows)
        if (props.onSelectRows) {
            props.onSelectRows(selectedRows);
            SetSelectSubject("");
            selectRef.current.value = "";
        }
      };


    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "14px",
                minHeight: '40px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }
        },

    }

    const columns = [
        {
            name: (
                <Button onClick={toggleSelectAll} className={`btn btn-none fw-bold text-dark`}>
                    {allCheckboxesChecked ? 
                    <Tooltip title="Deselect all" arrow>
                       <input
                     type="checkbox"
                     className="fs-1"
                     style={{width: '18px', height: '18px'}}
                     defaultChecked={true}
                     checked={true}
                    />
                    </Tooltip>
                    : 
                    <Tooltip title="Select all" arrow >
                    <input
                     type="checkbox"
                     className="fs-1"
                     style={{width: '18px', height: '18px'}}
                     defaultChecked={false}
                     checked={false}
                    />
                    </Tooltip>
                    }
                    
                </Button>
            ),
            selector: row => row.select,
            center: "true",
            width: '50px'
        },
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            // center: "true",
            width: '80px'
        },
        {
            name: 'Questions',
            selector: row => row.question,
            grow: 2,
            // center: "true",
        },
        // {
        //     name: 'Options',
        //     cell: (row) => {
        //       const optionsArray = row.options;
        //       const formattedOptions = optionsArray.map((option, index) => {
        //         const isCorrectAnswer = (row.answer === 'option_a' ? '1' : row.answer === 'option_b' ? '2' : row.answer === 'option_c' ? '3' : row.answer === 'option_d' ? '4' : null ) === `${index + 1}`;
        //         const textColor = isCorrectAnswer ? 'green' : 'black';
        //         return `<span style="color: ${textColor}; line-height: 1.5" className='${isCorrectAnswer && 'fw-bold'}'>${index + 1}: ${option}</span>`;
        //       }).join(',<br/>');
        //       return <div style={{textAlign:"center", padding:"10px 0px"}} dangerouslySetInnerHTML={{ __html: formattedOptions }} />;
        //     },
        //     grow: 3,
        //     center: true,
        //     format: (row) => row.options,
        // },
        {
            name: 'Answer',
            selector: row => row.answer,
            grow: 1,
            center: "true",
        }
    ];

    const filteredData = data && data !== "No data available" && data.filter(item => {
        return (String(item.question).toLowerCase().includes(searchQuery.toLowerCase()));
    });

    const rows = filteredData
    ? filteredData.map((item, index) => {
        return {
            id: item.id,
            no: index + 1,
            question: item.question,
            options: [item.option_a, item.option_b, item.option_c, item.option_d],
            answer: <span style={{color:"green", fontWeight:"bold"}}>
                {
                    item.correct_option === 'option_a' ? item.option_a :
                    item.correct_option === 'option_b' ? item.option_b :
                    item.correct_option === 'option_c' ? item.option_c :
                    item.correct_option === 'option_d' ? item.option_d : ''
                }</span>,
            dropanswer: 
                (
                    item.correct_option === 'option_a' ? 0 :
                    item.correct_option === 'option_b' ? 1 :
                    item.correct_option === 'option_c' ? 2 :
                    item.correct_option === 'option_d' ? 3 : ''
                ),
            select: (
            <input
                type="checkbox"
                name="singleCheckbox"
                // value={item.no}
                className="fs-1"
                style={{width: '18px', height: '18px'}}
                checked={selectedCheckbox[index] || null}
                onChange={(e) => handleRowSelection(e, {...item, no:index})}
            />
            ),
        };
    })
    : '';

   
    
    const ExpandedComponent = ({ data }) => {
        return (
          <div style={{ padding:"18px 0px 4px"}}>
            <p><span className="fw-bold">Question: </span>{data.question}</p>
            <p>
            <span className="fw-bold">Options: </span>
            {data.options.map((item, index) => (
                <span key={index} style={{color: index === data.dropanswer ? 'green' : 'black', fontWeight:  index === data.dropanswer && "bold"}}>{`${String.fromCharCode(65 + index)}) ${item}`} {item.length > 25 && <br/>}</span> 
            ))}
            </p>
            {/* <p><span className="fw-bold">Answer: </span>{data.answer}</p> */}
          </div>
        );
      };

      const conditionalRowStyles = [
        {
          when: row => row.expandableRows,
          style: {
            display:"none"
          },
        },
      ];

      const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };


    return (
        <div>
                <div className="form-group row my-3" style={{display: isDisabled ? 'none' : '' }}>
                        <label htmlFor="exam-qb" className="col-sm-3 col-form-label text-center">Question Bank:</label>
                        <div className="col-sm-9">
                            <select id="exam-qb" name="answer" className="form-control py-1 exam-input"  ref={selectRef}
                            onClick={(e)=>{ 
                                SetSelectSubject(e.target.value);
                                // setSelectedQuestionsCount(0);
                            }}>
                            <option value="">Select Question bank</option>
                            {course.map((option, index) => (
                                <option key={index} value={option.id}>
                                {option.name}
                                </option>
                            ))}
                            </select>
                        </div>
                </div>
                {selectSubject &&
                <div className="my-3">
                <div className="">
                <p className="my-2">Selected Questions: {selectedQuestionsCount}</p>
                <div className="text-end mb-2">
                    <input type="text" className="form-control py-1 exam-input" placeholder="search by question name" onKeyDown={handleKeyDown} onChange={(e) => setSearchQuery(e.target.value) }/>
                </div>
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeader
                            fixedHeaderScrollHeight={'600px'}
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHove
                            expandableRows 
                            progressPending={pending}
			                progressComponent={<TableLoadingSpinner/>}
                            expandableRowsComponent={ExpandedComponent}
                            onRowExpandToggled={(toggleState, row) => {
                                setExpandedRows(toggleState ? [row.id] : []);
                              }}
                            expandableRowExpanded={(row) => expandedRows.includes(row.id)}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                        <div className="text-end">
                            <button  
                                onClick={() => {
                                    SetSelectSubject("")
                                    selectRef.current.value = "";
                                }} 
                                className={`${btnOK} btn fw-bold text-dark me-2`}>Cancel</button>
                            <button onClick={handleOkClick} className={`btn btn-custom`}>Save</button>
                        </div>
                    </div>
                </div>
                }
        </div>
    );
}