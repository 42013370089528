import Sidebar from '../../Sidebar/Sidebar';
import LeftSideBar from '../../Sidebar/Left_SideBar';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { saveAs } from 'file-saver';
import LoadingSpinner from '../../../Utils/LoadingSpinner';
import ExcelJS from 'exceljs';

import Switch from '@mui/material/Switch';
import { useRef } from 'react';
import apiName from '../../../Utils/BackendApi';

export default function StudentInExam() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [QBSwitch, setQBSwitch] = useState(false);
    const [loader, setLoader] = useState(false);

    const { id } = useParams();
    const { name } = useParams();

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;

    const [searchInput, setSearchInput] = useState({
        name: "",
        email: "",
        percentage: ""
    })
    const [filter, setFilter] = useState(false);

    const nullname = 'n/a';


    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])

    useEffect(() => {
        // ${apiName}api/result/get-all-results/admin:id/exam:id
        axios.get(`${apiName}api/result/get-all-results/${userId}/${id}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setLoader(true);
            }
            else {
                setLoader(true)
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });

    }, [id, userId])

    const handleQBSwitch = () => {
        setQBSwitch((prevOpen) => !prevOpen)
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearchInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const filteredData = data && data.filter(item => {
        return (String(item.student_data.username).toLowerCase().includes(searchInput.name.toLowerCase()) &&
            String(item.student_data.email).toLowerCase().includes(searchInput.email.toLowerCase()) &&
            String(Number(item.final_result !== "No Result" && (item.final_result.final_score / item.final_result.total_questions_in_exam) * 100)).toLowerCase().includes(String(searchInput.percentage)))
    });


    const customStyles = {
        headRow: {
            style: {
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                // whiteSpace: 'pre-wrap',
                // wordBreak: 'break-all',
                fontSize: '15px'
            },
        },
        rows: {
            style: {
                backgroundColor: "#fff",
                minHeight: '40px',
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                // whiteSpace: 'pre-wrap',
                // wordBreak: 'break-all',
                fontSize: '15px'
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            width: '80px'
        },
        {
            name: 'Admission',
            selector: row => row.admission,
        },
        {
            name: 'Name',
            selector: row => row.username,
            grow: 1,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2,
        },

        QBSwitch && {
            name: 'Question Bank',
            cell: (row) => {
                const optionsArray = row.question_bank_result;
                const formattedOptions = optionsArray.map((option, index) => {
                    return `
                    <div>
                        ${option.question_bank_name} mark is ${option.score_in_question_bank} out of ${option.total_no_of_question_from_question_bank_in_exam}
                    </div>
                `;
                }).join(',<br/>');
                return optionsArray.length > 0 ? <div dangerouslySetInnerHTML={{ __html: formattedOptions }} /> : "N/A";
            },
            grow: 3,
            format: (row) => row.options,
        },

        {
            name: 'Total Mark',
            selector: row => row.totalMark,
            center: 'true',
            sortable: true,
        },

        {
            name: 'Result',
            selector: row => row.results,
            center: 'true',
            sortable: true,
        },
        {
            name: 'Percentage',
            selector: row => row.percentage,
            center: 'true',
            sortable: true,
        }
    ];

    const rows = filteredData
        ? filteredData.map((item, index) => {
            return {
                no: index + 1,
                id: item.student_data.id,
                admission: item.student_data.admission_number,
                username: item.student_data.username,
                email: item.student_data.email,
                totalQuestions: item.final_result !== "No Result" ? item.final_result.total_questions_in_exam : nullname,
                totalMark: item.final_result !== "No Result" ? item.final_result.total_mark_in_exam : nullname,
                attempted: item.final_result !== "No Result" ? item.final_result.total_attempted_questions : nullname,
                corrected: item.final_result !== "No Result" ? item.final_result.total_corrected_answer : nullname,
                skipped: item.final_result !== "No Result" ? item.final_result.total_skipped_question : nullname,
                results: item.final_result !== "No Result" ? `${item.final_result.final_score}` : nullname,
                percentage: item.final_result !== "No Result" ? ((item.final_result.final_score / item.final_result.total_questions_in_exam) * 100).toFixed(2) + ' %' : nullname,
                // QB
                question_bank_result: item.question_bank_result
            };
        })
        : '';


    const excelFilterData = data;

    const exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Result');

        // Function to add bold formatting to a cell
        const addBoldFormatting = (cell) => {
            cell.font = { bold: true };
        };

        const centerText = (cell) => {
            cell.alignment = { horizontal: 'middle', vertical: 'middle'  };
        }

        const addNullNameFormatting = (cell) => {
            if (cell.value === nullname) {
                cell.alignment = { horizontal: 'right', vertical: 'middle' };
            }
        };

        worksheet.getColumn(1).width = 5; // Adjust column width for the 'No' column
        worksheet.getColumn(2).width = 20; // Adjust column width for the 'Name' column
        worksheet.getColumn(3).width = 20; // Adjust column width for the 'Email' column
        worksheet.getColumn(4).width = 15; // Adjust column width for the 'Phone' column

        


        const examDetailsRow = worksheet.addRow(['Exam Details']);
        examDetailsRow.eachCell({ includeEmpty: true }, addBoldFormatting);

        // const examHeaderRow = worksheet.addRow(['', 'Exam Name', 'Date', 'Start Time', 'End Time']);
        // examHeaderRow.eachCell({ includeEmpty: true }, addBoldFormatting);

        const examDetails1 = worksheet.addRow(['', 'Exam Name', data[0].exam_data.exam_name]);
        const examDetails2 = worksheet.addRow(['', 'Date', data[0].exam_data.start_date]);
        const examDetails3 = worksheet.addRow(['', 'Start Time', data[0].exam_data.start_time]);
        const examDetails4 = worksheet.addRow(['', 'End Time', data[0].exam_data.end_time]);

        examDetails1.eachCell({ includeEmpty: true }, addBoldFormatting);
        examDetails2.eachCell({ includeEmpty: true }, addBoldFormatting);
        examDetails3.eachCell({ includeEmpty: true }, addBoldFormatting);
        examDetails4.eachCell({ includeEmpty: true }, addBoldFormatting);

        

        // worksheet.addRow(['', data[0].exam_data.exam_name, data[0].exam_data.start_date, data[0].exam_data.start_time, data[0].exam_data.end_time]);
        worksheet.addRow([]);

        const studentDetailsRow = worksheet.addRow(['Student Details']);
        studentDetailsRow.eachCell({ includeEmpty: true }, addBoldFormatting);

        const headerRow = worksheet.addRow([
            'No',
            'Name',
            'Email',
            'Phone',
            ...excelFilterData[0].question_bank_result.map((item) => {
                return `${item.question_bank_name} Question Bank `;
            }),
            'Total number of questions',
            'Total mark',
            'Attempted questions',
            'Corrected answer',
            'Skipped question',
            'Result'
        ]);

        headerRow.eachCell({ includeEmpty: true }, (cell) => {
            addBoldFormatting(cell);
            centerText(cell);
        });

        const nullArray = []
        for (let index = 0; index < excelFilterData[0].question_bank_result.length; index++) {
            nullArray.push(nullname)
        }

        data.forEach((item, index) => {
            const row = worksheet.addRow([
                index + 1,
                item.student_data.username,
                item.student_data.email,
                Number(item.student_data.phone),
                ...item.question_bank_result.length > 0 ? item.question_bank_result.map((item) => {
                    return `${item.score_in_question_bank} out of ${item.total_no_of_question_from_question_bank_in_exam}`;
                }) : nullArray,
                item.final_result !== "No Result" ? item.final_result.total_questions_in_exam : nullname,
                item.final_result !== "No Result" ? item.final_result.total_mark_in_exam : nullname,
                item.final_result !== "No Result" ? item.final_result.total_attempted_questions : nullname,
                item.final_result !== "No Result" ? item.final_result.total_corrected_answer : nullname,
                item.final_result !== "No Result" ? item.final_result.total_skipped_question : nullname,
                item.final_result !== "No Result" ? item.final_result.final_score : nullname,
            ]);

            // Apply special formatting to cells containing 'nullname'
            // row.eachCell({ includeEmpty: true });
        });


        // Create a buffer and save it as a file
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer]), `${name} result.xlsx`);
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
                <div className="main-content">
                    <div className="mx-sm-5 my-2">
                        <div className="row">
                            <div className="col-sm-8 text-start fw-bold fs-3 text-capitalize">
                                Students in the Exam of {name}
                            </div>
                            <div className="col-sm-4 text-end">
                                <button onClick={exportToExcel} className="my-2 btn btn-custom" style={{ cursor: "pointer" }}>Download Result</button>
                            </div>
                        </div>

                        <div className="mt-4">

                            <span className='search-label'>Filter 
                            <Switch 
                                onClick={() => {
                                    setFilter((preOpen) => !preOpen)
                                    if(filter === true){
                                        setSearchInput({ name: "", email: "", percentage: "" })
                                    }
                                }} 
                                checked={filter} 
                                size="small" 
                                color="secondary" 
                            />
                            </span>
                            <form className='my-2' style={{ display: filter ? "block" : "none" }}>
                                <div className="form-row row search-box">
                                    <div className="form-group col-md-4">
                                        <label htmlFor="student-name" className='search-label'>Name:</label>
                                        <input type="text" name='name' className="form-control search-input" onKeyDown={handleKeyDown} id="student-name" value={searchInput.name} onChange={handleChange} placeholder="Name" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="student-email" className='search-label'>Email:</label>
                                        <input type="text" name='email' className="form-control search-input" onKeyDown={handleKeyDown} id="student-email" value={searchInput.email} onChange={handleChange} placeholder="Email" />
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="student-percentage" className='search-label'>Percentage:</label>
                                        <input type="number" name='percentage' className="form-control search-input" onKeyDown={handleKeyDown} id="student-percentage" value={searchInput.percentage} onChange={handleChange} placeholder="percentage" />
                                    </div>
                                    <div className='text-end my-2'>
                                        <span className='search-label' style={{ cursor: "pointer" }} onClick={() => { setSearchInput({ name: "", email: "", percentage: "" }) }}>Clear filter</span>
                                    </div>

                                </div>
                            </form>


                            <span className='mt-2' style={{ display: 'flex', justifyContent: "start", alignItems: 'center' }}>
                                Show Question Bank: <Switch onClick={handleQBSwitch} checked={QBSwitch} size="small" color="secondary" />
                            </span>
                            <DataTable
                                columns={columns}
                                data={rows}
                                fixedHeaderScrollHeight="600px"
                                customStyles={customStyles}
                                persistTableHead
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 25, 50]}
                                highlightOnHover
                            />
                        </div>
                    </div>
                </div>
                :
                <LoadingSpinner />}
        </div>
    )
}