import React, { useState, useEffect } from "react";
import '../../Login/login.css'
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import bg from '../../../assets/Candidate assets/Candidate-login.svg'
import Forgotpassword from "../../Login/Forgot_password";
import LoadingSpinner from '../../Utils/LoadingSpinner'
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import apiName from "../../Utils/BackendApi";


function CandidateLogin() {
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateEmail = () => {
        // Regular expression for email validation
        const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        setEmailError(!emailPattern.test(formData.email));
    };

    const validatePassword = () => {
        // Password validation logic (e.g., minimum length)
        const passwordPattern = /.{6,}/; // Minimum 6 characters
        setPasswordError(!passwordPattern.test(formData.password));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate email and password inputs
        validateEmail();
        validatePassword();

        // If both email and password are valid, you can perform other actions here
        if (!emailError && !passwordError) {
            axios.post(`${apiName}api/login`, {
                email: formData.email,
                password: formData.password,
            })
                .then(res => {
                    if (res.data !== "Invalid email or password!") {
                        if(res.data[0].role !==  "Admin"){
                            localStorage.setItem("mcq-candidates", JSON.stringify(res.data[0]));
                            navigate('/candidate-dashboard');
                        }
                        else{
                            toast.error('Invalid Email or Password!', {
                                toastId: 'errvol3',
                            });
                        }
                    }
                    else {
                        toast.error('Invalid Email or Password!', {
                            toastId: 'errvol2',
                        });
                    }
                })
        }
    };

    const [passtype, setPassType] = useState('password');

    useEffect(() => {
        setLoader(false);
    }, [])


    //password hige & seek toggle
    const handleToggle = () => {
        passtype === 'password' ? setPassType('text') : setPassType('password')
    }

    const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent the default context menu
    };

    return (
        <section onContextMenu={handleContextMenu}>
            {loader ? <LoadingSpinner /> :
                <div className="login-page">
                    <div className="login-card">
                        <div className="login-box row">
                            <div className="left-side mobile-view-photo">
                                <div className="left-contant">
                                    <img src={bg} width={"200px"} height={"200px"} alt="login image" />
                                </div>
                            </div>
                            <div className="right-side">
                                <div className="right-contant" >
                                    <div className="py-5 text-center">
                                        <h5 className="mb-3">Login</h5>
                                        <form onSubmit={handleSubmit}>
                                            <div className="form-group my-3">
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="outlined-email"
                                                    label={formData.email && "E-mail"}
                                                    placeholder="E-mail"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInput}
                                                    error={emailError}
                                                    helperText={emailError ? 'Invalid email address' : ''}
                                                    size="small"
                                                />
                                            </div>
                                            <div className="form-group my-3" style={{ position: "relative" }}>
                                                <TextField
                                                    sx={{ width: '100%' }}
                                                    id="outlined-password"
                                                    label={formData.password && "Password"}
                                                    placeholder="Password"
                                                    name="password"
                                                    type={passtype}
                                                    value={formData.password}
                                                    onChange={handleInput}
                                                    error={passwordError}
                                                    helperText={passwordError ? 'Password must be at least 6 characters long' : ''}
                                                    size="small"
                                                />
                                                <i className={passtype === 'password' ? "bi bi-eye-slash-fill" : "bi bi-eye-fill"}
                                                    id="input-password-eye"
                                                    onClick={handleToggle}
                                                ></i>
                                            </div>
                                            <div className="form-group sgn-from-group">
                                                <div className="text-center">
                                                    <button type="submit"
                                                        className="btn sign-btn"
                                                    >Login</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </section>
    );

} export default CandidateLogin;