import Sidebar from '../../Sidebar/Sidebar';
import LeftSideBar from '../../Sidebar/Left_SideBar';
import DataTable from 'react-data-table-component';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import LoadingSpinner from '../../../Utils/LoadingSpinner';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SlideshowIcon from '@mui/icons-material/Slideshow';

import resultHello from '../../../../assets/Questions/result-hello.gif'
import { useRef } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import apiName from '../../../Utils/BackendApi';

export default function ViewRecord() {
    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;
    const userName = user && JSON.parse(user).username;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const [searchInput, setSearchInput] = useState({
        exam: "",
        date: "",
        status: ""
    })
    const [filter, setFilter] = useState(false);

    const [data, setData] = useState([]);
    const [flagToUpdate, setFlagToUpdated] = useState([]);

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
    }, [])

    useEffect(() => {
        axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
            if (res.data !== 'No data found') {
                const published = res.data.filter(item => Number(item.status) === 4);
                setData(published);
                setLoader(true);
                const today = dayjs();
                const updatedList = published.reduce((accumulator, item) => {
                    const parsedTargetDate = dayjs(item.start_date, { format: 'DD-MM-YYYY' });
                    const isSameDate = parsedTargetDate.isSame(today, 'day');

                    if (isSameDate) {
                        accumulator.push(item);
                    }

                    return accumulator;
                }, []);
                setFlagToUpdated(updatedList);
            }
            else {
                setLoader(true);
            }
        })
            .catch((e) => {
                toast.error(e.message, { toastId: "err-api" });
            });
    }, [userId])

    useEffect(() => {
        const intervalIds = flagToUpdate.map((item) => {
            const endDateTime = new Date(`${item.start_date}T${item.end_time}`);

            const shouldCheck = () => {
                const currentDateTime = new Date();
                return currentDateTime >= endDateTime;
            };

            const autoupdate = () => {
                console.log(`data update on ${item.start_date} at ${item.end_time}!`);
                axios(`${apiName}api/exam/get-all-exams/${userId}`).then((res) => {
                    if (res.data !== 'No data found') {
                        const published = res.data.filter(item => Number(item.status) === 4);
                        setData(published);
                    }
                })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            };

            const intervalId = setInterval(() => {
                if (shouldCheck()) {
                    autoupdate();
                    clearInterval(intervalId);
                }
            }, 60000);

            return intervalId;
        });

        return () => {
            intervalIds.forEach((intervalId) => clearInterval(intervalId));
        };
    }, [flagToUpdate]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSearchInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDateAccept = () => {
        // Handle any logic you want when the user clicks "OK" button
        // This is where you can perform additional actions if needed
        console.log('OK button clicked!');
    };

    const filteredData = data && data.filter(item => {
        return (String(item.exam_name).toLowerCase().includes(searchInput.exam.toLowerCase()) &&
            String(item.start_date).toLowerCase().includes(searchInput.date.toLowerCase()) &&
            String(item.status).toLowerCase().includes(searchInput.status.toLowerCase()));
    });

    const handleRowClick = (row) => {
        navigate(`/exam-records/exam-student-records/${row.id}/${row.exam_name}`)
    };

    const customStyles = {
        headRow: {
            style: {
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            },
        },
        rows: {
            style: {
                backgroundColor: "#fff",
                minHeight: '40px',
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            width: '100px'
        },
        {
            name: 'Exam Title',
            selector: row => row.exam_name,
            grow: 3,
        },
        {
            name: 'Date',
            selector: row => row.start_date,
            center: 'true',
        },
        {
            name: 'Time',
            selector: row => row.time,
            center: 'true',
            grow: 1,
        },
        // {
        //     name: 'End Time',
        //     selector: row => row.end_time,
        //     center: true
        // },
        {
            name: 'Status',
            selector: row => row.status,
            center: 'true',
        },
        {
            name: 'View',
            selector: row => row.viewQuestions,
            center: 'true',
            width: '100px'
        },
    ];

    const rows = filteredData ? filteredData.map((item, index) => {
        return {
            no: index + 1,
            id: item.id,
            exam_name: item.exam_name,
            start_date: dayjs(item.start_date).format('DD/MM/YYYY'),
            time: `${dayjs(item.start_time, "HH:mm").format('h:mm A')} to ${dayjs(item.end_time, "HH:mm").format('h:mm A')}`,
            start_time: dayjs(item.start_time, "HH:mm").format('h:mm A'),
            end_time: dayjs(item.end_time, "HH:mm").format('h:mm A'),
            status: Number(item.status) === 4 ? <span className='status-green'>Completed</span> : Number(item.status) === 3 ? <span className='status-red'>Expired</span> : Number(item.status) === 2 ? <span className='status-green'>Published</span> : <span className='status-orange'>Draft</span>,
            enable_negative_mark: item.enable_negative_mark,
            description: item.description,
            question_bank: item.question_bank,
            // viewQuestions: <button className="btn btn-none" style={{border:"none"}} onClick={()=>{navigate(`/exam-records/exam-student-records/${item.id}/${item.exam_name}`)}}><i className="bi bi-pencil-square"></i></button>,
            viewQuestions: (
                <div>
                    <IconButton
                        size="small"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => { navigate(`/exam-records/exam-student-records/${item.id}/${item.exam_name}`) }}
                        sx={{ color: 'rgb(116, 103, 239)' }} >
                        <Tooltip title="View" arrow>
                            <SlideshowIcon />
                        </Tooltip>
                    </IconButton>
                </div>
            )
        }
    }) : '';

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ?
                <div className="main-content">
                    <div className="mx-sm-5 my-2">
                        <div className="row">
                            <div className="col-9 text-start fw-bold fs-3 text-capitalize">
                                Record of Examination
                            </div>
                            {/* <div className="col-3 text-end">
                            <button className="btn btn-primary" onClick={()=>navigate('/create-exam')}>Create a Exam</button>
                        </div> */}
                        </div>
                        <div className="mt-4">
                            {data.length > 0 ?
                                <div>
                                    <span className='search-label'>Filter
                                        <Switch
                                            onClick={() => {
                                                setFilter((preOpen) => !preOpen)
                                                if (filter === true) {
                                                    setSearchInput({ exam: "", date: "", status: "" })
                                                }
                                            }}
                                            checked={filter}
                                            size="small"
                                            color="secondary"
                                        />
                                    </span>
                                    <form className='my-2' style={{ display: filter ? "block" : "none" }}>
                                        <div className="form-row row search-box">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="exam-name" className='search-label'>Name:</label>
                                                <input type="text" name='exam' className="form-control search-input" onKeyDown={handleKeyDown} id="exam-name" value={searchInput.exam} onChange={handleChange} placeholder="Name" />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="exam-date" className='search-label'>Date:</label>
                                                {/* <input type="date" name='date' className="form-control search-input" id="exam-date" onKeyDown={handleKeyDown} value={searchInput.date} onChange={handleChange} placeholder="Date"/> */}
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <MobileDatePicker
                                                        className="form-control search-input"
                                                        value={searchInput.date ? dayjs(searchInput.date) : null}
                                                        onAccept={(newValue) =>
                                                            setSearchInput((prevState) => ({
                                                                ...prevState,
                                                                date: dayjs(newValue).format('YYYY-MM-DD')
                                                            }))
                                                        }
                                                        format="DD/MM/YYYY"
                                                        sx={{
                                                            border: 'none',
                                                            outline: 'none',
                                                            borderRadius: '5px',
                                                            fontSize: "14px",
                                                            '& input': {
                                                                border: '2px solid rgb(116, 103, 239)',
                                                                borderRadius: '5px',
                                                                outline: 'none',
                                                                fontSize: "14px",
                                                                padding: '8px 14px',
                                                                '&:focus': {
                                                                    borderBottom: '2px solid rgb(116, 103, 239)',
                                                                },
                                                                '&:active': {
                                                                    borderBottom: '2px solid rgb(116, 103, 239)',
                                                                },
                                                                '&::placeholder': {
                                                                    verticalAlign: 'bottom',
                                                                    fontSize: "14px",
                                                                },
                                                            },
                                                            '& fieldset': {
                                                                border: 'none',
                                                                // borderBottom: '2px solid rgb(116, 103, 239)',
                                                                borderRadius: '8px',
                                                                fontSize: "14px",
                                                                padding: '8px 14px'
                                                            }
                                                        }}
                                                        slotProps={{ textField: { size: 'small' } }}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                            {/* <div className="form-group col-md-4">
                            <label htmlFor="exam-status" className='search-label'>Status:</label>
                            <select id="exam-status" name="status" onChange={handleChange} className="form-control search-input" onKeyDown={handleKeyDown} value={searchInput.status}>
                                <option value="">Status</option>
                                <option value="1">Draft</option>
                                <option value="2">Published</option>
                                <option value="3">Expired</option>
                                <option value="4">Completed</option>
                            </select>
                            </div> */}
                                            <div className='text-end my-2'>
                                                <span
                                                    className='search-label'
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSearchInput({ exam: "", date: "", status: "" })
                                                    }}
                                                >
                                                    Clear filter
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                    <DataTable
                                        columns={columns}
                                        data={rows}
                                        fixedHeaderScrollHeight="600px"
                                        customStyles={customStyles}
                                        persistTableHead
                                        pagination
                                        paginationPerPage={10}
                                        paginationRowsPerPageOptions={[10, 25, 50]}
                                        highlightOnHover
                                        pointerOnHover
                                        onRowClicked={handleRowClick}
                                    />
                                </div>
                                :
                                <div style={{ textAlign: "center" }}>
                                    <div className='my-2' style={{ margin: "0px auto" }}>
                                        <div className='question-bank-box-card'>
                                            <div>
                                                <img src={resultHello} alt="welcome-gif" width={250} height={200} style={{ marginTop: "20px", borderRadius: "500px" }} />
                                            </div>
                                            <div style={{ padding: '40px 10px 30px', color: 'indigo', textAlign: "center" }}>
                                                <p style={{ fontSize: "16px", fontWeight: "bold" }}>Hi {userName} welcome to Exam Result Page</p>
                                                <p style={{ marginTop: "20px", fontSize: "16px", fontWeight: "bold" }}>We haven't completed any exams yet!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                <LoadingSpinner />}
        </div>
    )
}