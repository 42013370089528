import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import LogoutPopup from "./Logout";
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import AdminProfile from './Profile';
import logo from '../../../assets/Exam logo.png'

let drawerWidth

const openedMixin = (theme) => ({
    width: drawerWidth,
    backgroundColor: 'rgba(34, 42, 69, 0.96)',
    color: "white",
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
    backgroundColor: 'rgba(34, 42, 69, 0.96)',
    color: "white",
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


export default function LeftSideBar({ sidebarFlag }) {
    const theme = useTheme();

    const user = localStorage.getItem("mcq-user");
    const userName = user && JSON.parse(user).username //username;

    const initialSidebarState = localStorage.getItem("sidebarState");

    const [open, setOpen] = React.useState(true);
    const [openSidebar, setOpenSidebar] = React.useState(true);
    const [sidebarSwitch, setSidebarSwitch] = React.useState(initialSidebarState === "false");
    const [logoutIsOpen, setLogoutIsOpen] = React.useState(false);
    const [profileIsOpen, setProfileIsOpen] = React.useState(false);
    const [sidebarDisable, setSidebarDisable] = React.useState(false);

    const location = useLocation();

    React.useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth > 500) {
            drawerWidth = 260;
            setSidebarDisable(false);
            // setOpen(true);
          } else {
            drawerWidth = 'auto';
            setOpen(false);
            setSidebarDisable(true);
          }
        };
    
        // Initial call
        handleResize();
    
        // Event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup the event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    React.useEffect(() => {
        const particularPages = [
            '/exam-list/create-exam',
            '/view-exam/:examId',
            '/question-list/:id/:name',
            '/add-question/:id/:name',
            '/edit-question/:id/:name/:qid',
            '/exam-student-records/:id/:name',
        ];

        // Check if the current page is one of the particular pages
        const isOnParticularPage = particularPages.indexOf(location.pathname) === -1;

        // If on the particular page, open the sidebar
        if (isOnParticularPage && sidebarSwitch) {
            setOpenSidebar(false);
        }
    }, [location.pathname]);

    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        // zIndex: theme.zIndex.drawer + 1,
        width: "100%",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${openSidebar ? drawerWidth : 80}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,

            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );


    const handleDrawerOpen = () => {
        if (sidebarSwitch) {
            setOpen(true);
            setSidebarSwitch(false);
            localStorage.setItem("sidebarState", "true");
        }
        else {
            setOpen((prevOpen) => !prevOpen);
        }

        setOpenSidebar(true);
    };

    const handleDrawerClose = () => {

        if(sidebarDisable){
            setOpen(false)
        }
        else{
            if (sidebarSwitch) {
                setOpenSidebar(true);
                localStorage.setItem("sidebarState", "true");
            }
            else {
                setOpenSidebar((prevOpen) => !prevOpen);
                localStorage.setItem("sidebarState", "false");
            }
            setSidebarSwitch((prevOpen) => !prevOpen);
        }
    };

    const handleSidebarMouseEnter = () => {
        if (sidebarSwitch) {
            setOpenSidebar(true);
        }
    };

    const handleSidebarMouseLeave = () => {
        if (sidebarSwitch) {
            setOpenSidebar(false);
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    const link = [
        { name: 'Dashboard', icon: 'bi bi-speedometer2', link: '/admin-dashboard' },
        { name: 'Examination', icon: 'bi bi-card-text', link: '/exam-list' },
        { name: 'Question Bank', icon: 'bi bi-clipboard2', link: '/question-bank' },
        { name: 'Exam Records ', icon: 'bi bi-postcard', link: '/exam-records' },
        { name: 'Student List', icon: 'bi bi-people', link: '/list-of-all-students' }
    ]

    return (
        <Box>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{ height: "48px", display: 'flex', justifyContent: 'center', backgroundColor: "white", color: "black" }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                        >
                            <MenuIcon />
                        </IconButton>
                    </div>
                    <div style={{ display: 'flex', justifyContent: "end", alignItems: 'center' }}>
                        <Typography variant="h6" noWrap component="div" sx={{ textAlign: "end", fontSize: "14px" }}>
                            Hi <strong>{userName}</strong>
                        </Typography>
                        <div style={{ position: "relative" }}>
                            <IconButton
                                size="small"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                 sx={{
                                    position: 'absolute',
                                    top: '5%',
                                    left: '90%',
                                    '@media screen and (max-width: 1100px)': {
                                      left: '90%',
                                    },
                                    '@media screen and (max-width: 900px)': {
                                      left: '80%',
                                    },
                                    '@media screen and (max-width: 700px)': {
                                        left: '75%',
                                    },
                                    '@media screen and (max-width: 500px)': {
                                        left: '65%',
                                    },
                                      
                                  }}
                                className={'account-menu'}
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                getContentAnchorEl={null}
                            >
                                <MenuItem onClick={() => setProfileIsOpen(true)}>Profile</MenuItem>
                                <MenuItem onClick={() => setLogoutIsOpen(true)}>Logout</MenuItem>
                            </Menu>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                open={openSidebar}
                sx={{
                    display: open ? "block" : "none",
                    color: 'rgb(255, 255, 255)',
                    transition: 'all 250ms ease-in-out 0s',
                    backgroundColor: 'darkblue'
                }}
                ModalProps={{ keepMounted: false }}
                onMouseEnter={handleSidebarMouseEnter}
                onMouseLeave={handleSidebarMouseLeave}
            >
                <DrawerHeader sx={{ display: "flex", justifyContent: "space-between" }}>
                    <ListItem key={"LOGO"} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            to={'/admin-dashboard'}
                            sx={{
                                minHeight: 48,
                                justifyContent: openSidebar ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: openSidebar ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <div className="sidebar-header-title">
                                    <img src={logo} alt="name logo" width="30px" height="30px" style={{ willChange: 'width, height', width: '30px' }} />
                                </div >
                            </ListItemIcon>
                        </ListItemButton>
                    </ListItem>
                    {sidebarDisable ?
                        <IconButton onClick={handleDrawerClose}>
                            < KeyboardArrowLeftIcon color="warning" />
                        </IconButton>
                            :
                            (openSidebar &&
                        <IconButton onClick={handleDrawerClose}>
                            <Switch  checked={sidebarSwitch} size="small" color='warning' />
                        </IconButton>)}
                </DrawerHeader>
                <Divider />
                <List>
                    {link.map((text, index) => (
                        <ListItem key={text.name}>
                            <ListItemText
                                primary={
                                    <NavLink
                                        to={text.link}
                                        className={({ isActive }) =>
                                            isActive
                                                ? 'active-link active-link-name'
                                                : 'nav-link navlink-names'
                                        }
                                        style={{
                                            padding: openSidebar ? '3px 10px' : '3px 6px',
                                            justifyContent: openSidebar ? '' : 'center'
                                        }}
                                    >
                                        <i className={`${text.icon}`} style={{ fontSize: '20px' }}></i>
                                        <span
                                            style={{
                                                display: openSidebar ? 'block' : 'none',
                                                marginLeft: '8px',
                                            }}
                                        >
                                            {text.name}
                                        </span>
                                    </NavLink>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <LogoutPopup isOpen={logoutIsOpen} onClose={() => { setLogoutIsOpen(false) }} />
            <AdminProfile isOpen={profileIsOpen} onClose={() => { setProfileIsOpen(false) }} closeMenu={handleClose}/>
        </Box>
    );
}