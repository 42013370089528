import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { toast } from 'react-toastify';
import styled from 'styled-components';
import apiName from "../../../../Utils/BackendApi";

const StyledDialog = styled(Dialog)`
  .custom-dialog-paper {
    background: linear-gradient(90deg, rgb(253, 240, 255) -19.83%, rgb(222, 255, 255));
    border-radius: 20px;
  }
`;

const btnOK = {
  backgroundColor: "#464646",
  textTransform: "none",
  cursor: "pointer",
  color: "white",
  fontSize: "12px",
}

export default function EditQuestionPopup(props) {
  const { selectQuestionBankId, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const user = localStorage.getItem("mcq-user");
  const userId = user && JSON.parse(user).id;

  const [inputs, setInputs] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setErrorsMessage('');
    setInputs('');
  };

  useEffect(() => {
    setOpen(isOpen);
    setInputs(selectQuestionBankId && selectQuestionBankId.name);
  }, [isOpen,selectQuestionBankId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputs && String(inputs).trim()) {
      setIsSubmitting(true);
        axios
          .put(`${apiName}api/question/update-questionbank/${userId}/${selectQuestionBankId.id}`, {
            name:inputs
          })
          .then((res) => {
            if (res.data) {
              setTimeout(() => {
                toast.success("Question Bank Updated!", { toastId: "addTax-1" });
                setOpen(false);
                if (onClose) onClose();
                setIsSubmitting(false);
                setErrorsMessage('');
                setInputs('');
              }, 1000);
            } else {
              toast.error("Failed, please try again", { toastId: "addTax-1" });
              setIsSubmitting(false);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api" });
            setIsSubmitting(false);
          });
      }
      else {
        setErrorsMessage("Name is required");
        toast.warning(`Name is required please make sure to include it.`, {toastId:"error-qub"});
      }
    };

  return (
    <StyledDialog
      open={_isOpen}
      onClose={handleClose}
      classes={{ paper: 'custom-dialog-paper' }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      scroll={'body'}
      style={{ height: "auto", maxHeight: "900px"}}
      maxWidth={'sm'}
      fullWidth={true}
    >
      {/* <DialogTitle id="alert-dialog-title" className="text-center fw-bold">Edit Exam Courses</DialogTitle> */}
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-5 align-content-center ">
        Edit Question Bank
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      {/* <hr /> */}
      <DialogContent className="px-5 mt-2 fs-3">
          <div className="form-group col-sm-12 fs-6 fw-bold">
            <label htmlFor="course-name" className='search-label'>Question Bank Name: </label>
            <input type="text" className="form-control search-input" id="course-name" value={inputs} onChange={(e) => setInputs(e.target.value)}/>
            {errorsMessage && <span style={{color:"red",fontSize:"12px"}}>{errorsMessage}</span>}
          </div>  
      </DialogContent>
      {/* <hr /> */}
      <DialogActions className="mx-2">
        <Button onClick={handleClose} className={`${btnOK} btn fw-bold text-dark`}  sx={{
          color: "black",
          '&:hover': {
            color: "darkgrey",
            backgroundColor:"rgb(0,0,0,0.1)"
          }
        }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} disabled={isSubmitting} color="primary" className={`${btnOK} btn fw-bold`}
        sx={{
          color: "rgb(116, 103, 239)",
          '&:hover': {
            color: "darkblue",
            backgroundColor:"rgb(0,0,0,0.1)"
          }
        }}>
          {isSubmitting ? 'Uploading...' : 'Ok'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
