import LeftSideBar from '../../../Sidebar/Left_SideBar';
import DataTable from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import DeleteQuestion from "./Delete_Question_Popup";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Switch from '@mui/material/Switch';
import UploadExcel from './Upload_Excel_Popup';
import LoadingSpinner from '../../../../Utils/LoadingSpinner';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useRef } from 'react';
import apiName from '../../../../Utils/BackendApi';

export default function QuestionTable() {
    const { id } = useParams();
    const { name } = useParams();

    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);

    const user = localStorage.getItem("mcq-user");
    const userId = user && JSON.parse(user).id;

    const [selectQuestion, setSelectQuestion] = useState();
    const [openDeleteQuestion, setOpenDeleteQuestion] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [filter, setFilter] = useState(false);
    const [openExcelPopup, setOpenExcelPopup] = useState(false);

    const [data, setData] = useState([]);

    useEffect(()=>{
        axios.get(`${apiName}api/question/get-all-questions/${userId}/${id}`).then((res) => {
            if (res.data !== 'No data found') {
                setData(res.data);
                setLoader(true);
            }
            else {
                setLoader(true);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api" });
          });
    },[id, userId,openDeleteQuestion,openExcelPopup])

    useEffect(()=>{
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
          }
    },[])

    const handleEdit = (item) => {
        navigate(`/question-bank/edit-question/${id}/${name}/${item.id}`);
    }

    const handleRowClick = (row) => {
        navigate(`/question-bank/edit-question/${id}/${name}/${row.id}`);
      };

    const handleDelete = (item) => {
        console.log("item: ",item)
        setSelectQuestion(item);
        setOpenDeleteQuestion(true);
    }

    const filteredData = data && data !== "No data available" && data.filter(item => {
        return(String(item.question).toLowerCase().includes(searchQuery.toLowerCase()));
    });

    const customStyles = {
        headRow: {
            style: {
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            },
        },
        rows: {
            style: {
                backgroundColor: "#fff",
                minHeight: '40px',
                padding: "12px 0px",
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "500",
                lineHeight: "1.5rem",
                letterSpacing: "0.01071em",
                color: 'rgb(52, 49, 76)',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                fontSize: '15px'
            }
        },

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.no,
            sortable: true,
            // center: true,
            // grow: 1,
            width: '100px'
        },
        {
            name: 'Questions',
            selector: row => row.question,
            grow: 2,
            // center: true,
        },
        // {
        //     name: 'Options',
        //     cell: (row) => {
        //         const optionsArray = row.options;
        //         const formattedOptions = optionsArray.map((option, index) => {
        //           const isCorrectAnswer = (row.answer === 'option_a' ? '1' : row.answer === 'option_b' ? '2' : row.answer === 'option_c' ? '3' : row.answer === 'option_d' ? '4' : null ) === `${index + 1}`;
        //         const textColor = isCorrectAnswer ? 'green' : 'black';
        //         return `<span style="color: ${textColor}; line-height: 1.5" class='${isCorrectAnswer && 'fw-bold'}'>${index + 1}: ${option}</span>`;
        //       }).join(',<br/>');
        //       return <div style={{textAlign:"center", padding:"10px 0px"}} dangerouslySetInnerHTML={{ __html: formattedOptions }} />;
        //     },
        //     grow: 3,
        //     center: true,
        //     format: (row) => row.options,
        // },
        {
            name: 'Answer',
            selector: row => row.answer,
            center: 'true',
        },
        {
            name: 'Edit',
            selector: row => row.Edit,
            center: 'true',
            width: '150px'
        },
        // {
        //     name: 'Delete',
        //     selector: row => row.delete,
        //     center: true,
        //     width: '100px'
        // },
    ];

    const rows = filteredData ? filteredData.map((item, index) => {
        return {
            no: index + 1,
            id: item.id,
            question: item.question,
            options: [item.option_a, item.option_b, item.option_c, item.option_d],
            answer: <div style={{color:"green", fontWeight:"bold"}}>
                {
                    item.correct_option === 'option_a' ? item.option_a :
                    item.correct_option === 'option_b' ? item.option_b :
                    item.correct_option === 'option_c' ? item.option_c :
                    item.correct_option === 'option_d' ? item.option_d : ''
                }</div>,
           Edit:( <div>
            <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={()=>handleEdit(item)} sx={{color:'rgb(116, 103, 239)'}} > <Tooltip title="Edit" arrow><ModeEditIcon /></Tooltip> </IconButton>
            <IconButton size="small" aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={()=>handleDelete(item)} sx={{color:'red', marginLeft:"10px"}} > <Tooltip title="Delete" arrow><DeleteForeverIcon /></Tooltip> </IconButton>
           </div>
           )
        }
    }) : '';

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      };

  
    return (
        <div className={`d-flex`}>
            <LeftSideBar />
            {loader ? 
            <div className="main-content" >
            <div className="mx-sm-5 my-2">
                <div className="row">
                    <div className="col-md-6 text-start fw-bold fs-3 text-capitalize">
                        {name}
                    </div>
                    <div className="col-md-6">
                        <div style={{display:"flex", justifyContent:"flex-end"}}>
                            <button className="btn btn-custom me-2" onClick={()=>{navigate(`/question-bank/add-question/${id}/${name}`)}}>Add Question</button>
                            <button className="btn btn-success" onClick={()=>{setOpenExcelPopup(true)}}>Upload Excel</button>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                <span className='search-label'>Filter 
                <Switch 
                                onClick={() => {
                                    setFilter((preOpen) => !preOpen)
                                    if(filter === true){
                                        setSearchQuery('')
                                    }
                                }} 
                                checked={filter} 
                                size="small" 
                                color="secondary" 
                            />
                    </span>
                        <form className='my-2' style={{display: filter ? "block":"none"}}>
                        <div className="form-row row search-box">
                            <div className="form-group col-sm-12">
                            <label htmlFor="exam-name" className='search-label'>Question Name:</label>
                            <input type="text" name='exam' className="form-control search-input" id="exam-name" onKeyDown={handleKeyDown} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Name"/>
                            </div>
                            <div className='text-end my-2'>
                                <span className='search-label' style={{cursor:"pointer"}} onClick={()=>{setSearchQuery('')}}>Clear filter</span>
                            </div>
                            
                        </div>
                        </form>
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                            pointerOnHover
                            onRowClicked={handleRowClick}
                        />
                    </div>
            </div>
         </div>
            :<LoadingSpinner/>}
            <DeleteQuestion
                questionBankId={id}
                selectQuestion={selectQuestion}
                isOpen={openDeleteQuestion}
                onClose={() => {
                    setOpenDeleteQuestion(false);
                }}
            />
            <UploadExcel
                questionBankId={id}
                isOpen={openExcelPopup}
                onClose={() => {
                    setOpenExcelPopup(false);
                }}
            />
        </div>
    )
}