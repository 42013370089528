import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import logout from "../../../assets/Logout.png"
import Paper from '@mui/material/Paper';


const LogoutPopup = (props) => {
    const { isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);
  const navigate = useNavigate();
  
  const user = localStorage.getItem("mcq-user");
  const candidate = localStorage.getItem("mcq-candidates");
  const userRole = user && JSON.parse(user).role //username;
  const candidateRole = candidate && JSON.parse(candidate).role

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleLogout = () => {
    if(userRole === "Admin" || userRole === "1"){
      navigate("/admin-login");
      localStorage.removeItem("mcq-user");
    }
    if(candidateRole === 'User' || userRole === "2"){
      navigate("/");
      localStorage.removeItem("mcq-candidates");
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={'sm'}
    >
      {/* <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center" style={{ color:"#001b66" }}>
        Logout
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle> */}
      <DialogContent className="px-4 my-2 text-center">
        <img src={logout} alt="" style={{width:"100px", height:"100px"}}/>
        <p className='fw-bold fs-4 py-2' >Logout</p>
        <div className='fw-bold mt-3'>
        {/* <h5>Hi {userName},</h5> */}
        {/* <h5>You are attempting to log out of Wave app</h5> */}
        <h5 style={{color: "#685e5e" }}>Are you sure</h5>
        <h5>you want to <span className='text-danger'>logout</span> ?</h5>
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", marginBottom: "18px" }} >
        <button onClick={handleLogout} className="btn btn-danger border rounded-5 border-danger mx-2 px-4 text-capitalize fw-bold">
          Yes
        </button>
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 px-4 text-capitalize fw-bold`}>
          No
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutPopup;
